import apiService from './api-base';

const PACKAGE = 'pd/user/mgnt';

const apiUser = {
  insert: (dto: any) => apiService.post(PACKAGE + '/insert', dto),

  login: (dto: { userEmpno: string; password: string }) =>
    apiService.post(PACKAGE + '/login', dto),

  getList: (dto: {
    keyword?: string;
    userStCd?: string;
    userTypeCd?: string;
    posnCd?: string;
    rankCd?: string;
    page?: number;
  }) => apiService.post(PACKAGE + '/getList', dto),

  getDeptList: () => apiService.post(PACKAGE + '/dept/getList'),

  retire: (userId: string | number | boolean | undefined) =>
    apiService.post(PACKAGE + '/retire', { userId }),

  update: (dto: any) => apiService.post(PACKAGE + '/update', dto),

  findByEmpno: (dto: any) => apiService.post(PACKAGE + '/findByEmpno', dto),

  generateRegno: (dto: any) => apiService.post(PACKAGE + '/generateRegno', dto),

  checkRegno: (dto: any) => apiService.post(PACKAGE + '/checkRegno', dto),

  changePassword: (dto: any) =>
    apiService.post(PACKAGE + '/changePassword', dto),
};

export default apiUser;
