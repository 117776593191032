import React, { useState, useEffect } from 'react';

import AmSettingPresenter from './AmSettingPresenter';
import apiUser from 'api/api-user';
import apiAmConfig from 'api/api-amconfig';
import moment from 'moment';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import swal from '@sweetalert/with-react';

const AmSettingContainer = () => {
  const amSetTableTitle = [
    '출근시간(초기값)',
    '지각 기준',
    '필수 근무시간(주)',
  ];
  const [timeValue, setTimeValue] = useState('');
  const [amObject, setAmObject] = useState();
  const [workSiteList, setWorkSiteList] = useState();
  const [lateTime, setLateTime] = useState(10);
  const [weeklyWorkHours, setWeeklyWorkHours] = useState(40);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await apiAmConfig.getList();
      if (res.rsltCd === '00') {
        setAmObject(res.data.configList[0]);
        setWorkSiteList(res.data.configList[0].workSiteList);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const formatTime = (value) => {
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length >= 1) {
      const hours = numericValue.substring(0, 2);
      let formattedTime = hours;

      if (numericValue.length >= 3) {
        const minutes = numericValue.substring(2, 4);
        const hoursNum = parseInt(hours, 10);
        const minutesNum = parseInt(minutes, 10);
        if (!(hoursNum >= 0 && hours <= 23)) {
          makeMsg('시간을 정확하게 입력해주세요', 'warning');
        }
        if (!(minutesNum >= 0 && minutesNum <= 59)) {
          makeMsg('분을 정확하게 입력해주세요', 'warning');
        }
        if (
          hoursNum >= 0 &&
          hoursNum <= 24 &&
          minutesNum >= 0 &&
          minutesNum <= 59
        ) {
          formattedTime += `:${minutes}`;
        }
      }
      return formattedTime;
    }
    return '';
  };

  const timeFormatChange = (e) => {
    const { value } = e.target;
    setTimeValue(formatTime(value));
  };

  const saveSetting = async () => {
    try {
      const dto: any = {
        configId: 1,
        startTm: moment(timeValue, 'HH:mm').format('HHmm'),
        lateTolerance: lateTime,
        weeklyWorkHours: weeklyWorkHours,
        worksiteList: workSiteList,
      };
      const res = await apiAmConfig.update(dto);
      if (res.rsltCd === '00') {
        makeMsg('설정되었습니다.', 'success');
        fetchData();
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <>
      <AmSettingPresenter
        amSetTableTitle={amSetTableTitle}
        timeFormatChange={timeFormatChange}
        timeValue={timeValue}
        amObject={amObject}
        workSiteList={workSiteList}
        setLateTime={setLateTime}
        setWeeklyWorkHours={setWeeklyWorkHours}
        saveSetting={saveSetting}
      />
    </>
  );
};

AmSettingContainer.defaultProps = {};

export default AmSettingContainer;
