import React, { Dispatch, SetStateAction } from 'react';
import * as s from './AmLogStyled';
import { Link } from 'react-router-dom';

import NotReady from '../../../notReady';
import moment from 'moment';

interface AmLogProps {
  amTableTitle: any;
  amApcObject: any;
}

const AmLogPresenter = (props: AmLogProps) => {
  return (
    <s.Container>
      <s.TableTitle>기본시간변경 현황</s.TableTitle>
      <s.Table>
        <s.Tbody>
          <s.Tr>
            {props.amTableTitle?.map((item: any) => (
              <s.Th>{item}</s.Th>
            ))}
          </s.Tr>
          {props.amApcObject?.length > 0 ? (
            props.amApcObject?.map((row: any) => (
              <s.Tr>
                <s.Td>{moment(row.regDtm).format('YYYY-MM-DD HH:mm')}</s.Td>
                <s.Td>{row.deptNm}</s.Td>
                <s.Td>{row.rankNm}</s.Td>
                <s.Td>{row.userNm}</s.Td>
                <s.Td>
                  {row.startTm}~{row.endTm}
                </s.Td>
                <s.Td>
                  {row.chgStartTm}~{row.chgEndTm}
                </s.Td>
                <s.Td>{row.nowturn}</s.Td>
                <s.Td>
                  {row.state
                    ? row.state === '반려'
                      ? `${row.state} (${row?.description})`
                      : row.state
                    : '결재중'}
                </s.Td>
              </s.Tr>
            ))
          ) : (
            <s.Tr>
              <s.Td colSpan={7}>{'데이터가 없습니다.'}</s.Td>
            </s.Tr>
          )}
        </s.Tbody>
      </s.Table>
    </s.Container>
  );
};

export default AmLogPresenter;
