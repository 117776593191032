import React, { Dispatch, SetStateAction } from 'react';
import * as s from './ManagerStyled';
import { Link } from 'react-router-dom';

// import VcSetting from './vcSetting';
// import WorkTime from './workTime';
// import Approval from './approval';
import Setting from './setting';
import Status from './status';
import Request from './request';
import ApprMgnt from './apprmgnt';

import NotReady from '../notReady';

interface ManagerProps {
  onFocused: () => void;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabList: string[];
}

const ManagerPresenter = (props: ManagerProps) => {
  return (
    <s.Container onClick={() => props.onFocused()}>
      <s.PageTitle>관리자 페이지</s.PageTitle>
      <s.TabArea>
        <s.TabItemWrapper>
          {props.tabList.map((tabItem: string, index: number) => (
            <s.TabItem
              active={props.activeTab === index + 1}
              onClick={() => props.setActiveTab(index + 1)}
            >
              {tabItem}
            </s.TabItem>
          ))}
        </s.TabItemWrapper>
      </s.TabArea>

      <s.ContentArea>
        {props.activeTab === 1 ? (
          <Status />
        ) : props.activeTab === 2 ? (
          <Request />
        ) : props.activeTab === 3 ? (
          <ApprMgnt />
        ) : props.activeTab === 4 ? (
          <Setting />
        ) : (
          <NotReady />
        )}
      </s.ContentArea>
    </s.Container>
  );
};

export default ManagerPresenter;
