import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';

import StatusPresenter from './StatusPresenter';

const StatusContainer = () => {
  const setOpenLeftNavi = useSetRecoilState(recoilitem.openLeftNavi);

  const onFocused = () => {
    setOpenLeftNavi(false);
  };

  const [activeTab, setActiveTab] = useState<number>(1);
  const tabList = ['출퇴근 현황', '휴가 내역', '결재 현황'];

  return (
    <>
      <StatusPresenter
        onFocused={onFocused}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabList={tabList}
      />
    </>
  );
};

StatusContainer.defaultProps = {};

export default StatusContainer;
