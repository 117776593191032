import React, { Dispatch, SetStateAction } from 'react';
import * as s from './SettingStyled';
import { Link } from 'react-router-dom';

import VcSetting from './vcSetting';
import AmSetting from './amSetting';

import NotReady from '../../notReady';

interface SettingProps {
  onFocused: () => void;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabList: string[];
}

const SettingPresenter = (props: SettingProps) => {
  return (
    <s.Container onClick={() => props.onFocused()}>
      <s.TabItemWrapper>
        {props.tabList.map((tabItem: string, index: number) => (
          <s.TabItem
            active={props.activeTab === index + 1}
            onClick={() => props.setActiveTab(index + 1)}
          >
            {tabItem}
          </s.TabItem>
        ))}
      </s.TabItemWrapper>

      <s.ContentArea>
        {props.activeTab === 1 ? (
          <AmSetting />
        ) : props.activeTab === 2 ? (
          <VcSetting />
        ) : props.activeTab === 3 ? (
          <NotReady />
        ) : props.activeTab === 4 ? (
          <NotReady />
        ) : props.activeTab === 5 ? (
          <NotReady />
        ) : (
          <NotReady />
        )}
      </s.ContentArea>
    </s.Container>
  );
};

export default SettingPresenter;
