import theme from 'components/styles/theme';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import {
  MdCheck,
  MdCheckBox,
  MdChevronLeft,
  MdChevronRight,
  MdLockClock,
  MdLogout,
  MdMail,
  MdNewspaper,
  MdPages,
  MdSettings,
  MdSupervisedUserCircle,
} from 'react-icons/md';
import { SetterOrUpdater } from 'recoil';
import * as s from './LeftNaviStyled';

interface LeftNaviProps {
  userInfo: any;
  logout: () => void;
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  openedDelay: boolean;
  movePage: (url: string) => void;
  openLeftNavi: any;
  setOpenLeftNavi: SetterOrUpdater<any>;
  registerEnter: () => void;
  registerLeave: () => void;
  onClickAmButton: () => void;
  myAmLog: any;
}

const LeftNaviPresenter = (props: LeftNaviProps) => {
  return (
    <>
      <s.Container opened={props.openLeftNavi}>
        <s.UserInfo opened={props.openLeftNavi}>
          <s.UserInfoImg src="https://picsum.photos/60" />
          {props.openLeftNavi ? (
            <s.UserInfoWrapper>
              <s.UserInfoName>
                {props.userInfo.userNm}
                <s.UserInfoDeptSpan>{props.userInfo.deptNm}</s.UserInfoDeptSpan>
                <s.UserInfoPosnSpan>
                  {props.userInfo.rankNm}/{props.userInfo.posnNm}
                </s.UserInfoPosnSpan>
              </s.UserInfoName>
              <s.UserInfoAmButton
                onClick={() => props.onClickAmButton()}
                backgroundColor={
                  props.myAmLog.inVacation ||
                  moment().day() === 0 ||
                  moment().day() === 6
                    ? theme.color.darkGrey
                    : props.myAmLog.enter
                    ? props.myAmLog.leave
                      ? theme.color.darkGrey
                      : theme.color.secondary
                    : moment().add(-10, 'minutes').format('HHmm') >
                      props.myAmLog.startTm
                    ? theme.color.secondary
                    : theme.color.secondary
                }
              >
                {props.myAmLog.inVacation
                  ? '휴가중'
                  : moment().day() === 0 || moment().day() === 6
                  ? '휴일입니다.'
                  : props.myAmLog.enter
                  ? props.myAmLog.leave
                    ? '근무종료'
                    : '퇴근하기'
                  : moment().add(-10, 'minutes').format('HHmm') >
                    props.myAmLog.startTm
                  ? '출근하기(지각)'
                  : '출근하기'}
              </s.UserInfoAmButton>
              <s.LogoutButton
                onClick={() => props.logout()}
                opened={props.openedDelay}
              >
                로그아웃
                <MdLogout size={'2rem'} color={theme.color.white} />
              </s.LogoutButton>
            </s.UserInfoWrapper>
          ) : null}
        </s.UserInfo>
        <s.ItemList opened={props.openLeftNavi}>
          {props.openLeftNavi ? (
            <>
              <s.OpenedItem onClick={() => props.movePage('/mail')}>
                <MdMail size={'2.5rem'} color={theme.color.white} />
                <s.OpenedItemText>
                  메일<s.OpenedItemSpan>Mail</s.OpenedItemSpan>
                </s.OpenedItemText>
              </s.OpenedItem>
              <s.OpenedItem onClick={() => props.movePage('/approval')}>
                <MdCheck size={'2.5rem'} color={theme.color.white} />
                <s.OpenedItemText>
                  결재<s.OpenedItemSpan>Approval</s.OpenedItemSpan>
                </s.OpenedItemText>
              </s.OpenedItem>
              <s.OpenedItem onClick={() => props.movePage('/am')}>
                <MdLockClock size={'2.5rem'} color={theme.color.white} />
                <s.OpenedItemText>
                  근태<s.OpenedItemSpan>Worktime</s.OpenedItemSpan>
                </s.OpenedItemText>
              </s.OpenedItem>
              <s.OpenedItem onClick={() => props.movePage('/board')}>
                <MdNewspaper size={'2.5rem'} color={theme.color.white} />
                <s.OpenedItemText>
                  게시판<s.OpenedItemSpan>Board</s.OpenedItemSpan>
                </s.OpenedItemText>
              </s.OpenedItem>
              {props.userInfo.userTypeCd === 'USRUTADM' && (
                <s.OpenedItem onClick={() => props.movePage('/manager')}>
                  <MdSupervisedUserCircle
                    size={'2.5rem'}
                    color={theme.color.white}
                  />
                  <s.OpenedItemText>
                    관리자<s.OpenedItemSpan>Manager</s.OpenedItemSpan>
                  </s.OpenedItemText>
                </s.OpenedItem>
              )}
            </>
          ) : (
            <>
              <s.ClosedItem onClick={() => props.movePage('/mail')}>
                <MdMail size={'100%'} color={theme.color.white} />
              </s.ClosedItem>
              <s.ClosedItem onClick={() => props.movePage('/approval')}>
                <MdCheck size={'100%'} color={theme.color.white} />
              </s.ClosedItem>
              <s.ClosedItem onClick={() => props.movePage('/am')}>
                <MdLockClock size={'100%'} color={theme.color.white} />
              </s.ClosedItem>
              <s.ClosedItem onClick={() => props.movePage('/board')}>
                <MdNewspaper size={'100%'} color={theme.color.white} />
              </s.ClosedItem>
              {props.userInfo.userTypeCd === 'USRUTADM' && (
                <s.ClosedItem onClick={() => props.movePage('/manager')}>
                  <MdSupervisedUserCircle
                    size={'100%'}
                    color={theme.color.white}
                  />
                </s.ClosedItem>
              )}
            </>
          )}
        </s.ItemList>
        <s.OpenCloseToggle
          onClick={() => props.setOpenLeftNavi(!props.openLeftNavi)}
        >
          {props.openLeftNavi ? (
            <FaChevronCircleLeft size={'100%'} color={theme.color.white} />
          ) : (
            <FaChevronCircleRight size={'100%'} color={theme.color.white} />
          )}
        </s.OpenCloseToggle>
      </s.Container>
    </>
  );
};

export default LeftNaviPresenter;
