import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';

import ApprPresenter from './ApprPresenter';

const ApprContainer = () => {
  const setOpenLeftNavi = useSetRecoilState(recoilitem.openLeftNavi);

  const onFocused = () => {
    setOpenLeftNavi(false);
  };

  const [activeTab, setActiveTab] = useState<number>(1);
  const tabList = ['결재 현황', '상신함', '결재함', '문서함'];

  return (
    <>
      <ApprPresenter
        onFocused={onFocused}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabList={tabList}
      />
    </>
  );
};

ApprContainer.defaultProps = {};

export default ApprContainer;
