import React, { Dispatch, SetStateAction } from 'react';
import * as s from './DashboardStyled';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Calendar from 'components/calendar';
import { userInfo } from 'os';
import { MdClose } from 'react-icons/md';
import { CSVLink } from 'react-csv';

interface DashboardProps {
  amObject: any;
  monthlyWorkData: any;
  userInfo: any;
  setIsAdmin: Dispatch<SetStateAction<any>>;
  isAdmin: any;
  searchData: any;
  setSearchData: Dispatch<SetStateAction<any>>;
  amTableTitle: any[];
  amHistObject: any;
  isBaseTimeModal: any;
  setIsBaseTimeModal: Dispatch<SetStateAction<any>>;
  isChangeTimeModal: any;
  setIsChangeTimeModal: Dispatch<SetStateAction<any>>;
  yearMonthList: any;
  selectedYearMonth: any;
  setSelectedYearMonth: Dispatch<SetStateAction<any>>;
  adminAmExcelHeader: any;
  adminAmExcelData: any;
  fileName: any;
  startTimeList: any;
  endTimeList: any;
  selectStartTime: any;
  setSelectStartTime: Dispatch<SetStateAction<any>>;
  selectEndTime: any;
  setSelectEndTime: Dispatch<SetStateAction<any>>;
  updateBaseTime: () => void;
  setChangeStartTime: Dispatch<SetStateAction<any>>;
  setChangeEndTime: Dispatch<SetStateAction<any>>;
  updateChangeTime: () => void;
  setChangeStartDt: Dispatch<SetStateAction<any>>;
  setChangeEndDt: Dispatch<SetStateAction<any>>;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabList: string[];
  getMonthlyList: () => void;
  isRequestModal: any;
  setIsRequestModal: Dispatch<SetStateAction<any>>;
  reqStartTime: any;
  setReqStartTime: Dispatch<SetStateAction<any>>;
  reqEndTime: any;
  setReqEndTime: Dispatch<SetStateAction<any>>;
  setReqLogId: Dispatch<SetStateAction<any>>;
  reqRsn: any;
  setReqRsn: Dispatch<SetStateAction<any>>;
  updateReqTime: () => void;
  dayOff: any;
  setDayOff: Dispatch<SetStateAction<any>>;
}

const DashboardPresenter = (props: DashboardProps) => {
  return (
    <s.Container>
      <>
        <s.BaseTimeArea>
          <s.DashboardCardRow>
            기본 시간
            <s.DashboardCardRowSpan style={{ color: 'black' }}>
              {props.amObject?.startTm?.substring(0, 2)}:
              {props.amObject?.startTm?.substring(2)} ~
              {props.amObject?.endTm?.substring(0, 2)}:
              {props.amObject?.endTm?.substring(2)}
            </s.DashboardCardRowSpan>
          </s.DashboardCardRow>
          <s.ChangeTime onClick={() => props.setIsBaseTimeModal(true)}>
            기본근무시간 변경
          </s.ChangeTime>
          <s.ChangeTime onClick={() => props.setIsChangeTimeModal(true)}>
            유연근무 신청
          </s.ChangeTime>
        </s.BaseTimeArea>
        <s.DashboardRow>
          <s.DashboardCard>
            <s.DashboardCardTitle>
              {moment().format('YYYY년 MM월 DD일')}
            </s.DashboardCardTitle>
            <s.DashboardCardRow>
              출근시간
              <s.DashboardCardRowSpan>
                {props.amObject.enterTmFormat}
              </s.DashboardCardRowSpan>
            </s.DashboardCardRow>
            <s.DashboardCardRow>
              퇴근시간
              <s.DashboardCardRowSpan>
                {props.amObject.leaveTmFormat}
              </s.DashboardCardRowSpan>
            </s.DashboardCardRow>
            <s.DashboardCardRow>
              오늘 근로시간
              <s.DashboardCardRowSpan>
                {props.amObject.workHours}
              </s.DashboardCardRowSpan>
            </s.DashboardCardRow>
          </s.DashboardCard>
          <s.DashboardCard>
            <s.DashboardCardTitle>근로시간</s.DashboardCardTitle>
            <s.DashboardCardRow>
              이번 주 근로시간(확정)
              <s.DashboardCardRowSpan>
                사내 IP가 아닙니다
              </s.DashboardCardRowSpan>
            </s.DashboardCardRow>
            <s.DashboardCardRow>
              잔여 근로시간
              <s.DashboardCardRowSpan>
                사내 IP가 아닙니다
              </s.DashboardCardRowSpan>
            </s.DashboardCardRow>
            <s.DashboardCardRow>
              추정 근로시간
              <s.DashboardCardRowSpan>
                사내 IP가 아닙니다
              </s.DashboardCardRowSpan>
            </s.DashboardCardRow>
          </s.DashboardCard>
        </s.DashboardRow>
        <s.TabItemWrapper>
          {props.tabList.map((tabItem: string, index: number) => (
            <s.TabItem
              active={props.activeTab === index + 1}
              onClick={() => props.setActiveTab(index + 1)}
            >
              {tabItem}
            </s.TabItem>
          ))}
        </s.TabItemWrapper>
        {props.activeTab === 1 ? (
          <Calendar monthlyWorkData={props.monthlyWorkData} />
        ) : (
          <>
            <s.SearchArea>
              <s.ModalInput //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
                type="date"
                max={'9999-12-31'}
                onChange={(e) => {
                  props.setChangeStartDt(e.target.value);
                }}
              />
              ~
              <s.ModalInput
                type="date"
                max={'9999-12-31'}
                onChange={(e) => {
                  props.setChangeEndDt(e.target.value);
                }}
              />
              <s.SearchBtn onClick={() => props.getMonthlyList()}>
                검색
              </s.SearchBtn>
            </s.SearchArea>
            <s.AmTable>
              <s.AmTbody>
                <s.AmTr>
                  {props.amTableTitle?.map((item: any) => (
                    <s.AmTh>{item}</s.AmTh>
                  ))}
                </s.AmTr>
                {props.amHistObject?.length > 0 ? (
                  props.amHistObject?.map((row: any) => (
                    <s.AmTr>
                      <s.AmTd>{row?.userEmpno}</s.AmTd>
                      <s.AmTd>{row?.userNm}</s.AmTd>
                      <s.AmTd>{row?.deptNm}</s.AmTd>
                      <s.AmTd>{row?.posnNm}</s.AmTd>
                      <s.AmTd>{moment(row?.logDt).format('YYYYMMDD')}</s.AmTd>
                      <s.AmTd>{row?.startTm}</s.AmTd>
                      <s.AmTd>{row?.endTm}</s.AmTd>
                      <s.AmTd>
                        {row?.case
                          ? row?.amDvNm + '(' + row?.case + ')'
                          : row?.amDvNm}
                      </s.AmTd>
                      {row.apprStCdNm ? (
                        <s.AmTd>{row.apprStCdNm}</s.AmTd>
                      ) : (
                        <s.AmTd></s.AmTd>
                      )}
                      {(row.amDvNm === '출근누락' ||
                        row.amDvNm === '퇴근누락') &&
                      row.state === 0 ? (
                        <s.AmTd>
                          <s.BtnArea>
                            <s.ApprBtn
                              primary
                              onClick={() => {
                                props.setIsRequestModal(true);
                                props.setReqLogId(row.logId);
                              }}
                            >
                              요청
                            </s.ApprBtn>
                          </s.BtnArea>
                        </s.AmTd>
                      ) : (
                        <s.AmTd></s.AmTd>
                      )}
                    </s.AmTr>
                  ))
                ) : (
                  <s.AmTr>
                    <s.AmTd colSpan={10}>{'데이터가 없습니다.'}</s.AmTd>
                  </s.AmTr>
                )}
              </s.AmTbody>
            </s.AmTable>
          </>
        )}
      </>
      {props.isRequestModal && (
        <s.PopupBackground onClick={() => props.setIsRequestModal(false)}>
          <s.PopupModal onClick={(e) => e.stopPropagation()}>
            <s.ModalCloseButton onClick={() => props.setIsRequestModal(false)}>
              <MdClose size={'100%'} color={'#fff'} />
            </s.ModalCloseButton>
            <s.ModalTitle>출퇴근현황 변경 요청</s.ModalTitle>

            <s.ModalButtonWrapper>
              <s.ModalInputLabel>출근시간</s.ModalInputLabel>
              <s.ModalSelect
                onChange={(e) => {
                  props.setReqStartTime(e.target.value);
                }}
              >
                <s.SelectOption value="">시작시간 선택</s.SelectOption>
                {props.startTimeList?.map((startTime) => (
                  <s.SelectOption key={startTime} value={startTime}>
                    {moment(startTime, 'HH:mm').format('HH:mm')}
                  </s.SelectOption>
                ))}
              </s.ModalSelect>
            </s.ModalButtonWrapper>
            <s.ModalButtonWrapper>
              <s.ModalInputLabel>퇴근시간</s.ModalInputLabel>
              <s.ModalSelect
                onChange={(e) => {
                  props.setReqEndTime(e.target.value);
                }}
              >
                <s.SelectOption value="">퇴근시간 선택</s.SelectOption>
                {props.endTimeList?.map((endTime) => (
                  <s.SelectOption key={endTime} value={endTime}>
                    {moment(endTime, 'HH:mm').format('HH:mm')}
                  </s.SelectOption>
                ))}
              </s.ModalSelect>
            </s.ModalButtonWrapper>
            <s.ModalButtonWrapper>
              <s.ModalInputLabel>요청사유</s.ModalInputLabel>
              <s.ModalText
                value={props.reqRsn}
                onChange={(e) => props.setReqRsn(e.target.value)}
                placeholder="요청 사유를 작성해 주세요."
              />
            </s.ModalButtonWrapper>
            <s.ModalButtonWrapper>
              <s.ModalButton
                onClick={() => {
                  props.setIsRequestModal(false);
                  props.setReqLogId(null);
                  props.setReqRsn(null);
                }}
                style={{ color: '#fff', background: '#9a9a9a' }}
              >
                취소
              </s.ModalButton>
              <s.ModalButton onClick={() => props.updateReqTime()}>
                신청
              </s.ModalButton>
            </s.ModalButtonWrapper>
          </s.PopupModal>
        </s.PopupBackground>
      )}

      {props.isBaseTimeModal && (
        <s.PopupBackground onClick={() => props.setIsBaseTimeModal(false)}>
          <s.PopupModal onClick={(e) => e.stopPropagation()}>
            <s.ModalCloseButton onClick={() => props.setIsBaseTimeModal(false)}>
              <MdClose size={'100%'} color={'#fff'} />
            </s.ModalCloseButton>
            <s.ModalTitle>기본 시간 변경</s.ModalTitle>

            <s.ModalButtonWrapper>
              <s.ModalInputLabel>출근시간</s.ModalInputLabel>
              <s.ModalSelect
                onChange={(e) => {
                  props.setSelectStartTime(e.target.value);
                }}
              >
                <s.SelectOption value="">시작시간 선택</s.SelectOption>
                {props.startTimeList?.map((startTime) => (
                  <s.SelectOption key={startTime} value={startTime}>
                    {moment(startTime, 'HH:mm').format('HH:mm')}
                  </s.SelectOption>
                ))}
              </s.ModalSelect>
            </s.ModalButtonWrapper>
            <s.ModalButtonWrapper>
              <s.ModalInputLabel>퇴근시간</s.ModalInputLabel>
              <s.ModalInputRow>
                {props.selectEndTime?.isValid()
                  ? props.selectEndTime?.format('HH:mm')
                  : '출근시간을 선택하세요'}
              </s.ModalInputRow>
            </s.ModalButtonWrapper>
            <s.ModalButtonWrapper>
              <s.ModalButton
                onClick={() => props.setIsBaseTimeModal(false)}
                style={{ color: '#fff', background: '#9a9a9a' }}
              >
                취소
              </s.ModalButton>
              <s.ModalButton onClick={() => props.updateBaseTime()}>
                신청
              </s.ModalButton>
            </s.ModalButtonWrapper>
          </s.PopupModal>
        </s.PopupBackground>
      )}

      {props.isChangeTimeModal && ( //유연근로
        <s.PopupBackground onClick={() => props.setIsChangeTimeModal(false)}>
          <s.PopupModal onClick={(e) => e.stopPropagation()}>
            <s.ModalCloseButton
              onClick={() => props.setIsChangeTimeModal(false)}
            >
              <MdClose size={'100%'} color={'#fff'} />
            </s.ModalCloseButton>
            <s.ModalTitle>유연근무 신청</s.ModalTitle>
            <s.ModalButtonWrapper>
              <s.ModalInputLabel>변경일자</s.ModalInputLabel>
              <s.ModalInput //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
                type="date"
                style={{
                  colorScheme: 'white',
                }}
                max={'9999-12-31'}
                onChange={(e) => {
                  props.setChangeStartDt(e.target.value);
                }}
              />
              ~
              <s.ModalInput //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
                type="date"
                style={{
                  colorScheme: 'white',
                }}
                max={'9999-12-31'}
                onChange={(e) => {
                  props.setChangeEndDt(e.target.value);
                }}
              />
            </s.ModalButtonWrapper>
            <s.ModalButtonWrapper style={{ fontSize: '14px' }}>
              <s.ModalInputCheck //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
                type="checkbox"
                style={{
                  colorScheme: 'white',
                }}
                checked={props.dayOff}
                onClick={() => {
                  props.setDayOff(!props.dayOff);
                }}
              />
              Day Off
            </s.ModalButtonWrapper>
            {!props.dayOff && (
              <>
                <s.ModalButtonWrapper style={{ justifyContent: 'start' }}>
                  <s.ModalInputLabel>출근시간</s.ModalInputLabel>
                  <s.ModalSelect
                    onChange={(e) => {
                      props.setChangeStartTime(e.target.value);
                    }}
                  >
                    <s.SelectOption value="">시작시간 선택</s.SelectOption>
                    {props.startTimeList?.map((startTime) => (
                      <s.SelectOption key={startTime} value={startTime}>
                        {moment(startTime, 'HH:mm').format('HH:mm')}
                      </s.SelectOption>
                    ))}
                  </s.ModalSelect>
                </s.ModalButtonWrapper>
                <s.ModalButtonWrapper style={{ justifyContent: 'start' }}>
                  <s.ModalInputLabel>퇴근시간</s.ModalInputLabel>
                  <s.ModalSelect
                    onChange={(e) => {
                      props.setChangeEndTime(e.target.value);
                    }}
                  >
                    <s.SelectOption value="">퇴근시간 선택</s.SelectOption>
                    {props.endTimeList?.map((startTime) => (
                      <s.SelectOption key={startTime} value={startTime}>
                        {moment(startTime, 'HH:mm').format('HH:mm')}
                      </s.SelectOption>
                    ))}
                  </s.ModalSelect>
                </s.ModalButtonWrapper>
              </>
            )}
            <s.ModalButtonWrapper style={{ marginTop: '4rem' }}>
              <s.ModalButton
                onClick={() => props.setIsChangeTimeModal(false)}
                style={{ color: '#fff', background: '#9a9a9a' }}
              >
                취소
              </s.ModalButton>
              <s.ModalButton onClick={() => props.updateChangeTime()}>
                신청
              </s.ModalButton>
            </s.ModalButtonWrapper>
          </s.PopupModal>
        </s.PopupBackground>
      )}
    </s.Container>
  );
};

export default DashboardPresenter;
