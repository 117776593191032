import theme from 'components/styles/theme';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';

export const Container = styled.div`
  width: 100%;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

export const AdminContentArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const SearchArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
`;
export const ModalInput = styled.input`
  width: 15%;
  border-radius: 0.5rem;
  background-color: ${theme.color.white};
  color: ${theme.color.black};
  font-size: 1.2rem;
  border: 1px solid ${theme.color.grey};
  padding: 0.7rem;
  margin: 0 0.5rem;
`;

export const InputLabel = styled.div`
  font-size: 1.6rem;
  color: ${theme.color.black};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const Input = styled.input`
  width: 30rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.color.darkGrey};
  color: ${theme.color.black};
  font-size: 1.2rem;
  padding: 1rem;
  margin: 1rem;
`;
export const SearchBtn = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 1.2rem 1.7rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.veryDarkGrey};
  color: ${theme.color.white};
`;

export const VcTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 0.2rem solid ${theme.color.primary};
  border-bottom: 0.2rem solid ${theme.color.primary};
  width: 100%;
  margin: 1rem auto 5rem;
`;
export const VcTbody = styled.tbody``;

export const VcTr = styled.tr``;

export const VcTh = styled.th`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
`;

export const VcTd = styled.td`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
  & > :last-child {
    border-bottom: 0;
  }
`;

// table start
export const VacationTableTitle = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
  margin-top: 3rem;
`;

export const VacationTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 0.2rem solid ${theme.color.primary};
  border-bottom: 0.2rem solid ${theme.color.primary};
  width: 100%;
  margin: 2rem auto 5rem;
`;

export const VacationTbody = styled.tbody``;

export const VacationTr = styled.tr``;

export const VacationTh = styled.th`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
`;

export const VacationTd = styled.td`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
  & > :last-child {
    border-bottom: 0;
  }
`;
// table end

export const ExcelBtn = styled(CSVLink).attrs((props) => ({
  headers: props?.headers,
  data: props?.data,
}))`
  width: fit-content;
  height: fit-content;
  padding: 1.2rem 1.7rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.secondary};
  color: ${theme.color.white};
  margin-left: auto;
  text-decoration: none;
`;
