import React, { useState, useEffect, useCallback } from 'react';

import DetailPresenter from './DetailPresenter';
import moment from 'moment';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import apiAppr from 'api/api-appr';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import swal from '@sweetalert/with-react';

const DetailContainer = () => {
  const location = useLocation();
  const apprTypeId = new URLSearchParams(location.search).get('apprTypeId');
  const navigate = useNavigate();

  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [isItemModal, setIsItemModal] = useState(false);
  const [itemTitle, setItemTitle] = useState<string>('');
  const [itemInputData, setItemInputData] = useState<string>('');
  const itemList = ['TEXT', '일자', '금액', 'File', '참조문서'];
  const [price, setPrice] = useState<any | undefined>();
  const [itemSt, setItemSt] = useState('');

  interface Item {
    questionTitle: string;
    questionTypeCd: string;
  }
  const [item, setItem] = useState<Item[]>([]);
  const [contents, setContents] = useState();
  const [detailItemCnt, setDetailItemCnt] = useState(0);
  const [detailItem, setDetailItem] = useState<Item[]>([]);
  const [title, setTitle] = useState();
  const [user, setUser] = useState<any>([]);
  const [ccUser, setCcUser] = useState<any>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const dto = {
        apprTypeId: apprTypeId,
      };
      const res = await apiAppr.detail(dto);
      if (res.rsltCd === '00') {
        setContents(res.data.apprTypeDetail?.content);
        setTitle(res.data.apprTypeDetail?.title);
        setUser(
          res.data?.apprTypeDetail?.lineList?.filter(
            (type) => type.approverTypeCd === 'APPRTYPE01',
          ),
        );
        setCcUser(
          res.data?.apprTypeDetail?.lineList?.filter(
            (type) => type.approverTypeCd === 'APPRTYPE04',
          ),
        );
        setItem(res.data.apprTypeDetail?.questionList);
        if (res.data.apprTypeDetail?.detailQuestionList?.length > 0) {
          setDetailItem(res.data.apprTypeDetail?.detailQuestionList);
          setDetailItemCnt(1);
        }
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const priceChange = (e) => {
    const { value } = e.target;
    const formattedValue = value
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    setPrice(formattedValue);
  };

  const itemReg = (itemSt: any) => {
    if (itemTitle === '') {
      makeMsg('Title을 입력하세요.', 'warning');
      return;
    } else if (itemInputData === '') {
      makeMsg('InputData를 입력하세요.', 'warning');
      return;
    }

    if (itemSt === 'item') {
      setItem([
        ...item,
        { questionTitle: itemTitle, questionTypeCd: itemInputData },
      ]);
    } else if (itemSt === 'detailItem') {
      setDetailItem([
        ...detailItem,
        { questionTitle: itemTitle, questionTypeCd: itemInputData },
      ]);
    }
    setIsItemModal(false);
    setItemTitle('');
    setItemInputData('');
  };

  const save = useCallback(
    async (content, user, ccUser) => {
      try {
        if (!title) {
          makeMsg('기안 제목을 입력하세요.', 'warning');
          return;
        }
        setIsLoading(true);

        const lineList = [
          ...user.map((user, index) => ({
            userId: user.userId,
            approverTypeCd: 'APPRTYPE01',
            apprOrder: index,
          })),
          ...ccUser.map((user, index) => ({
            userId: user.userId,
            approverTypeCd: 'APPRTYPE04',
            apprOrder: null,
          })),
        ];

        const questionList = item.map((item, index) => ({
          questionTitle: item.questionTitle,
          questionPlaceholder: `${item.questionTitle}을 입력하세요.`,
          questionNum: index,
          questionTypeCd: item.questionTypeCd,
        }));

        const detailQuestionList = detailItem.map((item, index) => ({
          questionTitle: item.questionTitle,
          questionPlaceholder: `${item.questionTitle}을 입력하세요.`,
          questionNum: index,
          questionTypeCd: item.questionTypeCd,
        }));

        const dto = {
          apprTypeId: apprTypeId,
          title: title,
          description: `${title}입니다`,
          content: content,
          questionList: questionList,
          detailQuestionList: detailQuestionList,
          lineList: lineList,
        };
        const res = await apiAppr.update(dto);
        if (res.rsltCd === '00') {
          makeMsg('등록되었습니다.', 'success');
          movePage('/manager');
        }
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        makeMsg('네트워크 오류가 발생했습니다.', 'error');
      }
    },
    [title, user, item, detailItem, ccUser],
  );

  const itemDelete = async () => {
    const value = await swal({
      text: '기안양식을 삭제 하시겠습니까?',
      buttons: {
        confirm: {
          text: '확인',
          closeModal: true,
        },
        cancel: '취소',
      },
    });
    if (value) {
      const dto: any = {
        apprTypeId: apprTypeId,
      };
      const res = await apiAppr.delete(dto);
      if (res.rsltCd === '00') {
        makeMsg('삭제되었습니다.', 'success');
        movePage('/manager');
      }
    }
  };

  const movePage = (page) => {
    navigate(page);
  };

  return (
    <>
      <DetailPresenter
        priceChange={priceChange}
        isItemModal={isItemModal}
        setIsItemModal={setIsItemModal}
        itemList={itemList}
        item={item}
        setItemInputData={setItemInputData}
        itemTitle={itemTitle}
        setItemTitle={setItemTitle}
        itemReg={itemReg}
        setPrice={setPrice}
        price={price}
        detailItem={detailItem}
        setDetailItem={setDetailItem}
        detailItemCnt={detailItemCnt}
        setDetailItemCnt={setDetailItemCnt}
        itemSt={itemSt}
        setItemSt={setItemSt}
        setItem={setItem}
        save={save}
        setTitle={setTitle}
        movePage={movePage}
        title={title}
        setUser={setUser}
        user={user}
        setCcUser={setCcUser}
        ccUser={ccUser}
        contents={contents}
        itemDelete={itemDelete}
      />
    </>
  );
};

DetailContainer.defaultProps = {};

export default DetailContainer;
