import theme from 'components/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  margin-top: 7rem;

  @media screen and (max-width: 1200px) {
    min-height: 81vh;
  }
`;
