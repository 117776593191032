import React, { useState, useEffect } from 'react';

import ApprMgntPresenter from './ApprMgntPresenter';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import { useNavigate } from 'react-router-dom';
import apiAppr from 'api/api-appr';

const ApprMgntContainer = () => {
  const navigate = useNavigate();
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const amSetTableTitle = ['기안양식 번호', '제목', '수정날짜'];
  const [apprObject, setApprObject] = useState();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const res = await apiAppr.getList();
      const dto = {
        apprTypeId: 24,
      };
      apiAppr.detail(dto);
      if (res.rsltCd === '00') {
        setApprObject(res.data.apprTypeList);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const movePage = (page) => {
    navigate(page);
  };

  return (
    <>
      <ApprMgntPresenter
        amSetTableTitle={amSetTableTitle}
        apprObject={apprObject}
        movePage={movePage}
      />
    </>
  );
};

ApprMgntContainer.defaultProps = {};

export default ApprMgntContainer;
