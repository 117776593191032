import React, { Dispatch, SetStateAction } from 'react';
import * as s from './StatusStyled';
import { Link } from 'react-router-dom';

import NotReady from '../../notReady';
import AmLog from '../status/amLog';
import VcLog from '../status/vcLog';
import ApprLog from '../status/apprLog';

interface StatusProps {
  onFocused: () => void;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabList: string[];
}

const StatusPresenter = (props: StatusProps) => {
  return (
    <s.Container onClick={() => props.onFocused()}>
      <s.TabItemWrapper>
        {props.tabList.map((tabItem: string, index: number) => (
          <s.TabItem
            active={props.activeTab === index + 1}
            onClick={() => props.setActiveTab(index + 1)}
          >
            {tabItem}
          </s.TabItem>
        ))}
      </s.TabItemWrapper>

      <s.ContentArea>
        {props.activeTab === 1 ? (
          <AmLog />
        ) : props.activeTab === 2 ? (
          <VcLog />
        ) : props.activeTab === 3 ? (
          <ApprLog />
        ) : props.activeTab === 4 ? (
          <ApprLog />
        ) : props.activeTab === 5 ? (
          <NotReady />
        ) : (
          <NotReady />
        )}
      </s.ContentArea>
    </s.Container>
  );
};

export default StatusPresenter;
