import { API_SERVER } from '../config/config';

import * as util from 'util/util';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import axios from 'axios';

const apiService = {
  post: (
    url: string,
    body?: any,
  ): Promise<{
    rsltCd: string;
    rsltMsg?: string;
    dtlsRsltMsg?: string;
    data?: any;
    [key: string | number]: any;
  }> => baseApi().post(url, body),
};

const baseApi = () => {
  const api = axios.create({
    baseURL: `${API_SERVER}`,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      AUTHORIZATION: authToken?.accessToken,
    },
  });

  api.interceptors.request.use(
    (request) => {
      try {
        console.log(
          'ApiService::Request URL >',
          (request.baseURL || '') + request.url,
        );
        return request;
      } catch (e) {
        console.log(e);
      }
    },
    (error) => console.log('ApiService::Request Error >', error),
  );

  api.interceptors.response.use(
    (response) => response.data,
    async (error) => {
      if (error?.response?.status === 401) {
        util
          .makeMsg('로그인이 필요한 서비스입니다.', 'etc')
          .then(cleanUserInfo);
        return;
      } else {
        util.makeMsg('네트워크 오류가 발생했습니다.', 'error');
        return Promise.reject(error?.response?.data);
      }
    },
  );

  return api;
};

let authToken: { accessToken: string; refreshToken: string } = {
  accessToken: '',
  refreshToken: '',
};
let cleanUserInfo = () => {};

export const ApiServiceBridge: React.FC = () => {
  authToken = useRecoilValue(recoilitem.token);
  const setToken = useSetRecoilState(recoilitem.token);
  const setUserInfo = useSetRecoilState(recoilitem.userInfo);

  cleanUserInfo = () => {
    setUserInfo({
      userId: '',
      userNm: '',
      userEmail: '',
      userTypeCd: '',
      profileImage: '',
    });
    setToken(null);
  };

  return null;
};

export default apiService;
