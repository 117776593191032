import { Link, NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import theme from '../styles/theme';

// Common
export const Container = styled.div<{ opened: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: ${(props) => (props.opened ? '30rem' : '5rem')};
  height: 100vh;
  display: flex;
  flex-direction: column;
  transition: 0.35s ease-in-out;
  background-color: ${theme.color.primary};
  z-index: 999;
  overflow: hidden;

  @media screen and (max-width: 1000px) {
    position: fixed;
    height: calc(var(--vh, 1vh) * 100);
  }
`;

export const OpenCloseToggle = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;

// For closed
export const UserInfo = styled.div<{ opened: boolean }>`
  width: ${(props) => (props.opened ? '28rem' : '4rem')};
  margin: 2rem auto;
  overflow: hidden;
  cursor: pointer;
  transition: 0.35s ease-out;
  display: flex;
  gap: 1rem;
`;

export const UserInfoImg = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50rem;
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;

export const UserInfoName = styled.div`
  font-size: 1.8rem;
  color: ${theme.color.white};
  font-weight: bold;
`;

export const UserInfoPosnSpan = styled.span`
  margin-left: 1rem;
  font-size: 1.2rem;
  color: ${theme.color.secondary};
`;

export const UserInfoDeptSpan = styled.span`
  margin-left: 1rem;
  font-size: 1.2rem;
  color: ${theme.color.white};
`;

export const UserInfoAmButton = styled.div<{ backgroundColor: string }>`
  width: 20rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: ${theme.color.white};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : theme.color.veryDarkGrey};
  border-radius: 0.5rem;
  font-weight: bold;
`;

export const LogoutButton = styled.div<{ opened: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  color: ${theme.color.white};
  height: 2rem;
  cursor: pointer;
  position: absolute;
  left: 2rem;
  bottom: 1rem;
  transition: 0.8s ease-in-out;
  opacity: ${(props) => (props.opened ? '1' : '0')};
`;

export const ItemList = styled.div<{ opened: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  width: ${(props) => (props.opened ? '25rem' : '4rem')};
  margin: 5rem auto;
`;

export const ClosedItem = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  margin: 0 auto;
  display: flex;
`;

export const OpenedItem = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  width: 25rem;
  height: 2.5rem;
  cursor: pointer;
  margin: 0 auto;
  border-bottom: 1px solid ${theme.color.white};
  padding: 2rem 0;
`;

export const OpenedItemText = styled.div`
  width: 20rem;
  font-size: 1.8rem;
  color: ${theme.color.white};
  position: relative;
`;

export const OpenedItemSpan = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1.2rem;
  color: ${theme.color.white};
`;
