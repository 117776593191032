import React, { useState, useEffect } from 'react';

import WorkTimePresenter from './WorkTimePresenter';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import apiAmTime from 'api/api-amtime';
import moment, { invalid } from 'moment';
import swal from '@sweetalert/with-react';

const WorkTimeContainer = () => {
  const WorkTimeTableTitle = [
    '시작일시',
    '종료일시',
    '사용자',
    '부서',
    '직책',
    '직급',
    '현재결재자',
    '결재',
  ];
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const userInfo = useRecoilValue(recoilitem.userInfo);
  const [wtHistObject, setWtHistObject] = useState<any>();
  const [isModal, setIsModal] = useState<boolean>(false);
  const [extraDt, setExtraDt] = useState('');
  const [startHour, setStartHour] = useState('');
  const [startMinute, setStartMinute] = useState('');
  const [endHour, setEndHour] = useState('');
  const [endMinute, setEndMinute] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await apiAmTime.getApcList({ adminYn: 'N' });
      if (res.rsltCd === '00') {
        setWtHistObject(res?.data?.apcList);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const formatExtraDtm = (momentObj) =>
    momentObj.isValid() ? momentObj.format('YYYY-MM-DD HH:mm:sss') : '';

  const extraTimeRequest = async () => {
    const startHm = startHour + startMinute;
    const endHm = endHour + endMinute;

    const extraDtIsValid = extraDt !== ''; // extraDt 유효성 검사

    const parsedStartExtraDtm = moment(
      `${extraDt}${startHm}`,
      'YYYY-MM-DDHHmm',
    );
    const parsedEndExtraDtm = moment(`${extraDt}${endHm}`, 'YYYY-MM-DDHHmm');

    const extraStartDtm = extraDtIsValid
      ? formatExtraDtm(parsedStartExtraDtm)
      : '';
    const extraEndDtm = extraDtIsValid ? formatExtraDtm(parsedEndExtraDtm) : '';

    try {
      if (extraDt === '') {
        makeMsg('일자 를 선택해 주세요.', 'warning');
        return;
      } else if (startHour === '') {
        makeMsg('시작 시 를 입력해 주세요.', 'warning');
        return;
      } else if (startMinute === '') {
        makeMsg('시작 분 을 입력해 주세요.', 'warning');
        return;
      } else if (endHour === '') {
        makeMsg('종료 시 를 입력해 주세요.', 'warning');
        return;
      } else if (endMinute === '') {
        makeMsg('종료 분 을 입력해 주세요.', 'warning');
        return;
      }

      const value = await swal({
        text: ' 시간 외 근무신청 하시겠습니까?',
        buttons: {
          confirm: {
            text: '확인',
            closeModal: true,
          },
          cancel: '취소',
        },
      });
      if (value) {
        setIsLoading(true);
        const dto: any = {
          userId: userInfo?.userId,
          startDtm: extraStartDtm,
          endDtm: extraEndDtm,
        };
        const res = await apiAmTime.request(dto);
        if (res.rsltCd === '00') {
          makeMsg('시간외근무 신청을 완료하였습니다.', 'success');
          fetchData();
        }
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const workTimeApproval = async (apprData: any, apprStCd: string) => {
    try {
      let value;
      if (apprStCd === 'APPRST02') {
        value = await swal({
          text: '승인 진행 하시겠습니까?',
          buttons: {
            confirm: {
              text: '확인',
              closeModal: true,
            },
            cancel: '취소',
          },
        });
      } else {
        value = await swal({
          text: '반려 진행 하시겠습니까?',
          content: {
            element: 'input',
            attributes: {
              placeholder: '반려사유를 입력하세요',
              type: 'text',
            },
          },
          buttons: {
            confirm: {
              text: '확인',
              closeModal: true,
            },
            cancel: '취소',
          },
        });
      }
      if (value) {
        setIsLoading(true);
        let description;
        if (value === true) {
          description = '승인되었습니다.';
        } else {
          description = value;
        }
        const dto: any = {
          apcId: apprData?.apcId,
          apprStCd: apprStCd,
          description: description,
        };
        const res = await apiAmTime.approval(dto);

        if (res.rsltCd === '00') {
          makeMsg('결재되었습니다.', 'success');
          fetchData();
        } else {
          makeMsg('신청에 실패했습니다(개발자에게 문의하세요).', 'warning');
        }
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const workTimeCancel = async (apprData: any) => {
    try {
      const value = await swal({
        text: '취소 진행 하시겠습니까?',
        buttons: {
          confirm: {
            text: '확인',
            closeModal: true,
          },
          cancel: '취소',
        },
      });
      if (value) {
        setIsLoading(true);

        const dto: any = {
          apcId: apprData?.apcId,
        };
        const res = await apiAmTime.cancel(dto);

        if (res.rsltCd === '00') {
          makeMsg('취소되었습니다.', 'success');
          fetchData();
        } else {
          makeMsg('취소에 실패했습니다(관리자에게 문의하세요).', 'warning');
        }
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <>
      <WorkTimePresenter
        WorkTimeTableTitle={WorkTimeTableTitle}
        wtHistObject={wtHistObject}
        isModal={isModal}
        setIsModal={setIsModal}
        extraDt={extraDt}
        setExtraDt={setExtraDt}
        setStartHour={setStartHour}
        setStartMinute={setStartMinute}
        setEndHour={setEndHour}
        setEndMinute={setEndMinute}
        extraTimeRequest={extraTimeRequest}
        workTimeApproval={workTimeApproval}
      />
    </>
  );
};

WorkTimeContainer.defaultProps = {};

export default WorkTimeContainer;
