import React, { useState, useEffect } from 'react';

import VcSettingPresenter from './VcSettingPresenter';
import apiVclog from 'api/api-vclog';
import apiUser from 'api/api-user';
import moment from 'moment';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import swal from '@sweetalert/with-react';

const VcSettingContainer = () => {
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [vcObject, setVcObject] = useState<any>();
  const [searchData, setSearchData] = useState<any>();
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isUpdateModal, setIsUpdateModal] = useState<boolean>(false);
  const [userNm, setUserNm] = useState<any>();
  const [userEmpno, setUserEmpno] = useState<any>();
  const [showDropdown, setShowDropdwon] = useState(false);
  const [userValue, setUserValue] = useState();
  const [optionSelect, setOptionSelect] = useState<any | undefined>();
  const [vacationTypeSelect, setVacationTypeSelect] = useState<any>('');
  const [vacationNm, setVacationNm] = useState<any>('일반연차');
  const [vacationStartDtm, setVacationStartDtm] = useState('');
  const [vacationEndDtm, setVacationEndDtm] = useState('');
  const [vacationTime, setVacationTime] = useState<any>(0);
  const [updateUserInfo, setUpdateUserInfo] = useState<any | undefined>();
  const [startDt, setStartDt] = useState(moment().format('YYYY0101'));
  const [endDt, setEndDt] = useState(moment().format('YYYY1231'));

  const vacationTableTitle = [
    '이름',
    '부서',
    '직급',
    '휴가종류',
    '휴가 사용 가능 년/월/일',
    '부여시간',
    '남은시간',
    '비고',
  ];

  const vacationType = [
    { value: 'AMVACTYPE01', label: '일반연차' },
    { value: 'AMVACTYPE02', label: '특별연차' },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    searchUser();
  }, [userNm]);

  useEffect(() => {
    if (vacationTypeSelect === 'AMVACTYPE01') setVacationNm('일반연차');
  }, [vacationTypeSelect]);

  const fetchData = async () => {
    try {
      const dto: any = {
        startDt: moment().format('YYYY0101'),
        endDt: moment().format('YYYY1231'),
        adminYn: 'Y',
        keyword: '',
      };
      const res = await apiVclog.getList(dto);
      if (res.rsltCd === '00') {
        setVcObject(res.data?.vacationList);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const fetchAdminData = async () => {
    try {
      const dto: any = {
        startDt: moment(startDt).format('YYYYMMDD'),
        endDt: moment(endDt).format('YYYYMMDD'),
        adminYn: 'Y',
        keyword: searchData,
      };
      const res = await apiVclog.getList(dto);
      if (res.rsltCd === '00') {
        setVcObject(res.data?.vacationList);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const searchUser = async () => {
    try {
      const dto: any = {
        startDt: startDt,
        endDt: endDt,
        keyword: userNm,
      };
      if (userNm?.length >= 2) {
        const res = await apiUser.getList(dto);
        if (res?.rsltCd === '00') {
          setShowDropdwon(true);
          setUserValue(res.data.userList);
        }
      } else {
        setShowDropdwon(false);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const optionSelectUser = async (user: any) => {
    setShowDropdwon(false);
    setOptionSelect(user);
  };

  const reqVacation = async () => {
    try {
      const value = await swal({
        text: ' 휴가등록 하시겠습니까?',
        buttons: {
          confirm: {
            text: '확인',
            closeModal: true,
          },
          cancel: '취소',
        },
      });
      if (value) {
        if (!vacationStartDtm || !vacationEndDtm) {
          makeMsg('휴가 날짜를 넣어주세요.', 'warning');
          return;
        }
        if (!vacationTypeSelect) {
          makeMsg('휴가 종류를 선택해주세요..', 'warning');
          return;
        }
        if (!vacationNm) {
          makeMsg('특별연차 이름을 입력해주세요.', 'warning');
          return;
        }
        if (!vacationTime || !optionSelect?.userId) {
          makeMsg('부여시간을 입력해주세요.', 'warning');
          return;
        }
        if (!optionSelect?.userId) {
          makeMsg('직원을 선택해주세요.', 'warning');
          return;
        }

        const dto: any = {
          startDt: vacationStartDtm.replaceAll('-', ''),
          endDt: vacationEndDtm.replaceAll('-', ''),
          vacationTypeCd: vacationTypeSelect,
          vacationTypeNm: vacationNm, // 일반연차일경우 위에서 vacationNm('일반연차')로 설정
          timeAmount: vacationTime,
          userId: optionSelect?.userId,
        };

        const res = await apiVclog.insert(dto);
        if (res.rsltCd === '00') {
          makeMsg('등록되었습니다.', 'success');
          setIsModal(false);
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
          makeMsg('데이터가 잘못 되었습니다.', 'warning');
          setIsModal(false);
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const updVacation = async () => {
    try {
      const value = await swal({
        text: ' 휴가수정 하시겠습니까?',
        buttons: {
          confirm: {
            text: '확인',
            closeModal: true,
          },
          cancel: '취소',
        },
      });
      if (value) {
        const dto: any = {
          vacationId: updateUserInfo.vacationId,
          startDt: vacationStartDtm.replaceAll('-', ''),
          endDt: vacationEndDtm.replaceAll('-', ''),
          vacationTypeCd: updateUserInfo.vacationTypeCd,
          vacationTypeNm: updateUserInfo.vacationTypeNm,
          timeAmount: vacationTime,
          userId: updateUserInfo?.userId,
        };
        const res = await apiVclog.update(dto);
        if (res.rsltCd === '00') {
          makeMsg('수정되었습니다.', 'success');
          setIsUpdateModal(false);
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
          makeMsg('데이터가 잘못 되었습니다.', 'warning');
          setIsUpdateModal(false);
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const updateVcModal = async (row: any) => {
    setIsUpdateModal(true);
    setUpdateUserInfo(row);
    setVacationTime(row.timeAmount);
    setVacationStartDtm(moment(row?.startDt).format('YYYY-MM-DD'));
    setVacationEndDtm(moment(row?.endDt).format('YYYY-MM-DD'));
  };

  const modalContentsReset = async () => {
    setShowDropdwon(false);
    setVacationStartDtm('');
    setVacationEndDtm('');
    setVacationTypeSelect(null);
    setVacationNm(null);
    setVacationTime(null);
    setOptionSelect(null);
  };

  return (
    <>
      <VcSettingPresenter
        vacationTableTitle={vacationTableTitle}
        vcObject={vcObject}
        searchData={searchData}
        setSearchData={setSearchData}
        fetchAdminData={fetchAdminData}
        isModal={isModal}
        setIsModal={setIsModal}
        userNm={userNm}
        setUserNm={setUserNm}
        searchUser={searchUser}
        reqVacation={reqVacation}
        userEmpno={userEmpno}
        userValue={userValue}
        showDropdown={showDropdown}
        optionSelect={optionSelect}
        optionSelectUser={optionSelectUser}
        setOptionSelect={setOptionSelect}
        vacationType={vacationType}
        vacationTypeSelect={vacationTypeSelect}
        setVacationTypeSelect={setVacationTypeSelect}
        setVacationNm={setVacationNm}
        setVacationStartDtm={setVacationStartDtm}
        setVacationEndDtm={setVacationEndDtm}
        setVacationTime={setVacationTime}
        modalContentsReset={modalContentsReset}
        isUpdateModal={isUpdateModal}
        setIsUpdateModal={setIsUpdateModal}
        updateVcModal={updateVcModal}
        updateUserInfo={updateUserInfo}
        vacationStartDtm={vacationStartDtm}
        vacationEndDtm={vacationEndDtm}
        vacationTime={vacationTime}
        updVacation={updVacation}
        startDt={startDt}
        setStartDt={setStartDt}
        endDt={endDt}
        setEndDt={setEndDt}
      />
    </>
  );
};

VcSettingContainer.defaultProps = {};

export default VcSettingContainer;
