import theme from 'components/styles/theme';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';

export const Container = styled.div`
  width: 100%;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

// table start

export const Reason = styled.div`
  width: 80rem;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #1a2d56;
  padding: 1rem 1.5rem;
  color: #fff;
`;

export const TableTitle = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
  margin-top: 3rem;
`;

export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 0.2rem solid ${theme.color.primary};
  border-bottom: 0.2rem solid ${theme.color.primary};
  width: 100%;
  margin: 2rem auto 5rem;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr``;

export const Th = styled.th`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
`;

export const Td = styled.td`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
  position: relative;
  & > :last-child {
    border-bottom: 0;
  }

  &.expanded {
    ${Reason} {
      animation: scale(1) 0.5s ease;
    }
  }
`;

export const BtnArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
`;

export const ApprBtn = styled.div<{ primary?: boolean }>`
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid
    ${(props) => (props.primary ? theme.color.primary : theme.color.black)};
  background-color: ${(props) =>
    props.primary ? theme.color.primary : theme.color.white};
  color: ${(props) => (props.primary ? theme.color.white : theme.color.black)};
  cursor: pointer;
`;

// table end
