import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';

import NotReadyPresenter from './NotReadyPresenter';

const NotReadyContainer = () => {
  const setOpenLeftNavi = useSetRecoilState(recoilitem.openLeftNavi);

  const onFocused = () => {
    setOpenLeftNavi(false);
  };

  return (
    <>
      <NotReadyPresenter onFocused={onFocused} />
    </>
  );
};

NotReadyContainer.defaultProps = {};

export default NotReadyContainer;
