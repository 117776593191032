import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

import ApprboxPresenter from './ApprboxPresenter';
import apiAppr from 'api/api-appr';
import moment from 'moment';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import swal from '@sweetalert/with-react';
import { useNavigate } from 'react-router-dom';

const ApprboxContainer = () => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(recoilitem.userInfo);
  const ApprTableTitle = [
    '문서번호',
    '기안양식',
    '기안제목',
    '상신일시',
    '결재자',
    '결재상태',
  ];
  const apprType = [
    { cd: 'all', nm: '전체' },
    { cd: 'APPRST02', nm: '승인' },
    { cd: 'APPRST03', nm: '반려' },
    { cd: 'APPRST04', nm: '취소' },
  ];

  const [mainActiveTab, setMainActiveTab] = useState<number>(1);
  const tabList = ['상신함', '결재함'];
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [apprObject, setApprObject] = useState();
  const [startDt, setStartDt] = useState(
    moment().startOf('year').format('YYYYMMDD'),
  );
  const [endDt, setEndDt] = useState(moment().endOf('year').format('YYYYMMDD'));
  const [search, setSearch] = useState(false);

  useEffect(() => {
    fetchData();
  }, [search]);
  const [apprStCd, setApprStCd] = useState('all');
  const [keyword, setKeyword] = useState('');

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const dto = {
        apprStCd: apprStCd,
        userId: userInfo.userId,
        startDt: startDt,
        endDt: endDt,
        keyword: keyword,
      };
      const res = await apiAppr.toApproval(dto); // 결재함

      if (res.rsltCd === '00') {
        //상신함
        setApprObject(res.data.apcList);
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const movePage = (page) => {
    navigate(page);
  };

  return (
    <>
      <ApprboxPresenter
        ApprTableTitle={ApprTableTitle}
        setMainActiveTab={setMainActiveTab}
        mainActiveTab={mainActiveTab}
        tabList={tabList}
        apprObject={apprObject}
        setStartDt={setStartDt}
        setEndDt={setEndDt}
        setSearch={setSearch}
        search={search}
        apprType={apprType}
        setApprStCd={setApprStCd}
        setKeyword={setKeyword}
        movePage={movePage}
      />
    </>
  );
};

ApprboxContainer.defaultProps = {};

export default ApprboxContainer;
