import React, { Dispatch, SetStateAction } from 'react';
import * as s from './DocumentboxStyled';
import theme from 'components/styles/theme';
import moment from 'moment';
interface DocumentboxProps {
  ApprTableTitle: any;
  setMainActiveTab: Dispatch<SetStateAction<any>>;
  mainActiveTab: any;
  tabList: any;
  apprObject: any;
  setStartDt: Dispatch<SetStateAction<any>>;
  setEndDt: Dispatch<SetStateAction<any>>;
  setSearch: Dispatch<SetStateAction<any>>;
  search: any;
  apprType: any;
  setApprStCd: Dispatch<SetStateAction<any>>;
  movePage: (page: any) => void;
}
const DocumentboxPresenter = (props: DocumentboxProps) => {
  return (
    <s.Container>
      <s.Title>결재함</s.Title>
      <s.SearchWrapper>
        <s.SearchTitle>상신일</s.SearchTitle>
        <s.ModalInput //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
          type="date"
          style={{
            colorScheme: 'white',
            marginRight: '1rem',
          }}
          max={'9999-12-31'}
          onChange={(e) => props.setStartDt(e.target.value)}
        />
        ~
        <s.ModalInput //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
          type="date"
          style={{
            colorScheme: 'white',
            marginLeft: '1rem',
          }}
          max={'9999-12-31'}
          onChange={(e) => props.setEndDt(e.target.value)}
        />
      </s.SearchWrapper>

      <s.SearchWrapper style={{ marginBottom: '5rem' }}>
        <s.SearchTitle>검색</s.SearchTitle>
        <s.ModalInput //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
          type="text"
          placeholder="기안 양식 또는 제목을 입력하세요."
          style={{ width: '500px' }}
        />
        <s.SearchBtn onClick={() => props.setSearch(!props.search)}>
          검색
        </s.SearchBtn>
      </s.SearchWrapper>
      <s.Table>
        <s.Tbody>
          <s.Tr style={{ background: '#1A2D56' }}>
            {props.ApprTableTitle?.map((item: any) => (
              <s.Th>{item}</s.Th>
            ))}
          </s.Tr>
          {props.apprObject?.length > 0 ? (
            props.apprObject
              ?.filter(
                (row: any) =>
                  row.apprStCdNm === '승인' || row.apprStCdNm === '반려',
              )
              ?.map((row: any) => (
                <s.Tr
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    props.movePage(`/approval/detail?apcId=${row.apcId}`)
                  }
                >
                  <s.Td>{row.apprTypeId}</s.Td>
                  <s.Td>{row.apprTypeTitle}</s.Td>
                  <s.Td>{row.apcTitle}</s.Td>
                  <s.Td>{moment(row.regDtm).format('YYYY-MM-DD HH:mm')}</s.Td>
                  <s.Td>{row.apprUserNm}</s.Td>
                  <s.Td>{row.apprStCdNm}</s.Td>
                </s.Tr>
              ))
          ) : (
            <s.Tr>
              <s.Td colSpan={5}>{'데이터가 없습니다.'}</s.Td>
            </s.Tr>
          )}
        </s.Tbody>
      </s.Table>
    </s.Container>
  );
};

export default DocumentboxPresenter;
