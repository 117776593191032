import apiService from './api-base';

const PACKAGE = 'appr/apc';
const MGNTPACKAGE = 'appr/type/mgnt';

const apiAppr = {
  all: (dto: any) => apiService.post(PACKAGE + '/all', dto),
  approval: (dto: any) => apiService.post(PACKAGE + '/approval', dto),
  userInsert: (dto: any) => apiService.post(PACKAGE + '/insert', dto),
  userUpdate: (dto: any) => apiService.post(PACKAGE + '/update', dto),
  toApproval: (dto: any) => apiService.post(PACKAGE + '/to-approve', dto),
  userDetail: (dto: any) => apiService.post(PACKAGE + '/detail', dto),
  decision: (dto: any) => apiService.post(PACKAGE + '/decision', dto),
  recall: (dto: any) => apiService.post(PACKAGE + '/recall', dto),
  getList: () => apiService.post(MGNTPACKAGE + '/getList'),
  detail: (dto: any) => apiService.post(MGNTPACKAGE + '/detail', dto),
  insert: (dto: any) => apiService.post(MGNTPACKAGE + '/insert', dto),
  update: (dto: any) => apiService.post(MGNTPACKAGE + '/update', dto),
  delete: (dto: any) => apiService.post(MGNTPACKAGE + '/delete', dto),
};

export default apiAppr;
