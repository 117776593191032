import { Dispatch, SetStateAction } from 'react';
import * as s from './WorkTimeStyled';
import moment from 'moment';
import { MdClose } from 'react-icons/md';

interface WorkTimeProps {
  WorkTimeTableTitle: any;
  wtHistObject: any;
  isModal: boolean;
  setIsModal: Dispatch<SetStateAction<boolean>>;
  extraDt: any;
  setExtraDt: Dispatch<SetStateAction<any>>;
  setStartHour: Dispatch<SetStateAction<any>>;
  setStartMinute: Dispatch<SetStateAction<any>>;
  setEndHour: Dispatch<SetStateAction<any>>;
  setEndMinute: Dispatch<SetStateAction<any>>;
  extraTimeRequest: () => void;
  workTimeApproval: (apprData: any, apprStCd: string) => void;
}
const WorkTimePresenter = (props: WorkTimeProps) => {
  return (
    <s.Container>
      <s.AmExtraTableTitle>
        시간외근무
        <s.AmExtraReqBtn onClick={() => props.setIsModal(true)}>
          시간외근무 신청
        </s.AmExtraReqBtn>
      </s.AmExtraTableTitle>

      <s.AmExtraTable>
        <s.AmExtraTbody>
          <s.AmExtraTr>
            {props.WorkTimeTableTitle?.map((item: any) => (
              <s.AmExtraTh>{item}</s.AmExtraTh>
            ))}
          </s.AmExtraTr>
          {props.wtHistObject?.length > 0 ? (
            props.wtHistObject?.map((row: any) => (
              <s.AmExtraTr>
                <s.AmExtraTd>
                  {moment(row?.startDtm).format('YYYY-MM-DD HH:mm')}
                </s.AmExtraTd>
                <s.AmExtraTd>
                  {moment(row?.endDtm).format('YYYY-MM-DD HH:mm')}
                </s.AmExtraTd>
                <s.AmExtraTd>{row?.userNm}</s.AmExtraTd>
                <s.AmExtraTd>{row?.deptNm}</s.AmExtraTd>
                <s.AmExtraTd>{row?.posnNm}</s.AmExtraTd>
                <s.AmExtraTd>{row?.rankNm}</s.AmExtraTd>
                <s.AmExtraTd>
                  {row?.nowturn ? <>{row.nowturn}</> : '결재완료'}
                </s.AmExtraTd>
                <s.AmExtraTd>
                  {row?.nowturn ? (
                    '결재중'
                  ) : row?.state ? (
                    <>
                      {row.state}
                      {row.state === '반려' && `(${row?.description})`}
                    </>
                  ) : (
                    ''
                  )}
                </s.AmExtraTd>
              </s.AmExtraTr>
            ))
          ) : (
            <s.AmExtraTr>
              <s.AmExtraTd colSpan={8}>{'데이터가 없습니다.'}</s.AmExtraTd>
            </s.AmExtraTr>
          )}
        </s.AmExtraTbody>
      </s.AmExtraTable>

      {props.isModal && (
        <s.PopupBackground onClick={() => props.setIsModal(false)}>
          <s.PopupModal onClick={(e) => e.stopPropagation()}>
            <s.ModalCloseButton onClick={() => props.setIsModal(false)}>
              <MdClose size={'100%'} color={'#fff'} />
            </s.ModalCloseButton>
            <s.ModalTitle>시간외근무 신청</s.ModalTitle>

            <s.ModalButtonWrapper>
              <s.ModalInputLabel>일자</s.ModalInputLabel>
              <s.ModalInput
                type="date"
                style={{
                  colorScheme: 'dark',
                }}
                max={'9999-12-31'}
                value={props.extraDt}
                onChange={(e) => props.setExtraDt(e.target.value)}
              />
            </s.ModalButtonWrapper>
            <s.ModalButtonWrapper>
              <s.ModalInputLabel>시작시간</s.ModalInputLabel>
              <s.ModalInput
                type="text"
                inputMode="numeric"
                placeholder="시"
                style={{ width: '8rem' }}
                onInput={checkHour}
                onChange={(e) => props.setStartHour(e.target.value)}
              ></s.ModalInput>
              :
              <s.ModalInput
                type="text"
                inputMode="numeric"
                placeholder="분"
                min="0"
                max="59"
                style={{ width: '8rem' }}
                onInput={checkMinute}
                onChange={(e) => props.setStartMinute(e.target.value)}
              ></s.ModalInput>
            </s.ModalButtonWrapper>
            <s.ModalButtonWrapper>
              <s.ModalInputLabel>종료시간</s.ModalInputLabel>
              <s.ModalInput
                type="text"
                inputMode="numeric"
                placeholder="시"
                min="0"
                max="23"
                style={{ width: '8rem' }}
                onInput={checkHour}
                onChange={(e) => props.setEndHour(e.target.value)}
              ></s.ModalInput>
              :
              <s.ModalInput
                type="text"
                inputMode="numeric"
                placeholder="분"
                style={{ width: '8rem' }}
                onInput={checkMinute}
                onChange={(e) => props.setEndMinute(e.target.value)}
              ></s.ModalInput>
            </s.ModalButtonWrapper>
            <s.ModalButtonWrapper style={{ marginTop: '4rem' }}>
              <s.ModalButton
                onClick={() => props.setIsModal(false)}
                style={{ color: '#fff', background: '#9a9a9a' }}
              >
                취소
              </s.ModalButton>
              <s.ModalButton onClick={() => props.extraTimeRequest()}>
                신청
              </s.ModalButton>
            </s.ModalButtonWrapper>
          </s.PopupModal>
        </s.PopupBackground>
      )}
    </s.Container>
  );
};

const checkHour = (event) => {
  let numericValue = event.target.value.replace(/\D/g, '');
  if (numericValue !== '') {
    numericValue = parseInt(numericValue, 10);
    if (numericValue < 0) numericValue = 0;
    if (numericValue > 23) numericValue = 23;
  }
  const formattedValue = numericValue.toString().padStart(2, '0');
  event.target.value = formattedValue;
};

const checkMinute = (event) => {
  let numericValue = event.target.value.replace(/\D/g, '');
  if (numericValue !== '') {
    numericValue = parseInt(numericValue, 10);
    if (numericValue < 0) numericValue = 0;
    if (numericValue > 59) numericValue = 59;
  }
  const formattedValue = numericValue.toString().padStart(2, '0');
  event.target.value = formattedValue;
};

export default WorkTimePresenter;
