import React, { Dispatch, SetStateAction } from 'react';
import * as s from './ApprMgntStyled';
import { Link } from 'react-router-dom';
import NotReady from '../../notReady';
import moment from 'moment';
import Calendar from 'components/calendar';
import { MdClose } from 'react-icons/md';
import theme from 'components/styles/theme';
import { FaSearch } from 'react-icons/fa';
import Regist from './regist';

interface ApprMgntProps {
  amSetTableTitle: any[];
  apprObject: any;
  movePage: (page: any) => void;
}

const ApprMgntPresenter = (props: ApprMgntProps) => {
  return (
    <s.Container>
      <s.TableTitle>기안양식 관리</s.TableTitle>
      <s.Table>
        <s.Tbody>
          <s.Tr>
            {props.amSetTableTitle?.map((item: any) => (
              <s.Th>{item}</s.Th>
            ))}
          </s.Tr>
          <>
            {props.apprObject?.map((item) => (
              <s.Tr
                onClick={() =>
                  props.movePage(
                    `/manager/detail?apprTypeId=${item.apprTypeId}`,
                  )
                }
                style={{ cursor: 'pointer' }}
              >
                <s.Td>{item.apprTypeId}</s.Td>
                <s.Td>{item.title}</s.Td>
                <s.Td>{moment(item?.updDtm).format('YYYY-MM-DD HH:mm')}</s.Td>
              </s.Tr>
            ))}
          </>
        </s.Tbody>
      </s.Table>

      <s.SaveBtn onClick={() => props.movePage('/manager/regist')}>
        양식 등록
      </s.SaveBtn>
    </s.Container>
  );
};

export default ApprMgntPresenter;
