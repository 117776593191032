import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';

import AmChangeLogPresenter from './AmChangeLogPresenter';
import moment from 'moment';
import apiAmlog from 'api/api-amlog';
import { makeMsg } from 'util/util';

const AmChangeLogContainer = () => {
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [selectedYearMonth, setSelectedYearMonth] = useState(
    moment().startOf('month'),
  );
  const [amApcObject, setAmApcObject] = useState<any>();
  const [changeAmApcObject, setChangeAmApcObject] = useState<any>();

  const amChangeTableTitle = [
    '요청자',
    '부서',
    '변경기간',
    '시작시간',
    '종료시간',
    '현재결재자',
    '결재상태',
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const resChangeAm = await apiAmlog.getChangeApcList();

      if (resChangeAm.rsltCd === '00') {
        setChangeAmApcObject(resChangeAm.data?.apcList);
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <>
      <AmChangeLogPresenter
        amChangeTableTitle={amChangeTableTitle}
        changeAmApcObject={changeAmApcObject}
      />
    </>
  );
};

AmChangeLogContainer.defaultProps = {};

export default AmChangeLogContainer;
