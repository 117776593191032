import apiService from './api-base';

const PACKAGE = 'am/config/work';

const apiAmTime = {
  getList: () => apiService.post(PACKAGE + '/getList'),

  update: (dto: any) => apiService.post(PACKAGE + '/update', dto),
};

export default apiAmTime;
