import styled from 'styled-components';

import theme from 'components/styles/theme';
import { FaSearch } from 'react-icons/fa';
import { LiaTimesCircleSolid } from 'react-icons/lia';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5rem 10rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  @media screen and (max-width: 1000px) {
    padding: 5rem 3rem 5rem 8rem;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
  margin-top: 3rem;
`;

export const ContentTitle = styled.div`
  width: 10rem;
  font-size: 1.5rem;
  color: ${theme.color.black};
  position: relative;
  text-align: left;
  font-weight: bold;
`;

export const Wrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: start;
  margin-left: 6rem;
  margin-top: 3rem;
`;

export const ColumnDetailWrapper = styled.div<{ cnt?: any }>`
  padding: 1rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: ${(props) => (props.cnt > 0 ? '0' : null)};
`;
export const ColumnDetailAddWrapper = styled.div<{ isLast?: any }>`
  padding: 1rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  border-top: 0;
  border-bottom: 0;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const DetailItemDelete = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
`;

export const ColumnWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  gap: 3rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  font-size: 14px;
  padding: 0.1rem 0;
`;

export const DocWrapper = styled.div`
  display: flex;
  font-size: 12px;
  padding: 1rem 1rem;
  background-color: #e2dffa;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
`;

export const NewItemWrapper = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  /* padding: 1rem 2rem; */
`;

export const NewDetileItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* background: #eee; */
  border-radius: 5px;
  position: relative;
`;

export const NewDetileItemAddWrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* background: #eee; */
  border-radius: 5px;
  position: relative;
`;

export const DeleteItem = styled(LiaTimesCircleSolid)`
  font-size: 1.5rem;
  color: red;
  cursor: pointer;
  margin-left: 1rem;
`;

export const MdExit = styled(LiaTimesCircleSolid)`
  font-size: 2rem;
  color: red;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
`;

export const DetailAdd = styled.div`
  width: 10rem;
  height: 10rem;
  border: 1px dotted #000;
  display: flex;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Text = styled.div`
  display: flex;
  justify-content: end;
  font-size: 14px;
  width: 15rem;
  padding: 0.1rem 0;
`;

export const InputData = styled.input`
  display: flex;
  font-size: 12px;
  padding: 0.5rem 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
`;

export const FileUpload = styled.div`
  width: 8rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0.1px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background: linear-gradient(180deg, #ffffffcf 0%, rgb(171, 171, 171) 100%);
  align-content: center;
  border-radius: 5px;
  color: #000;
  font-size: 1.3rem;
  align-self: flex-start;
`;

export const DocBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 2.5rem;
  font-weight: 400;
  cursor: pointer;
`;

export const addDetailBtn = styled.div`
  width: 50px;
  height: 30px;
  font-size: 12px;
  padding: 0.5rem 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  background-color: ${theme.color.lightGrey};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
  margin-left: auto;
`;

export const AddBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.7rem;
  height: 1.7rem;
  margin-left: 2rem;
  border: 1px solid ${theme.color.secondary};
  border-radius: 50%;
  color: ${theme.color.white};
  background-color: ${theme.color.secondary};
  cursor: pointer;
`;

export const RecallBtn = styled.div`
  font-size: 14px;
  padding: 1rem 1.5rem;
  border: 1px solid ${theme.color.darkGrey};
  background-color: ${theme.color.darkGrey};
  color: ${theme.color.white};
  border-radius: 0.5rem;
  width: fit-content;
  margin-top: 1rem;
  display: flex;
  cursor: pointer;
`;

export const SaveBtn = styled.div`
  font-size: 14px;
  padding: 1rem 1.5rem;
  border: 1px solid ${theme.color.primary};
  background-color: ${theme.color.primary};
  color: ${theme.color.white};
  border-radius: 0.5rem;
  width: fit-content;
  margin-top: 1rem;
  display: flex;
  cursor: pointer;
`;

export const CancelBtn = styled.div`
  font-size: 14px;
  padding: 1rem 1.5rem;
  border: 1px solid ${theme.color.primary};
  background-color: ${theme.color.white};
  color: ${theme.color.primary};
  border-radius: 0.5rem;
  width: fit-content;
  margin-top: 1rem;
  display: flex;
  cursor: pointer;
`;

export const DeleteBtn = styled.div`
  font-size: 14px;
  padding: 1rem 1.5rem;
  background-color: ${theme.color.red};
  color: ${theme.color.white};
  border-radius: 0.5rem;
  width: fit-content;
  margin-top: 1rem;
  display: flex;
  cursor: pointer;
`;

export const MyBlock = styled.div`
  width: 100%;
  border: 1px solid ${theme.color.lightGrey};
  margin-top: 2rem;
  .wrapper-class {
    width: 100%;
    margin: 0 auto;
  }
  .editor {
    height: 30rem;
    padding: 5px !important;
    background-color: white;
  }
  .DraftEditor-root {
    line-height: 0.8; /* 원하는 간격으로 조절 (예: 1.2) */
  }
`;

// popup start

export const PopupBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(102, 102, 102, 0.7);
  z-index: 20;
  left: 0;
  top: 0;
`;

export const PopupModal = styled.div`
  width: 80%;
  max-height: 70rem;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.white};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  top: 10rem;
  z-index: 999;
  overflow-y: auto;
`;

export const ModalCloseButton = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1.8rem;
  top: 1rem;
`;

export const ModalTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.black};
  margin-bottom: 3rem;
  font-weight: bold;
`;

export const SearchWrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 1rem;
`;

export const SearchTitle = styled.div`
  width: 100px;
  font-size: 1.4rem;
  text-align: center;
`;

export const SearchBtn = styled.div`
  margin-left: 1rem;
  width: 80px;
  font-size: 1.2rem;
  padding: 0.8rem;
  background-color: ${theme.color.primary};
  color: ${theme.color.white};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalInputRow = styled.div`
  width: 40%;
  display: flex;
  gap: 1rem;
  font-size: 1.4rem;
  color: ${theme.color.white};
`;

export const ModalInputLabel = styled.div`
  font-size: 1.6rem;
  color: ${theme.color.white};
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ModalInput = styled.input`
  width: 19.5rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.white};
  color: ${theme.color.darkGrey};
  font-size: 1.2rem;
  border: 1px solid ${theme.color.grey};
  padding: 1rem;
`;

export const ModalCheck = styled.input`
  border-radius: 0.5rem;
  background-color: ${theme.color.white};
  color: ${theme.color.darkGrey};
  font-size: 1.2rem;
  border: 1px solid ${theme.color.grey};
  padding: 1rem;
`;

export const ModalText = styled.textarea`
  width: 40%;
  border-radius: 0.5rem;
  background-color: ${theme.color.black};
  color: ${theme.color.white};
  font-size: 1.4rem;
  border: 0;
  padding: 1rem;
  resize: none;
`;

export const ModalNotifyDiv = styled.div`
  font-size: 0.8rem;
  width: 100%;
  text-align: left;
  color: ${theme.color.red};
  padding-left: 25%;
`;

export const ModalButtonWrapper = styled.div`
  width: 100%;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export const ModalButton = styled.div<{ primary?: boolean }>`
  width: 15rem;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  border: 1px solid
    ${(props) => (props.primary ? theme.color.primary : theme.color.black)};
  background-color: ${(props) =>
    props.primary ? theme.color.primary : theme.color.white};
  color: ${(props) => (props.primary ? theme.color.white : theme.color.black)};
  padding: 1rem;
  margin-top: 3rem;
`;

export const Select = styled.select<{ short?: boolean }>`
  width: 139px;
  margin-left: 2rem;
  height: auto;
  padding: 0.1rem 3px;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  color: ${theme.color.black};
`;

export const SelectOption = styled.option`
  font-size: 1.2rem;
`;

//popup end

export const CcLine = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
  gap: 1rem;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
`;

export const ApprLine = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  gap: 1rem;
`;

export const ApprLineCancel = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
`;

export const CcLineCancel = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  cursor: pointer;
  color: #000;
  z-index: 999;
`;

export const FileName = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2px 10px;
`;

export const FileCancel = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  cursor: pointer;
  color: red;
  z-index: 999;
  justify-content: center;
  align-items: center;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 2.5rem;
  gap: 1rem;
  border: 1px solid #cecece;
  border-radius: 5px;
  justify-content: center;
  position: relative;
  align-items: center;
`;

export const CcUserInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.5rem;
  gap: 1rem;
  border-radius: 5px;
  justify-content: center;
  position: relative;
  align-items: center;
  background-color: #f2f6ff;
  color: #717aff;
`;

export const BtnWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: end;
`;

// table start

export const TableTitle = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
  margin-top: 3rem;
`;

export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 0.2rem solid ${theme.color.primary};
  border-bottom: 0.2rem solid ${theme.color.primary};
  width: 100%;
  margin: auto;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr``;

export const Th = styled.th`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
  color: #fff;
`;

export const Td = styled.td`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
  position: relative;
  text-align: center;
  & > :last-child {
    border-bottom: 0;
  }
`;

export const BtnArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
`;

// table end

export const DetailWrapper = styled.div<{ cnt?: any }>`
  padding: 1rem 1rem;
  flex: 1;
  display: flex;
  border: 1px solid #000;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const DetailTitle = styled.div`
  width: 10rem;
  font-size: 1.5rem;
  color: ${theme.color.black};
  position: relative;
  text-align: left;
  font-weight: bold;
`;

export const Delete = styled.button`
  display: flex;
  color: red;
  font-size: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
`;
