import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as s from './DetailStyled';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html'; // draft-js 컨텐츠를 HTML로 변환하는 라이브러리
import { MdClose } from 'react-icons/md';
import moment from 'moment';
interface DetailProps {
  apprDetail: any;
  apprHeader: any[];
  apprLineList: any;
  movePage: (page: any) => void;
  userInfo: any;
  setIsApprModal: Dispatch<SetStateAction<boolean>>;
  isApprModal: any;
  approval: (apprStCd: string) => void;
  description: any;
  setDescription: Dispatch<SetStateAction<any>>;
  openNewPage: (url: string) => void;
  questionTitle: any;
  recall: () => void;
}
const DetailPresenter = (props: DetailProps) => {
  const contentHtml = props.apprDetail?.content || '';

  // HTML 태그 제거 및 줄바꿈 처리
  const textContent = contentHtml.replace(/<[^>]+>/g, ''); // HTML 태그 제거
  const lines = textContent.split('\n');
  const formattedText = lines.map((line) => {
    return line;
  });

  // 줄바꿈을 포함한 문자열을 EditorState로 변환
  const textWithLineBreaks = formattedText.join('\n');
  const contentState = ContentState.createFromText(textWithLineBreaks);
  const editorState = EditorState.createWithContent(contentState);

  return (
    <s.Container>
      <s.ApprContainer>
        <s.MainTitle>{props.apprDetail?.apcNm}</s.MainTitle>
        <s.FlexColumnWrapper style={{ gap: '4rem', padding: '3rem 0' }}>
          <s.Wrapper width={'70%'}>
            <s.FlexRowWrapper>
              <s.FlexRowWrapper>
                <s.Text size={'16px'} fWeight={'700'} width={'110px'}>
                  기안양식
                </s.Text>
                <s.Text size={'15px'}>{props.apprDetail?.apprTypeNm}</s.Text>
              </s.FlexRowWrapper>
              <s.FlexRowWrapper>
                <s.Text size={'16px'} fWeight={'700'} width={'100px'}>
                  문서번호
                </s.Text>
                <s.Text size={'15px'}>{props.apprDetail?.apprTypeId}</s.Text>
              </s.FlexRowWrapper>
              <s.FlexRowWrapper>
                <s.Text size={'16px'} fWeight={'700'} width={'100px'}>
                  공개여부
                </s.Text>
                <s.Text size={'15px'}>{props.apprDetail?.openDvCdNm}</s.Text>
              </s.FlexRowWrapper>
            </s.FlexRowWrapper>
          </s.Wrapper>
          <s.FlexRowWrapper>
            <s.Text size={'16px'} fWeight={'700'} width={'110px'}>
              기안자/부서
            </s.Text>
            <s.Text size={'15px'}>
              {props.apprDetail?.userNm}/{props.apprDetail?.deptNm}
            </s.Text>
          </s.FlexRowWrapper>
          <s.FlexRowWrapper style={{ alignItems: 'start' }}>
            <s.Text size={'16px'} fWeight={'700'} width={'110px'}>
              결재선
            </s.Text>
            {props.apprDetail?.lineList?.filter(
              (item) => item.approverTypeCd === 'APPRTYPE01',
            ).length > 0 && ( // 결재선 선택시 생선되는 Contents
              <s.ApprLine>
                {props.apprDetail?.lineList
                  ?.filter((item) => item?.approverTypeCd === 'APPRTYPE01')
                  .sort((a, b) => a?.apprOrder - b?.apprOrder)
                  .map((item, index) => (
                    <s.UserInfo color={item?.apprStCdNm}>
                      <div>{item?.userNm}</div>
                      <div>({item?.userEmpno})</div>
                      <div style={{ color: '#ccc' }}>{item?.deptNm}</div>
                      <s.UserApprSt color={item?.apprStCdNm}>
                        {item?.apprStCdNm}
                      </s.UserApprSt>
                    </s.UserInfo>
                  ))}
              </s.ApprLine>
            )}
          </s.FlexRowWrapper>
          <s.FlexRowWrapper style={{ alignItems: 'start' }}>
            <s.Text size={'16px'} fWeight={'700'} width={'110px'}>
              참조
            </s.Text>
            {props.apprDetail?.lineList?.filter(
              (item) => item.approverTypeCd === 'APPRTYPE04',
            ).length > 0 && ( // 결재선 선택시 생선되는 Contents
              <s.ApprLine>
                {props.apprDetail?.lineList
                  ?.filter((item) => item.approverTypeCd === 'APPRTYPE04')
                  .map((item, index) => (
                    <s.CcUserInfo>
                      <div>{item?.userNm}</div>
                      <div>({item?.userEmpno})</div>
                      <div style={{ color: '#ccc' }}>{item?.deptNm}</div>
                    </s.CcUserInfo>
                  ))}
              </s.ApprLine>
            )}
          </s.FlexRowWrapper>
          {props?.apprDetail?.answerList?.length > 0 &&
            props?.apprDetail?.answerList?.map((item, index) => (
              <s.FlexRowWrapper style={{ alignItems: 'start' }}>
                <s.Text size={'16px'} fWeight={'700'} width={'110px'}>
                  {item?.questionTitle}
                </s.Text>
                <s.Text size={'16px'} fWeight={'400'} width={'110px'}>
                  {item?.answer !== 'undefined' ? item?.answer : ''}
                </s.Text>
              </s.FlexRowWrapper>
            ))}
          {props.apprDetail?.fileList?.length > 0 && ( //파일
            <s.FlexRowWrapper style={{ alignItems: 'start' }}>
              <>
                <s.Text size={'16px'} fWeight={'700'} width={'110px'}>
                  파일첨부
                </s.Text>
                <s.ApprLine>
                  {props.apprDetail?.fileList?.map((item, index) => (
                    <s.FileInfo
                      onClick={() => window.open(item.fileUrl, '_blank')}
                    >
                      <div>{item?.originalFileName}</div>
                    </s.FileInfo>
                  ))}
                </s.ApprLine>
              </>
            </s.FlexRowWrapper>
          )}
          {props.apprDetail?.refList?.length > 0 && ( // 참조문서
            <s.FlexRowWrapper style={{ alignItems: 'start' }}>
              <>
                <s.Text size={'16px'} fWeight={'700'} width={'110px'}>
                  참조문서
                </s.Text>
                <s.ApprLine>
                  {props.apprDetail?.refList?.map((item, index) => (
                    <s.DocWrapper
                      onClick={() =>
                        props.openNewPage(
                          `/approval/detail?apcId=${item.toApcId}`,
                        )
                      }
                    >
                      {item?.title}
                    </s.DocWrapper>
                  ))}
                </s.ApprLine>
              </>
            </s.FlexRowWrapper>
          )}
          {props?.apprDetail?.detailAnswerList.length > 0 && (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
            >
              <s.Text size={'16px'} fWeight={'700'} color={'#333'}>
                상세내용
              </s.Text>
              <s.FlexColumnWrapper
                style={{
                  border: '1px solid #eee',
                  borderRadius: '5px',
                  padding: '5px',
                  display: 'flex',
                }}
              >
                <s.FlexRowWrapper>
                  {props?.questionTitle?.map((item, index) => (
                    <s.FlexColumnWrapper
                      style={{ alignItems: 'center', gap: '8px' }}
                    >
                      <s.Text size={'16px'} fWeight={'700'}>
                        {item}
                      </s.Text>
                      <div
                        style={{
                          width: '100%',
                          height: '1px',
                          background: '#ccc',
                        }}
                      />
                    </s.FlexColumnWrapper>
                  ))}
                </s.FlexRowWrapper>
                <s.FlexRowWrapper
                  style={{
                    padding: '5px 0',
                    flexDirection: 'column',
                    // gap: '2rem',
                  }}
                >
                  {props?.apprDetail?.detailAnswerList.length > 0 &&
                    props?.apprDetail?.detailAnswerList.map(
                      (item, itemIndex) => (
                        <div style={{ display: 'flex' }}>
                          {props.apprDetail?.detailAnswerList
                            .filter(
                              (item, index) => item.lineOrder === itemIndex,
                            )
                            .map((detailItem) => (
                              <s.FlexColumnWrapper
                                style={{ alignItems: 'center', gap: '8px' }}
                              >
                                <s.Text
                                  size={'16px'}
                                  fWeight={'400'}
                                  style={{ margin: '1rem 0' }}
                                >
                                  {detailItem?.answer}
                                </s.Text>
                              </s.FlexColumnWrapper>
                            ))}
                        </div>
                      ),
                    )}
                </s.FlexRowWrapper>
              </s.FlexColumnWrapper>
            </div>
          )}
          <s.FlexRowWrapper style={{ alignItems: 'start' }}>
            <s.Text size={'16px'} fWeight={'700'} width={'110px'}>
              내용
            </s.Text>
            <s.Contents>
              <Editor editorState={editorState} readOnly toolbarHidden />
            </s.Contents>
          </s.FlexRowWrapper>
        </s.FlexColumnWrapper>
        <s.FlexColumnWrapper
          style={{ margin: '3rem 0', borderBottom: '1px solid #000' }}
        >
          <s.Text
            style={{ padding: '1rem' }}
            size={'16px'}
            fWeight={'700'}
            width={'110px'}
          >
            결재의견
          </s.Text>
        </s.FlexColumnWrapper>

        <s.FlexColumnWrapper>
          <s.FlexRowWrapper style={{ margin: '0 auto' }}>
            <s.Header width={'98px'}>{props.apprHeader[0]}</s.Header>
            <s.Header width={'184px'}>{props.apprHeader[1]}</s.Header>
            <s.Header width={'98px'}>{props.apprHeader[2]}</s.Header>
            <s.Header width={'134px'}>{props.apprHeader[3]}</s.Header>
            <s.Header width={'400px'}>{props.apprHeader[4]}</s.Header>
            <s.Header width={'97px'} border={'0'}>
              비고
            </s.Header>
          </s.FlexRowWrapper>
          {props.apprLineList?.length > 0 &&
            props.apprLineList.map((item, index) => (
              <s.FlexRowWrapper style={{ margin: '0 auto' }}>
                <s.content width={'98px'}>결재</s.content>
                <s.content width={'184px'}>
                  {item.userNm} | {item.deptNm}
                </s.content>
                <s.content width={'98px'} fColor={item.apprStCdNm}>
                  {item.apprStCdNm}
                </s.content>
                <s.content width={'134px'}>
                  {moment(item.apprDtm).format('YYYY.MM.DD HH:mm')}
                </s.content>
                <s.content style={{ justifyContent: 'start' }} width={'400px'}>
                  {item.description}
                </s.content>
                <s.content width={'97px'} border={'0'}></s.content>
              </s.FlexRowWrapper>
            ))}
        </s.FlexColumnWrapper>
        <s.BtnWrapper>
          <s.BackBtn onClick={() => props.movePage('/approval')}>
            돌아가기
          </s.BackBtn>
          {props.apprDetail?.apprUserNm === props.userInfo?.userNm && (
            <s.ApprBtn onClick={() => props.setIsApprModal(true)}>
              결 재
            </s.ApprBtn>
          )}
          {props.apprDetail?.apprStCd === 'APPRST05' && (
            <s.ApprBtn onClick={() => props.recall()}>상 신</s.ApprBtn>
          )}
          {props.apprDetail?.userNm === props.userInfo?.userNm &&
            props.apprDetail?.lineList[0].apprStCdNm === '대기' &&
            props.apprDetail?.apprStCd !== 'APPRST05' && (
              <s.ApprBtn onClick={() => props.recall()}>회 수</s.ApprBtn>
            )}
        </s.BtnWrapper>
      </s.ApprContainer>
      {props.isApprModal && (
        <s.PopupBackground onClick={() => props.setIsApprModal(false)}>
          <s.PopupModal onClick={(e) => e.stopPropagation()}>
            <s.ModalCloseButton onClick={() => props.setIsApprModal(false)}>
              <MdClose size={'100%'} color="#fff" />
            </s.ModalCloseButton>
            <s.ModalTitle>결재하기</s.ModalTitle>
            <s.ModalInputRow>
              <s.ModalText
                placeholder="결재의견을 작성해 주세요."
                onChange={(e) => props.setDescription(e.target.value)}
              />
            </s.ModalInputRow>
            <s.ModalButtonWrapper>
              <s.ModalButton primary onClick={() => props.approval('APPRST02')}>
                승인
              </s.ModalButton>
              <s.ModalButton onClick={() => props.approval('APPRST03')}>
                반려
              </s.ModalButton>
            </s.ModalButtonWrapper>
          </s.PopupModal>
        </s.PopupBackground>
      )}
    </s.Container>
  );
};

export default DetailPresenter;
