import React, { Dispatch, SetStateAction } from 'react';
import * as s from './TreeStyled';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { MdCancel } from 'react-icons/md';

interface TreePresenterProps {
  deptList: any;
  userList: any;
  setTree: Dispatch<SetStateAction<any>>;
  buttonClick: (deptId: any, index: any) => void;
  userFilter: any;
  userIndex: any;
  selectedUser: any;
  setSelectedUser: Dispatch<SetStateAction<any>>;
  user: any[];
  setUser: Dispatch<SetStateAction<any>>;
  ccUser: any[];
  setCcUser: Dispatch<SetStateAction<any>>;
  setUserLine: Dispatch<SetStateAction<any>>;
  setCcUserLine: Dispatch<SetStateAction<any>>;
  userSet: (apprTypeCd: any) => void;
}

const TreePresenter = (props: TreePresenterProps) => {
  return (
    <s.Container>
      <s.Title>결재선 추가</s.Title>
      <s.FlexRow>
        <s.LeftUserWrapper>
          {props.deptList?.map((item, index) => (
            <>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
              >
                <s.PlusBtn
                  onClick={() => props.buttonClick(item.deptId, index)}
                >
                  +
                </s.PlusBtn>
                <s.DeptInfo>{item.deptNm}</s.DeptInfo>
              </div>
              {props.userIndex === index &&
                props.userFilter?.map((item, userIndex) => (
                  <s.UserWrapper
                    key={userIndex}
                    onClick={() => props.setSelectedUser(item)}
                    style={{
                      backgroundColor:
                        props.selectedUser === item
                          ? 'lightblue'
                          : 'transparent',
                    }}
                  >
                    - {item.userNm}/{item.posnNm}/{item.rankNm}
                  </s.UserWrapper>
                ))}
            </>
          ))}
        </s.LeftUserWrapper>
        <s.ColumnWrapper>
          <s.AddApprLine onClick={() => props.userSet('APPRTYPE01')}>
            추가
          </s.AddApprLine>
          <s.AddApprLine
            style={{ background: '#38955f' }}
            onClick={() => props.userSet('APPRTYPE04')}
          >
            참조
          </s.AddApprLine>
        </s.ColumnWrapper>
        <s.RightUserWrapper>
          <s.Wrapper>
            <s.RightTitle>결재선</s.RightTitle>
            {props.user?.map((user, index) => (
              <div style={{ border: '1px solid #cecece' }}>
                <s.ApprLineOrderWrapper>
                  <s.ApprLineOrder>결재 순서 {index + 1}</s.ApprLineOrder>
                  <s.ApprLineOrderCencel
                    onClick={() => {
                      const updatedItems = [...props.user];
                      updatedItems.splice(index, 1);
                      props.setUser(updatedItems);
                    }}
                  >
                    x
                  </s.ApprLineOrderCencel>
                </s.ApprLineOrderWrapper>
                <s.RUserWrapper>
                  {user.userNm}/{user.posnNm}/{user.rankNm}
                </s.RUserWrapper>
              </div>
            ))}
          </s.Wrapper>
          <s.Wrapper style={{ height: '30%', display: 'flex' }}>
            <s.CCTitle>참조</s.CCTitle>
            <div style={{ display: 'flex', gap: '0.3rem', flexWrap: 'wrap' }}>
              {props.ccUser?.map((user, index) => (
                <div
                  style={{
                    border: '1px solid #cecece',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <s.ApprLineOrderWrapper style={{ width: 'fit-content' }}>
                    <s.RUserWrapper>{user.userNm}</s.RUserWrapper>
                    <s.ApprLineOrderCencel
                      onClick={() => {
                        const updatedItems = [...props.ccUser];
                        updatedItems.splice(index, 1);
                        props.setCcUser(updatedItems);
                      }}
                    >
                      x
                    </s.ApprLineOrderCencel>
                  </s.ApprLineOrderWrapper>
                </div>
              ))}
            </div>
          </s.Wrapper>
        </s.RightUserWrapper>
      </s.FlexRow>
      <s.BtnWrapper>
        <s.RegBtn
          onClick={() => {
            props.setUserLine(props.user);
            props.setCcUserLine(props.ccUser);
            props.setTree(false);
          }}
        >
          등록
        </s.RegBtn>
        <s.CancelBtn onClick={() => props.setTree(false)}>취소</s.CancelBtn>
      </s.BtnWrapper>
    </s.Container>
  );
};

export default TreePresenter;
