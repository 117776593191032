import theme from 'components/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 10rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

export const NotReadyWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
`;

export const NotReadyImg = styled.img`
  width: 100%;
  height: 50rem;
  object-fit: contain;
`;

export const NotReadyText = styled.div`
  font-size: 2rem;
  color: ${theme.color.secondary};
  font-weight: bold;
  line-height: 3rem;
`;
