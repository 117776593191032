import React, { Dispatch, SetStateAction } from 'react';
import * as s from './VcLogStyled';
import { Link } from 'react-router-dom';

// import VcSetting from './vcSetting';
// import WorkTime from './workTime';
// import Approval from './approval';

import NotReady from '../../../notReady';
import moment from 'moment';

interface VcLogProps {
  searchData: any;
  setSearchData: Dispatch<SetStateAction<any>>;
  fetchAdminData: any;
  adminVacationExcelHeader: any;
  adminVacationExcelData: any;
  fileName: any;
  adminVacationTableTitle: any[];
  adminVcObject: any;
  adminVcHistObject: any;
  setChangeStartDt: Dispatch<SetStateAction<any>>;
  setChangeEndDt: Dispatch<SetStateAction<any>>;
}

const VcLogPresenter = (props: VcLogProps) => {
  return (
    <s.Container>
      <s.AdminContentArea>
        <s.SearchArea>
          <s.ModalInput //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
            type="date"
            max={'9999-12-31'}
            onChange={(e) => {
              props.setChangeStartDt(e.target.value);
            }}
          />
          ~
          <s.ModalInput
            type="date"
            max={'9999-12-31'}
            onChange={(e) => {
              props.setChangeEndDt(e.target.value);
            }}
          />
          <s.InputLabel>
            <s.Input
              type="text"
              value={props.searchData}
              onChange={(e) => props.setSearchData(e.target.value)}
              placeholder="부서명, 사용자명, 직책 中 택1 입력하세요."
            />
          </s.InputLabel>
          <s.SearchBtn onClick={() => props.fetchAdminData()}>검색</s.SearchBtn>
        </s.SearchArea>
        <s.ExcelBtn
          headers={props.adminVacationExcelHeader}
          data={props.adminVacationExcelData}
          filename={props.fileName}
        >
          엑셀 다운로드
        </s.ExcelBtn>
        <s.VcTable>
          <s.VcTbody>
            <s.VcTr>
              {props.adminVacationTableTitle?.map((item: any) => (
                <s.VcTh>{item}</s.VcTh>
              ))}
            </s.VcTr>
            {props.adminVcHistObject?.length > 0 ? (
              props.adminVcHistObject?.map((row: any) => (
                <s.VacationTr>
                  <s.VacationTd>{row.userNm}</s.VacationTd>
                  <s.VacationTd>
                    {moment(row.regDtm).format('YYYY-MM-DD')}
                  </s.VacationTd>
                  <s.VacationTd>{row.vacationTypeNm}</s.VacationTd>
                  <s.VacationTd>{row.useDvNm}</s.VacationTd>
                  <s.VacationTd>
                    {row?.useStrDt === row?.useEndDt ? (
                      <>{moment(row?.useStrDt).format('YYYY-MM-DD')}</>
                    ) : (
                      <>
                        {moment(row?.useStrDt).format('YYYY-MM-DD')}~
                        {moment(row?.useEndDt).format('YYYY-MM-DD')}
                      </>
                    )}
                  </s.VacationTd>
                  <s.VacationTd>{row.useAmount}시간</s.VacationTd>
                  <s.VacationTd>{row.apprStNm}</s.VacationTd>
                </s.VacationTr>
              ))
            ) : (
              <s.VcTr>
                <s.VcTd colSpan={6}>{'데이터가 없습니다.'}</s.VcTd>
              </s.VcTr>
            )}
          </s.VcTbody>
        </s.VcTable>
      </s.AdminContentArea>
    </s.Container>
  );
};

export default VcLogPresenter;
