import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';

import SettingPresenter from './SettingPresenter';

const SettingContainer = () => {
  const setOpenLeftNavi = useSetRecoilState(recoilitem.openLeftNavi);

  const onFocused = () => {
    setOpenLeftNavi(false);
  };

  const [activeTab, setActiveTab] = useState<number>(1);
  const tabList = ['근태설정', '휴가설정'];

  return (
    <>
      <SettingPresenter
        onFocused={onFocused}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabList={tabList}
      />
    </>
  );
};

SettingContainer.defaultProps = {};

export default SettingContainer;
