import apiService from './api-base';

const PACKAGE = 'am/log/mgnt';
const AMPACKAGE = 'am/time/base/mgnt';
const AMCHANGE = 'am/time/change/mgnt';

const apiAmlog = {
  enter: () => apiService.post(PACKAGE + '/enter'),

  leave: () => apiService.post(PACKAGE + '/leave'),

  getList: (dto: { keyword: any; amDvCd: any; startDt: any; endDt: any }) =>
    apiService.post(PACKAGE + '/getList', dto),

  todayMyAmLog: () => apiService.post(PACKAGE + '/todayMyAmLog', {}),
  todayMyAmLogSendMail: () => apiService.post(PACKAGE + '/sendLog', {}),

  getMyMonthlyWorkData: () =>
    apiService.post(PACKAGE + '/getMyMonthlyWorkData', {}),

  baseTimeUpdate: (dto: { startTm: any; endTm: any }) =>
    apiService.post(AMPACKAGE + '/request', dto),

  reqTimeUpdate: (dto: {
    userId: any;
    logId: any;
    chgStartTm: any;
    chgEndTm: any;
    reason: any;
    apprTypeId: any;
  }) => apiService.post(PACKAGE + '/request', dto),

  changeTimeUpdate: (dto: { startTm: any; endTm: any }) =>
    apiService.post(AMCHANGE + '/request', dto),

  getApcList: () => apiService.post(AMPACKAGE + '/getApcList'),
  getChangeApcList: () => apiService.post(AMCHANGE + '/getApcList'),
  getReqApcList: () => apiService.post(PACKAGE + '/getApcList'),

  approval: (dto: {
    apcId: any;
    apprStCd: any;
    description: any;
    cancelYn: any;
  }) => apiService.post(AMPACKAGE + '/approval', dto),

  approvalReq: (dto: {
    apcId: any;
    apprStCd: any;
    description: any;
    cancelYn: any;
  }) => apiService.post(PACKAGE + '/approval', dto),

  changeTimeApproval: (dto: { apcId: any; apprStCd: any; reason: any }) =>
    apiService.post(AMCHANGE + '/approval', dto),
};

export default apiAmlog;
