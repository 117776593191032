import swal from 'sweetalert';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { useRecoilValue } from 'recoil';
import recoilItem from 'util/recoilitem';
import {
  ButtonList,
  ButtonOptions,
} from 'sweetalert/typings/modules/options/buttons';

export const makeMsg = async (msg: string, type: string) => {
  switch (type) {
    case 'success':
      return swal({
        text: msg,
        buttons: ['확인', false],
        icon: 'success',
        timer: Infinity,
      });
    case 'error':
      return swal({
        text: msg,
        buttons: ['확인', false],
        icon: 'error',
        timer: Infinity,
      });
    case 'warning':
      return swal({
        text: msg,
        buttons: ['확인', false],
        icon: 'warning',
        timer: Infinity,
      });
    default:
      return swal({
        text: msg,
        buttons: ['확인', false],
      });
  }
};

export const makeQuestion = async (
  msg: string,
  buttons: any,
): Promise<boolean> => {
  let res = false;
  await swal({
    text: msg,
    buttons: buttons,
  }).then((value) => {
    switch (value) {
      case 'Y':
        res = true;
        break;
      default:
        res = false;
        break;
    }
  });
  return res;
};

export const chkResultCd = (rsltCd: any, msg?: string) => {
  switch (rsltCd) {
    case '00':
      return true;
    case '02':
      makeMsg('잘못된 권한입니다. (' + msg + ')', 'error');
      break;
    case '03':
      makeMsg('잘못된 입력값입니다. (' + msg + ')', 'error');
      break;
    case '06':
      makeMsg('잘못된 데이터입니다. (' + msg + ')', 'error');
      break;
    case '10':
      makeMsg('사용자를 찾을 수 없습니다. 회원가입을 해주세요.', 'error');
      break;
    case '12':
      makeMsg('사용자를 찾을 수 없습니다. SNS회원가입을 해주세요.', 'error');
      break;
    case '07':
      makeMsg('관리자가 승인한 후 이용가능합니다.', 'error');
      break;
    case '17':
      makeMsg('이메일 인증 후 이용가능합니다.', 'error');
      break;
    case '11':
      makeMsg('비밀번호가 일치하지 않습니다.', 'error');
      break;
    case '05':
      makeMsg('데이터가 존재하지않습니다. (' + msg + ')', 'error');
      break;
    case '13':
      makeMsg('sns 토큰이 유효하지 않습니다.', 'error');
      break;
    default:
      makeMsg('실패했습니다. (' + msg + ')', 'error');
      break;
  }
  return false;
};

export const tokenCheck = (token: any) => {
  if (
    !token ||
    !token.accessToken ||
    token.accessToken === '' ||
    token.accessToken === null
  ) {
    localStorage.clear();
    return false;
  }
  const decode: any = jwt_decode(token.accessToken);
  const exp: any = decode.exp;
  const now: any = Date.now() / 1000;
  if (exp < now) {
    return false;
  } else {
    return true;
  }
};

export const priceToString = (price: number) => {
  price = Math.floor(price);
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const readFileAsDataURL = async (file: File) => {
  const resultBase64 = await new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });

  return resultBase64;
};

export default {
  makeMsg,
  chkResultCd,
  tokenCheck,
  priceToString,
  readFileAsDataURL,
};
