import React, { Dispatch, SetStateAction } from 'react';
import * as s from './approvalStyled';
import theme from 'components/styles/theme';
import moment from 'moment';
interface ApprovalProps {
  vcApcObject: any;
  amApcObject: any;
  changeAmApcObject: any;
  vacationTableTitle: any;
  amTableTitle: any;
  approval: (apprData: any, apprStCd: string, apprNm: string) => void;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabList: string[];
  changeTableTitle: any;
  setVcReason: Dispatch<SetStateAction<any>>;
  vcReason: any;
  reasonClick: (row: any) => void;
  reqTableTitle: any;
  reqAmApcObject: any;
  approvalReq: (apprData: any, apprStCd: string) => void;
  workTimeApproval: (apprData: any, apprStCd: string) => void;
  WorkTimeTableTitle: any;
  wtHistObject: any;
}

const ApprovalPresenter = (props: ApprovalProps) => {
  return (
    <s.Container>
      <s.TabItemWrapper>
        {props.tabList.map((tabItem: string, index: number) => (
          <s.TabItem
            active={props.activeTab === index + 1}
            onClick={() => props.setActiveTab(index + 1)}
          >
            {tabItem}
          </s.TabItem>
        ))}
      </s.TabItemWrapper>
      {props.activeTab === 1 ? (
        <>
          <s.TableTitle>기본시간변경 현황</s.TableTitle>
          <s.Table>
            <s.Tbody>
              <s.Tr>
                {props.amTableTitle?.map((item: any) => (
                  <s.Th>{item}</s.Th>
                ))}
              </s.Tr>
              {props.amApcObject?.length > 0 ? (
                props.amApcObject?.map((row: any) => (
                  <s.Tr>
                    <s.Td>{moment(row.regDtm).format('YYYY-MM-DD HH:mm')}</s.Td>
                    <s.Td>{row.deptNm}</s.Td>
                    <s.Td>{row.rankNm}</s.Td>
                    <s.Td>{row.userNm}</s.Td>
                    <s.Td>
                      {row.startTm}~{row.endTm}
                    </s.Td>
                    <s.Td>
                      {row.chgStartTm}~{row.chgEndTm}
                    </s.Td>
                    <s.Td>{row.nowturn}</s.Td>
                    {row.myturn ? (
                      <s.Td>
                        <s.BtnArea>
                          <s.ApprBtn
                            primary
                            onClick={() =>
                              props.approval(row, 'APPRST02', '기본시간')
                            }
                          >
                            승인
                          </s.ApprBtn>
                          <s.ApprBtn
                            onClick={() =>
                              props.approval(row, 'APPRST03', '기본시간')
                            }
                          >
                            반려
                          </s.ApprBtn>
                        </s.BtnArea>
                      </s.Td>
                    ) : (
                      <s.Td></s.Td>
                    )}
                    <s.Td>
                      {row.state
                        ? row.state === '반려'
                          ? `${row.state} (${row?.description})`
                          : row.state
                        : '결재중'}
                    </s.Td>
                  </s.Tr>
                ))
              ) : (
                <s.Tr>
                  <s.Td colSpan={7}>{'데이터가 없습니다.'}</s.Td>
                </s.Tr>
              )}
            </s.Tbody>
          </s.Table>
        </>
      ) : props.activeTab === 2 ? (
        <>
          <s.TableTitle>유연근무시간변경 현황</s.TableTitle>
          <s.Table>
            <s.Tbody>
              <s.Tr>
                {props.changeTableTitle?.map((item: any) => (
                  <s.Th>{item}</s.Th>
                ))}
              </s.Tr>
              {props.changeAmApcObject?.length > 0 ? (
                props.changeAmApcObject?.map((row: any) => (
                  <s.Tr>
                    <s.Td>{row.userNm}</s.Td>
                    <s.Td>{row.deptNm}</s.Td>
                    <s.Td>
                      {row.startDt === row.endDt ? (
                        <>{row.startDt}</>
                      ) : (
                        <>
                          {row.startDt}~{row.endDt}
                        </>
                      )}
                    </s.Td>
                    <s.Td>{moment(row.startTm, 'HHmm').format('HH:mm')}</s.Td>
                    <s.Td>{moment(row.endTm, 'HHmm').format('HH:mm')}</s.Td>
                    <s.Td>{row.nowturn}</s.Td>
                    {row.myturn ? (
                      <s.Td>
                        <s.BtnArea>
                          <s.ApprBtn
                            primary
                            onClick={() =>
                              props.approval(row, 'APPRST02', '유연근로')
                            }
                          >
                            승인
                          </s.ApprBtn>
                          <s.ApprBtn
                            onClick={() =>
                              props.approval(row, 'APPRST03', '유연근로')
                            }
                          >
                            반려
                          </s.ApprBtn>
                        </s.BtnArea>
                      </s.Td>
                    ) : (
                      <s.Td></s.Td>
                    )}
                    <s.Td>
                      {row.state
                        ? row.state === '반려'
                          ? `${row.state} (${row?.rejectionRsn})`
                          : row.state
                        : '결재중'}
                    </s.Td>
                  </s.Tr>
                ))
              ) : (
                <s.Tr>
                  <s.Td colSpan={7}>{'데이터가 없습니다.'}</s.Td>
                </s.Tr>
              )}
            </s.Tbody>
          </s.Table>
        </>
      ) : props.activeTab === 3 ? (
        <>
          <s.TableTitle>휴가신청 현황</s.TableTitle>
          <s.Table>
            <s.Tbody>
              <s.Tr>
                {props.vacationTableTitle?.map((item: any) => (
                  <s.Th>{item}</s.Th>
                ))}
              </s.Tr>
              {props.vcApcObject?.length > 0 ? (
                props.vcApcObject?.map((row: any) => (
                  <s.Tr>
                    <s.Td>{moment(row.regDtm).format('YYYYMMDD')}</s.Td>
                    <s.Td>{row.userNm}</s.Td>
                    <s.Td>{row.vacationTypeNm}</s.Td>
                    <s.Td>{row.useDvNm}</s.Td>
                    <s.Td>
                      {row.useStrDt === row.useEndDt ? (
                        <>{row.useStrDt}</>
                      ) : (
                        <>
                          {row.useStrDt}~{row.useEndDt}
                        </>
                      )}
                    </s.Td>
                    <s.Td>{row.useAmount}시간</s.Td>
                    <s.Td>{row.nowturn}</s.Td>
                    <s.Td
                    // style={{
                    //   border: '1px solid #000',
                    //   color: '#1A2D56',
                    //   fontWeight: 'bold',
                    // }}
                    // onClick={(e) => {
                    //   e.stopPropagation(); // 이벤트 버블링 방지
                    //   props.reasonClick(row); // vcReason 값을 토글
                    // }}
                    >
                      {row.apcRsn}
                      {props.vcReason[row?.apcId] ? (
                        <s.Reason
                          onClick={(e) => {
                            e.stopPropagation(); // 이벤트 버블링 방지
                            props.setVcReason(row);
                          }}
                        >
                          {row.apcRsn}
                        </s.Reason>
                      ) : null}
                    </s.Td>
                    {row.ismyturn === 'true' ? (
                      <s.Td>
                        <s.BtnArea>
                          <s.ApprBtn
                            primary
                            onClick={() =>
                              props.approval(row, 'APPRST02', '휴가')
                            }
                          >
                            승인
                          </s.ApprBtn>
                          <s.ApprBtn
                            onClick={() =>
                              props.approval(row, 'APPRST03', '휴가')
                            }
                          >
                            반려
                          </s.ApprBtn>
                        </s.BtnArea>
                      </s.Td>
                    ) : (
                      <s.Td></s.Td>
                    )}
                  </s.Tr>
                ))
              ) : (
                <s.Tr>
                  <s.Td colSpan={8}>{'데이터가 없습니다.'}</s.Td>
                </s.Tr>
              )}
            </s.Tbody>
          </s.Table>
        </>
      ) : props.activeTab === 4 ? (
        <>
          <s.TableTitle>출/퇴근 변경요청 현황</s.TableTitle>
          <s.Table>
            <s.Tbody>
              <s.Tr>
                {props.reqTableTitle?.map((item: any) => (
                  <s.Th>{item}</s.Th>
                ))}
              </s.Tr>
              {props.reqAmApcObject?.length > 0 ? (
                props.reqAmApcObject?.map((row: any) => (
                  <s.Tr>
                    <s.Td>{moment(row.regDtm).format('YYYYMMDD')}</s.Td>
                    <s.Td>{row.userNm}</s.Td>
                    <s.Td>{row.logDt}</s.Td>
                    <s.Td>
                      {moment(row.changeStartTm, 'HHmm').format('HH:mm')}
                    </s.Td>
                    <s.Td>
                      {moment(row.changeEndTm, 'HHmm').format('HH:mm')}
                    </s.Td>
                    <s.Td>{row.amDvCdNm}</s.Td>
                    <s.Td>{row.reason}</s.Td>
                    <s.Td>{row.nowturn}</s.Td>
                    {row.myturn === true ? (
                      <s.Td>
                        <s.BtnArea>
                          <s.ApprBtn
                            primary
                            onClick={() => props.approvalReq(row, 'APPRST02')}
                          >
                            승인
                          </s.ApprBtn>
                          <s.ApprBtn
                            onClick={() => props.approvalReq(row, 'APPRST03')}
                          >
                            반려
                          </s.ApprBtn>
                        </s.BtnArea>
                      </s.Td>
                    ) : (
                      <s.Td></s.Td>
                    )}
                  </s.Tr>
                ))
              ) : (
                <s.Tr>
                  <s.Td colSpan={8}>{'데이터가 없습니다.'}</s.Td>
                </s.Tr>
              )}
            </s.Tbody>
          </s.Table>
        </>
      ) : (
        <>
          <s.TableTitle>시간 외 근무</s.TableTitle>
          <s.Table>
            <s.Tbody>
              <s.Tr>
                {props.WorkTimeTableTitle?.map((item: any) => (
                  <s.Th>{item}</s.Th>
                ))}
              </s.Tr>
              {props.wtHistObject?.length > 0 ? (
                props.wtHistObject?.map((row: any) => (
                  <s.Tr>
                    <s.Td>
                      {moment(row?.startDtm).format('YYYY-MM-DD HH:mm')}
                    </s.Td>
                    <s.Td>
                      {moment(row?.endDtm).format('YYYY-MM-DD HH:mm')}
                    </s.Td>
                    <s.Td>{row?.userNm}</s.Td>
                    <s.Td>{row?.deptNm}</s.Td>
                    <s.Td>{row?.posnNm}</s.Td>
                    <s.Td>{row?.rankNm}</s.Td>
                    <s.Td>{row?.nowturn ? <>{row.nowturn}</> : ''}</s.Td>
                    <s.Td>
                      {row?.myturn ? (
                        <s.BtnArea>
                          <s.ApprBtn
                            primary
                            onClick={() =>
                              props.workTimeApproval(row, 'APPRST02')
                            }
                          >
                            승인
                          </s.ApprBtn>
                          <s.ApprBtn
                            onClick={() =>
                              props.workTimeApproval(row, 'APPRST03')
                            }
                          >
                            반려
                          </s.ApprBtn>
                        </s.BtnArea>
                      ) : row?.nowturn ? (
                        '결재중'
                      ) : row?.state ? (
                        <>
                          {row.state}
                          {row.state === '반려' && `(${row?.description})`}
                        </>
                      ) : (
                        ''
                      )}
                    </s.Td>
                  </s.Tr>
                ))
              ) : (
                <s.Tr>
                  <s.Td colSpan={8}>{'데이터가 없습니다.'}</s.Td>
                </s.Tr>
              )}
            </s.Tbody>
          </s.Table>
        </>
      )}
    </s.Container>
  );
};

export default ApprovalPresenter;
