import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as s from './DetailStyled';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html'; // draft-js 컨텐츠를 HTML로 변환하는 라이브러리
import { MdClose } from 'react-icons/md';
import DoubltTree from '../../../../components/tree';

interface DetailProps {
  priceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isItemModal: any;
  setIsItemModal: Dispatch<SetStateAction<any>>;
  itemList: any[];
  item: any[];
  itemTitle: any;
  setItemTitle: Dispatch<SetStateAction<any>>;
  setItemInputData: Dispatch<SetStateAction<any>>;
  itemReg: (item: any) => void;
  price: any;
  setPrice: Dispatch<SetStateAction<any>>;
  setDetailItem: Dispatch<SetStateAction<any>>;
  detailItem: any[];
  setDetailItemCnt: Dispatch<SetStateAction<any>>;
  detailItemCnt: any;
  itemSt: any;
  setItemSt: Dispatch<SetStateAction<any>>;
  setItem: Dispatch<SetStateAction<any>>;
  save: (content: any, user: any, ccUser: any) => void;
  setTitle: Dispatch<SetStateAction<any>>;
  movePage: (page: any) => void;
  title: any;
  user: any;
  setUser: Dispatch<SetStateAction<any>>;
  ccUser: any;
  setCcUser: Dispatch<SetStateAction<any>>;
  contents: any;
  itemDelete: () => void;
}

const DetailPresenter = (props: DetailProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [tree, setTree] = useState(false);

  useEffect(() => {
    const contentHtml = props.contents || '';
    // HTML 문자열을 ContentState로 변환
    const blocksFromHTML = convertFromHTML(contentHtml);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    setEditorState(EditorState.createWithContent(contentState));
  }, [props.contents]);

  const onEditorStateChange = (editorStated) => {
    // editorState에 값 설정
    setEditorState(editorStated);
  };

  const saveClick = () => {
    // editorState에서 현재 컨텐츠 추출
    const contentState = editorState.getCurrentContent();
    // 컨텐츠를 HTML로 변환
    const contentAsHtml = draftToHtml(convertToRaw(contentState));
    // contentAsHtml을 표시
    props.save(contentAsHtml, props.user, props.ccUser);
  };

  return (
    <s.Container>
      {tree && (
        <DoubltTree
          setTree={setTree}
          user={props.user}
          ccUser={props.ccUser}
          setUserLine={props.setUser}
          setCcUserLine={props.setCcUser}
        />
      )}
      <s.Title>기안양식 수정</s.Title>
      <s.Wrapper>
        <s.ColumnWrapper>
          <s.RowWrapper>
            <s.ContentTitle>양식제목</s.ContentTitle>
            <s.InputData
              style={{ marginLeft: '2rem' }}
              placeholder="기안제목 입력"
              onChange={(e) => props.setTitle(e.target.value)}
              value={props.title}
            ></s.InputData>
          </s.RowWrapper>
          <s.RowWrapper>
            <s.ContentTitle>결재선 설정</s.ContentTitle>
            <s.AddBtn onClick={() => setTree(true)}>+</s.AddBtn>
          </s.RowWrapper>
          {props.user.length > 0 && ( // 결재선 선택시 생선되는 Contents
            <s.ApprLine>
              {props.user?.map((item, index) => (
                <s.UserInfo>
                  <s.ApprLineCancel
                    onClick={() => {
                      const updatedItems = [...props.user];
                      updatedItems.splice(index, 1);
                      props.setUser(updatedItems);
                    }}
                  >
                    x
                  </s.ApprLineCancel>
                  <div>{item?.userNm}</div>
                  <div>({item?.userEmpno})</div>
                  <div style={{ color: '#ccc' }}>{item?.deptNm}</div>
                </s.UserInfo>
              ))}
            </s.ApprLine>
          )}
          <s.CcLine>참조</s.CcLine>
          <div style={{ display: 'flex', gap: '0.3rem', flexWrap: 'wrap' }}>
            {props.ccUser.length > 0 && ( // 참조선 선택시 생선되는 Contents
              <s.ApprLine>
                {props.ccUser?.map((item, index) => (
                  <s.CcUserInfo>
                    <div>{item?.userNm}</div>
                    <div>({item?.userEmpno}) | </div>
                    <div>{item?.deptNm}</div>
                    <s.CcLineCancel
                      onClick={() => {
                        const updatedItems = [...props.ccUser];
                        updatedItems.splice(index, 1);
                        props.setCcUser(updatedItems);
                      }}
                    >
                      x
                    </s.CcLineCancel>
                  </s.CcUserInfo>
                ))}
              </s.ApprLine>
            )}
          </div>
          <s.RowWrapper>
            <s.ContentTitle>항목추가</s.ContentTitle>
            <s.AddBtn
              onClick={() => {
                props.setIsItemModal(true);
                props.setItemSt('item');
              }}
            >
              +
            </s.AddBtn>
          </s.RowWrapper>
          {props.item.length > 0 && ( // 항목 추가시 생성퇴는 Contents
            <s.NewItemWrapper>
              {props.item?.map((item, index) => (
                <s.RowWrapper>
                  <s.ContentTitle style={{ color: 'black' }}>
                    {item.questionTitle}
                  </s.ContentTitle>
                  {item.questionTypeCd === 'TEXT' ? (
                    <>
                      <s.InputData placeholder={`${item.questionTitle} 입력`} />
                    </>
                  ) : item.questionTypeCd === '일자' ? (
                    <>
                      <s.InputData
                        type="date"
                        style={{
                          colorScheme: 'white',
                        }}
                        max={'9999-12-31'}
                      />
                    </>
                  ) : item.questionTypeCd === '금액' ? (
                    <>
                      <s.InputData
                        value={props.price}
                        onChange={(e) => props.priceChange(e)}
                        placeholder="금액을 입력(숫자)"
                      />
                      원
                    </>
                  ) : item.questionTypeCd === 'File' ? (
                    <>
                      <s.InputData type="file" />
                    </>
                  ) : item.questionTypeCd === '참조문서' ? (
                    <>
                      <s.DocBtn>+</s.DocBtn>
                    </>
                  ) : null}
                  <s.DeleteItem
                    onClick={() => {
                      const updatedItems = [...props.item];
                      updatedItems.splice(index, 1); // index 위치의 항목을 제거
                      // 업데이트된 배열로 상태를 업데이트
                      props.setItem(updatedItems);
                    }}
                  />
                </s.RowWrapper>
              ))}
            </s.NewItemWrapper>
          )}
          <s.RowWrapper>
            {props.detailItemCnt > 0 ? (
              <>
                <s.ContentTitle>상세항목</s.ContentTitle>
              </>
            ) : (
              <>
                <s.ContentTitle>상세항목추가</s.ContentTitle>
                <s.AddBtn onClick={() => props.setDetailItemCnt(1)}>+</s.AddBtn>
              </>
            )}
          </s.RowWrapper>
          {props.detailItemCnt > 0 && ( // 상세항목 추가하는 Contents
            <s.NewDetileItemWrapper>
              <s.MdExit
                onClick={() => {
                  props.setDetailItemCnt(0);
                  props.setDetailItem([]);
                }}
              />
              {props.detailItem?.map((item, index) => (
                <s.ColumnDetailWrapper key={item.apprDetailQuestionId}>
                  <s.DetailItemDelete
                    onClick={() => {
                      const updatedItems = [...props.detailItem];
                      updatedItems.splice(index, 1); // index 위치의 항목을 제거
                      // 업데이트된 배열로 상태를 업데이트
                      props.setDetailItem(updatedItems);
                    }}
                  >
                    X
                  </s.DetailItemDelete>
                  <s.ContentTitle style={{ textAlign: 'center' }}>
                    {item.questionTitle}
                  </s.ContentTitle>
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      background: '#000',
                    }}
                  />
                  {item.questionTypeCd === 'TEXT' ? (
                    <>
                      <s.InputData placeholder={`${item.questionTitle} 입력`} />
                    </>
                  ) : item.questionTypeCd === '일자' ? (
                    <>
                      <s.InputData
                        type="date"
                        style={{
                          colorScheme: 'white',
                        }}
                        max={'9999-12-31'}
                      />
                    </>
                  ) : item.questionTypeCd === '금액' ? (
                    <>
                      <s.InputData
                        value={props.price}
                        onChange={(e) => props.priceChange(e)}
                        placeholder="금액을 입력(숫자)"
                      />
                    </>
                  ) : item.questionTypeCd === 'File' ? (
                    <>
                      <s.InputData type="file" />
                    </>
                  ) : null}
                </s.ColumnDetailWrapper>
              ))}
              <s.DetailAdd
                onClick={() => {
                  props.setIsItemModal(true);
                  props.setItemSt('detailItem');
                }}
              >
                +
              </s.DetailAdd>
            </s.NewDetileItemWrapper>
          )}
          <s.RowWrapper
            style={{ flexDirection: 'column', alignItems: 'start' }}
          >
            <s.ContentTitle>내용</s.ContentTitle>
            <s.MyBlock>
              <Editor
                // 에디터와 툴바 모두에 적용되는 클래스
                wrapperClassName="wrapper-class"
                // 에디터 주변에 적용된 클래스
                editorClassName="editor"
                // 툴바 주위에 적용된 클래스
                toolbarClassName="toolbar-class"
                // 툴바 설정
                toolbar={{
                  // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
                  list: { inDropdown: false },
                  textAlign: { inDropdown: false },
                  link: { inDropdown: false },
                  history: { inDropdown: false },
                }}
                placeholder="내용을 작성해주세요."
                // 한국어 설정
                localization={{
                  locale: 'ko',
                }}
                // 초기값 설정
                editorState={editorState}
                // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
                onEditorStateChange={onEditorStateChange}
              />
            </s.MyBlock>
          </s.RowWrapper>
          <s.BtnWrapper>
            <s.SaveBtn onClick={saveClick}>수정</s.SaveBtn>
            <s.CancelBtn onClick={() => props.movePage('/manager')}>
              취소
            </s.CancelBtn>
            <s.DeleteBtn onClick={() => props.itemDelete()}>삭제</s.DeleteBtn>
          </s.BtnWrapper>
        </s.ColumnWrapper>
      </s.Wrapper>

      {props.isItemModal && (
        <s.PopupBackground onClick={() => props.setIsItemModal(false)}>
          <s.PopupModal onClick={(e) => e.stopPropagation()}>
            <s.ModalCloseButton onClick={() => props.setIsItemModal(false)}>
              <MdClose size={'100%'} color={'#fff'} />
            </s.ModalCloseButton>
            {props.itemSt === 'item' && <s.ModalTitle>항목추가</s.ModalTitle>}
            {props.itemSt === 'detailItem' && (
              <s.ModalTitle>상세항목추가</s.ModalTitle>
            )}

            <s.ModalButtonWrapper>
              <s.ModalInputLabel>Title</s.ModalInputLabel>
              <s.ModalInput
                placeholder="항목의 제목을 입력해주세요."
                onChange={(e) => props.setItemTitle(e.target.value)}
              />
            </s.ModalButtonWrapper>
            <s.ModalButtonWrapper>
              <s.ModalInputLabel>Input Data</s.ModalInputLabel>
              <s.ModalSelect
                onChange={(e) => {
                  props.setItemInputData(e.target.value);
                }}
              >
                <s.SelectOption value="">선택</s.SelectOption>
                {props.itemList?.map((item) => (
                  <s.SelectOption key={item} value={item}>
                    {item}
                  </s.SelectOption>
                ))}
              </s.ModalSelect>
            </s.ModalButtonWrapper>
            <s.ModalButtonWrapper>
              <s.ModalButton onClick={() => props.itemReg(props.itemSt)}>
                등록
              </s.ModalButton>
              <s.ModalButton
                onClick={() => props.setIsItemModal(false)}
                style={{ color: '#fff', background: '#9a9a9a' }}
              >
                취소
              </s.ModalButton>
            </s.ModalButtonWrapper>
          </s.PopupModal>
        </s.PopupBackground>
      )}
    </s.Container>
  );
};

export default DetailPresenter;
