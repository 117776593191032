import React, { Dispatch, SetStateAction } from 'react';
import * as s from './CurrentStyled';
import theme from 'components/styles/theme';
import moment from 'moment';

interface CurrentProps {
  ApprTableTitle: any;
  setMainActiveTab: Dispatch<SetStateAction<any>>;
  mainActiveTab: any;
  tabList: any;
  apprObject: any;
  toApprObject: any;
  apprViewBtn: (number: any) => void;
  movePage: (page: any) => void;
  getApprList: () => void;
  isApprListModal: any;
  setIsApprListModal: Dispatch<SetStateAction<any>>;
  apprTypeList: any;
}
const CurrentPresenter = (props: CurrentProps) => {
  return (
    <s.Container>
      <s.Title>
        결재 현황
        <s.ApprRegBtn primary onClick={() => props.getApprList()}>
          기안등록
        </s.ApprRegBtn>
      </s.Title>
      <s.MainWrapper>
        <s.ContentsWrapper
          style={{ cursor: 'pointer' }}
          onClick={() => props.apprViewBtn(2)}
        >
          상신함
          <s.Contents>{props.apprObject?.length}</s.Contents>
        </s.ContentsWrapper>
        <s.ContentsWrapper
          style={{ cursor: 'pointer' }}
          onClick={() => props.apprViewBtn(3)}
        >
          결재함
          <s.Contents>{props.toApprObject?.length}</s.Contents>
        </s.ContentsWrapper>
      </s.MainWrapper>
      <s.TabItemWrapper>
        {props.tabList.map((tabItem: string, index: number) => (
          <s.TabItem
            active={props.mainActiveTab === index + 1}
            onClick={() => props.setMainActiveTab(index + 1)}
          >
            {tabItem}
          </s.TabItem>
        ))}
      </s.TabItemWrapper>
      {props.mainActiveTab === 1 && (
        <s.Table>
          <s.Tbody>
            <s.Tr style={{ background: '#1A2D56' }}>
              {props.ApprTableTitle?.map((item: any) => (
                <s.Th>{item}</s.Th>
              ))}
            </s.Tr>
            {props.apprObject?.length > 0 ? (
              props.apprObject?.slice(0, 5).map((row: any) => (
                <s.Tr
                  onClick={() =>
                    props.movePage(`/approval/detail?apcId=${row.apcId}`)
                  }
                >
                  <s.Td>{row.apprTypeTitle}</s.Td>
                  <s.Td>{row.apcTitle}</s.Td>
                  <s.Td>{row.apprUserNm}</s.Td>
                  <s.Td>{row.apprStCdNm}</s.Td>
                  <s.Td>{moment(row.regDtm).format('YYYY-MM-DD HH:mm')}</s.Td>
                </s.Tr>
              ))
            ) : (
              <s.Tr>
                <s.Td colSpan={5}>{'데이터가 없습니다.'}</s.Td>
              </s.Tr>
            )}
          </s.Tbody>
        </s.Table>
      )}
      {props.mainActiveTab === 2 && (
        <s.Table>
          <s.Tbody>
            <s.Tr style={{ background: '#1A2D56' }}>
              {props.ApprTableTitle?.map((item: any) => (
                <s.Th>{item}</s.Th>
              ))}
            </s.Tr>
            {props.toApprObject?.length > 0 ? (
              props.toApprObject?.slice(0, 5).map((row: any) => (
                <s.Tr
                  onClick={() =>
                    props.movePage(`/approval/detail?apcId=${row.apcId}`)
                  }
                >
                  <s.Td>{row.apprTypeTitle}</s.Td>
                  <s.Td>{row.apcTitle}</s.Td>
                  <s.Td>{row.apprUserNm}</s.Td>
                  <s.Td>{row.apprStCdNm}</s.Td>
                  <s.Td>{moment(row.regDtm).format('YYYY-MM-DD HH:mm')}</s.Td>
                </s.Tr>
              ))
            ) : (
              <s.Tr>
                <s.Td colSpan={5}>{'데이터가 없습니다.'}</s.Td>
              </s.Tr>
            )}
          </s.Tbody>
        </s.Table>
      )}
      {props.isApprListModal && (
        <>
          <s.ArrowDropup onClick={() => props.setIsApprListModal(false)} />

          <s.ApprTypeContainer isOpen={props.isApprListModal}>
            <s.ApprTypeTitle>기안양식함</s.ApprTypeTitle>
            <s.FlexRow>
              {props.apprTypeList.map((item, index) => (
                <s.ApprTypeWrapper
                  onClick={() =>
                    props.movePage(
                      `/approval/current/regist?apprTypeId=${item.apprTypeId}`,
                    )
                  }
                >
                  <s.ApprType>{item.title}</s.ApprType>
                  <s.ApprTypeExplain>{item.description}</s.ApprTypeExplain>
                </s.ApprTypeWrapper>
              ))}
            </s.FlexRow>
          </s.ApprTypeContainer>
        </>
      )}
    </s.Container>
  );
};

export default CurrentPresenter;
