import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

import CurrentPresenter from './CurrentPresenter';
import apiAppr from 'api/api-appr';
import moment from 'moment';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import swal from '@sweetalert/with-react';
import { useNavigate } from 'react-router-dom';

interface CurrentProps {
  setActiveTab: Dispatch<SetStateAction<any>>;
}

const CurrentContainer = (props: CurrentProps) => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(recoilitem.userInfo);
  const ApprTableTitle = [
    '기안양식',
    '기안제목',
    '결재자',
    '결재상태',
    '상신일시',
  ];
  const [mainActiveTab, setMainActiveTab] = useState<number>(1);
  const tabList = ['상신함', '결재함'];
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [apprObject, setApprObject] = useState();
  const [toApprObject, setToApprObject] = useState();
  const [isApprListModal, setIsApprListModal] = useState<boolean>(false);
  const [apprTypeList, setApprTypeList] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const dto = {
        apprStCd: 'all',
        userId: userInfo.userId,
        startDt: moment().startOf('year').format('YYYYMMDD'),
        endDt: moment().endOf('year').format('YYYYMMDD'),
      };
      const res = await apiAppr.approval(dto); // 상신함
      const resTo = await apiAppr.toApproval(dto); // 결재함
      if (res.rsltCd === '00') {
        //상신함
        setApprObject(res.data.apcList);
      }

      if (resTo.rsltCd === '00') {
        //결재함
        setToApprObject(resTo.data.apcList);
      }

      const resApprType = await apiAppr.getList(); // 결재 유형 목록
      if (resApprType.rsltCd === '00') {
        setApprTypeList(resApprType.data?.apprTypeList);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const apprViewBtn = (number) => {
    props.setActiveTab(number);
  };

  const movePage = (page) => {
    navigate(page);
  };

  const getApprList = () => {
    setIsApprListModal(true);
  };

  return (
    <>
      <CurrentPresenter
        ApprTableTitle={ApprTableTitle}
        setMainActiveTab={setMainActiveTab}
        mainActiveTab={mainActiveTab}
        tabList={tabList}
        apprObject={apprObject}
        toApprObject={toApprObject}
        apprViewBtn={apprViewBtn}
        movePage={movePage}
        getApprList={getApprList}
        setIsApprListModal={setIsApprListModal}
        isApprListModal={isApprListModal}
        apprTypeList={apprTypeList}
      />
    </>
  );
};

CurrentContainer.defaultProps = {};

export default CurrentContainer;
