import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';

import VcLogPresenter from './VcLogPresenter';
import moment from 'moment';
import apiAmlog from 'api/api-amlog';
import { makeMsg } from 'util/util';
import apiVclog from 'api/api-vclog';

const VcLogContainer = () => {
  const [searchData, setSearchData] = useState('');
  const [fileName, setFileName] = useState<any | undefined>();
  const [changeStartDt, setChangeStartDt] = useState(
    moment().startOf('year').format('YYYYMMDD'),
  );
  const [changeEndDt, setChangeEndDt] = useState(
    moment().endOf('year').format('YYYYMMDD'),
  );
  const adminVacationExcelHeader = [
    { label: '사번', key: 'userEmpno' },
    { label: '부서', key: 'deptNm' },
    { label: '이름', key: 'userNm' },
    { label: '직책', key: 'posnNm' },
    { label: '휴가이름', key: 'vacationTypeNm' },
    { label: '시작시간', key: 'useStrDt' },
    { label: '종료시간', key: 'useEndDt' },
    { label: '휴가종류', key: 'useDvCd' },
    { label: '휴가시간', key: 'useAmount' },
    { label: '승인여부', key: 'apprStNm' },
  ];

  const adminVacationTableTitle = [
    '신청자',
    '신청일자',
    '사용구분',
    '종류',
    '휴가일자',
    '휴가시간',
    '승인여부',
  ];
  const useDvCdMap = {
    AMVACUSE01: '전일',
    AMVACUSE02: '오전',
    AMVACUSE03: '오후',
  };
  const [adminVcHistObject, setAdminVcHistObject] = useState<any>();
  const [adminVacationExcelData, setAdminVacationExcelData] = useState([]);
  const [adminVcObject, setAdminVcObject] = useState<any>();

  useEffect(() => {
    fetchAdminData();
  }, []);

  const fetchAdminData = async () => {
    const dto: any = {
      startDt: moment(changeStartDt).format('YYYYMMDD'),
      endDt: moment(changeEndDt).format('YYYYMMDD'),
      adminYn: 'Y',
      keyword: searchData,
    };

    const adminRestHist = await apiVclog.getUseHistList(dto);
    if (adminRestHist.rsltCd == '00') {
      setAdminVcHistObject(adminRestHist.data?.useHistList);
      setAdminVacationExcelData(
        adminRestHist.data?.useHistList.map((item) => ({
          userEmpno: item.userEmpno,
          userNm: item.userNm,
          deptNm: item.deptNm,
          posnNm: item.posnNm,
          useStrDt: moment(item.useStrDt).format('YYYY-MM-DD'),
          useEndDt: moment(item.useEndDt).format('YYYY-MM-DD'),
          useDvCd: useDvCdMap[item.useDvCd] || item.useDvCd,
          vacationTypeNm: item.vacationTypeNm,
          useAmount: item.useAmount + '시간',
          apprStNm: item.apprStNm,
        })),
      );
    }
  };

  return (
    <>
      <VcLogPresenter
        searchData={searchData}
        setSearchData={setSearchData}
        fetchAdminData={fetchAdminData}
        adminVacationExcelHeader={adminVacationExcelHeader}
        adminVacationExcelData={adminVacationExcelData}
        fileName={fileName}
        adminVacationTableTitle={adminVacationTableTitle}
        adminVcObject={adminVcObject}
        adminVcHistObject={adminVcHistObject}
        setChangeStartDt={setChangeStartDt}
        setChangeEndDt={setChangeEndDt}
      />
    </>
  );
};

VcLogContainer.defaultProps = {};

export default VcLogContainer;
