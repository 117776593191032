import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';

import MainPresenter from './MainPresenter';

const MainContainer = () => {
  const setOpenLeftNavi = useSetRecoilState(recoilitem.openLeftNavi);
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const onFocused = () => {
    setOpenLeftNavi(false);
  };

  return (
    <>
      <MainPresenter onFocused={onFocused} />
    </>
  );
};

MainContainer.defaultProps = {};

export default MainContainer;
