import React from 'react';
import * as s from './NotReadyStyled';

interface NotReadyProps {
  onFocused: () => void;
}

const NotReadyPresenter = (props: NotReadyProps) => {
  return (
    <s.Container onClick={() => props.onFocused()}>
      <s.NotReadyWrapper>
        <s.NotReadyImg src="/img/notready.jpeg" />
        <s.NotReadyText>
          허용된 IP가 아닙니다.
          <br />
          사내에서 사용해주세요.
        </s.NotReadyText>
      </s.NotReadyWrapper>
    </s.Container>
  );
};

export default NotReadyPresenter;
