import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';

import AmLogPresenter from './AmLogPresenter';
import moment from 'moment';
import apiAmlog from 'api/api-amlog';
import { makeMsg } from 'util/util';

const AmLogContainer = () => {
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [selectedYearMonth, setSelectedYearMonth] = useState(
    moment().startOf('month'),
  );
  const [amApcObject, setAmApcObject] = useState<any>();

  const amTableTitle = [
    '신청일자',
    '부서',
    '직급',
    '이름',
    '현 출/퇴근시간',
    '변경 출/퇴근시간',
    '현재결재자',
    '결재상태',
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const resAm = await apiAmlog.getApcList();
      if (resAm.rsltCd === '00') {
        setAmApcObject(resAm.data?.apcList);
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <>
      <AmLogPresenter amTableTitle={amTableTitle} amApcObject={amApcObject} />
    </>
  );
};

AmLogContainer.defaultProps = {};

export default AmLogContainer;
