import React, { Dispatch, SetStateAction } from 'react';
import * as s from './WorkTimeLogStyled';
import { Link } from 'react-router-dom';

import NotReady from '../../../notReady';
import moment from 'moment';

interface WorkTimeLogProps {
  WorkTimeTableTitle: any;
  wtHistObject: any;
}

const WorkTimeLogPresenter = (props: WorkTimeLogProps) => {
  return (
    <s.Container>
      <s.TableTitle>시간외근무 신청현황</s.TableTitle>
      <s.Table>
        <s.Tbody>
          <s.Tr>
            {props.WorkTimeTableTitle?.map((item: any) => (
              <s.Th>{item}</s.Th>
            ))}
          </s.Tr>
          {props.wtHistObject?.length > 0 ? (
            props.wtHistObject?.map((row: any) => (
              <s.Tr>
                <s.Td>{moment(row?.startDtm).format('YYYY-MM-DD HH:mm')}</s.Td>
                <s.Td>{moment(row?.endDtm).format('YYYY-MM-DD HH:mm')}</s.Td>
                <s.Td>{row?.userNm}</s.Td>
                <s.Td>{row?.deptNm}</s.Td>
                <s.Td>{row?.posnNm}</s.Td>
                <s.Td>{row?.rankNm}</s.Td>
                <s.Td>{row?.nowturn}</s.Td>
                <s.Td>
                  {row?.nowturn !== null
                    ? row.state
                      ? row.state === '반려'
                        ? `${row.state} (${row?.description})`
                        : row.state
                      : '결재중'
                    : '승인'}
                </s.Td>
              </s.Tr>
            ))
          ) : (
            <s.Tr>
              <s.Td colSpan={7}>{'데이터가 없습니다.'}</s.Td>
            </s.Tr>
          )}
        </s.Tbody>
      </s.Table>
    </s.Container>
  );
};

export default WorkTimeLogPresenter;
