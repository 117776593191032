import apiAmlog from 'api/api-amlog';
import apiUser from 'api/api-user';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import LoginPresenter from './LoginPresenter';
const LoginContainer = () => {
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const setMyAmLog = useSetRecoilState(recoilitem.myAmLog);
  const [userInfo, setUserInfo] = useRecoilState(recoilitem.userInfo);
  const [token, setToken] = useRecoilState(recoilitem.token);

  const [userEmpno, setUserEmpno] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [isOpenIdPwPopup, setIsOpenIdPwPopup] = useState<boolean>(false);
  const [findPasswordEmpno, setFindPasswordEmpno] = useState<string>('');
  const [findPasswordEmail, setFindPasswordEmail] = useState<string>('');
  const [isShowRegno, setIsShowRegno] = useState<boolean>(false);
  const [findPasswordRegno, setFindPasswordRegno] = useState<string>('');
  const [isRegnoValid, setIsRegnoValid] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConf, setNewPasswordConf] = useState<string>('');
  const [findPasswordUserId, setFindPasswordUserId] = useState<string>('');

  const [isOpenSignUpPopup, setIsOpenSignUpPopup] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  const [signUpPassword, setSignUpPassword] = useState<string>('');
  const [signUpPasswordConf, setSignUpPasswordConf] = useState<string>('');
  const [userNm, setUserNm] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string>('');
  const [gender, setGender] = useState<string>('M');
  const [externalEmail, setExternalEmail] = useState<string>('');
  const [userMobno, setUserMobno] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setFindPasswordEmail('');
    setFindPasswordEmpno('');
    setFindPasswordRegno('');
    setNewPassword('');
    setNewPasswordConf('');
    setIsShowRegno(false);
    setIsRegnoValid(false);
    setFindPasswordUserId('');
  }, [isOpenIdPwPopup]);

  const login = async () => {
    try {
      const res = await apiUser.login({
        userEmpno: userEmpno,
        password: password,
      });

      if (res.rsltCd === '00') {
        makeMsg('로그인 성공!', 'success');
        setUserInfo({ ...res.data.userInfo });
        setToken({
          accessToken: res.data.accessToken,
          refreshToken: res.data.refreshToken,
        });
        const todayAmLogRes = await apiAmlog.todayMyAmLog();
        if (todayAmLogRes.rsltCd === '00') {
          setMyAmLog({
            enter: todayAmLogRes.data.enter,
            leave: todayAmLogRes.data.leave,
            inVacation: todayAmLogRes.data.inVacation,
            startTm: todayAmLogRes.data.startTm,
            endTm: todayAmLogRes.data.endTm,
          });
        }
        navigate('/am');
      } else {
        makeMsg('아이디와 비밀번호를 확인해주세요', 'warning');
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const checkEnter = (event: any) => {
    if (event.key === 'Enter') {
      login();
    }
  };

  const findUserInfo = async () => {
    try {
      if (!findPasswordEmpno.replaceAll(' ', '')) {
        makeMsg('사번을 입력하세요.', 'warning');
        return;
      }

      setIsLoading(true);
      const data = {
        userEmpno: findPasswordEmpno,
      };

      const res = await apiUser.findByEmpno(data);
      if (res.rsltCd === '00') {
        setFindPasswordEmail(res.data.userInfo.userEmail);
        setFindPasswordUserId(res.data.userInfo.userId);
      } else if (res.rsltCd === '05') {
        makeMsg('조회된 사용자가 없습니다.', 'warning');
      } else {
        makeMsg('네트워크 에러가 발생했습니다.', 'error');
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 에러가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const sendRegno = async () => {
    try {
      const data = {
        userId: findPasswordUserId,
        userEmail: findPasswordEmail,
      };

      setIsLoading(true);
      const res = await apiUser.generateRegno(data);
      if (res.rsltCd === '00') {
        makeMsg('인증번호가 전송되었습니다.', 'success');
        setIsShowRegno(true);
      } else {
        makeMsg('네트워크 에러가 발생했습니다.', 'warning');
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 에러가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const checkReg = async () => {
    try {
      if (
        !findPasswordRegno.replaceAll(' ', '') ||
        findPasswordRegno.length > 6
      ) {
        makeMsg('인증번호를 정확히 입력하세요.', 'warning');
        return;
      }

      setIsLoading(true);
      const data = {
        userId: findPasswordUserId,
        regno: findPasswordRegno,
      };

      const res = await apiUser.checkRegno(data);
      if (res.rsltCd === '00') {
        makeMsg('인증되었습니다.', 'success');
        setIsRegnoValid(true);
      } else {
        makeMsg('인증정보를 다시 확인해주세요.', 'warning');
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 에러가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const updatePassword = async () => {
    try {
      if (
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          newPassword,
        )
      ) {
        makeMsg(
          '비밀번호는 영문대소문자, 숫자, 특수문자를 포함한 8자 이상으로 작성해주세요.',
          'warning',
        );
        return;
      }

      if (newPasswordConf !== newPassword) {
        makeMsg('비밀번호를 다시 확인해주세요.', 'warning');
        return;
      }

      const data = {
        userId: findPasswordUserId,
        password: newPassword,
      };

      setIsLoading(true);

      const res = await apiUser.changePassword(data);
      if (res.rsltCd === '00') {
        makeMsg('비밀번호가 변경되었습니다.', 'success');
        setIsOpenIdPwPopup(false);
      } else {
        makeMsg('네트워크 에러가 발생했습니다.', 'error');
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 에러가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const requestSignUp = async () => {
    try {
      if (!id.replaceAll(' ', '')) {
        makeMsg('사용하실 아이디를 입력해주세요.', 'warning');
        return;
      }

      if (!signUpPassword.replaceAll(' ', '')) {
        makeMsg('비밀번호를 입력해주세요.', 'warning');
        return;
      }

      if (
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          signUpPassword,
        )
      ) {
        makeMsg(
          '비밀번호는 영문대소문자, 숫자, 특수문자를 포함한 8자 이상으로 작성해주세요.',
          'warning',
        );
        return;
      }

      if (signUpPasswordConf !== signUpPassword) {
        makeMsg('비밀번호를 다시 확인해주세요.', 'warning');
        return;
      }

      if (!userNm.replaceAll(' ', '')) {
        makeMsg('이름을 입력하세요.', 'warning');
        return;
      }

      if (!birthDate.replaceAll(' ', '')) {
        makeMsg('생년월일을 입력하세요.', 'warning');
        return;
      }

      if (!gender.replaceAll(' ', '')) {
        makeMsg('성별을 확인해주세요.', 'warning');
        return;
      }

      if (
        !externalEmail.replaceAll(' ', '') ||
        !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(externalEmail)
      ) {
        makeMsg('이메일 형식에 맞게 이메일을 입력하세요.', 'warning');
        return;
      }

      if (
        !userMobno.replaceAll(' ', '') ||
        !/^01[0-9]{1}-[0-9]{3,4}-[0-9]{4}$/.test(userMobno)
      ) {
        makeMsg(
          '휴대전화 형식에 맞게 번호를 입력하세요.(010-1234-5678)',
          'warning',
        );
        return;
      }

      const data = {
        doubltEmailId: id,
        password: signUpPassword,
        userMobno: userMobno,
        userEmail: externalEmail,
        gender: gender,
        birthDate: moment(birthDate).format('YYYY-MM-DD'),
        userNm: userNm,
      };

      setIsLoading(true);
      const res = await apiUser.insert(data);
      if (res.rsltCd === '00') {
        makeMsg(
          '가입이 신청되었습니다. 관리자 확인 후 사용이 가능합니다.',
          'success',
        );
        setIsOpenSignUpPopup(false);
      } else {
        makeMsg('네트워크 오류가 발생했습니다.', 'error');
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 에러가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  return (
    <>
      <LoginPresenter
        userEmpno={userEmpno}
        setUserEmpno={setUserEmpno}
        password={password}
        setPassword={setPassword}
        login={login}
        checkEnter={checkEnter}
        isOpenIdPwPopup={isOpenIdPwPopup}
        setIsOpenIdPwPopup={setIsOpenIdPwPopup}
        isOpenSignUpPopup={isOpenSignUpPopup}
        setIsOpenSignUpPopup={setIsOpenSignUpPopup}
        findPasswordEmpno={findPasswordEmpno}
        setFindPasswordEmpno={setFindPasswordEmpno}
        findPasswordEmail={findPasswordEmail}
        findPasswordRegno={findPasswordRegno}
        setFindPasswordRegno={setFindPasswordRegno}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        newPasswordConf={newPasswordConf}
        setNewPasswordConf={setNewPasswordConf}
        isShowRegno={isShowRegno}
        isRegnoValid={isRegnoValid}
        findUserInfo={findUserInfo}
        sendRegno={sendRegno}
        checkReg={checkReg}
        updatePassword={updatePassword}
        id={id}
        setId={setId}
        signUpPassword={signUpPassword}
        setSignUpPassword={setSignUpPassword}
        signUpPasswordConf={signUpPasswordConf}
        setSignUpPasswordConf={setSignUpPasswordConf}
        userNm={userNm}
        setUserNm={setUserNm}
        birthDate={birthDate}
        setBirthDate={setBirthDate}
        gender={gender}
        setGender={setGender}
        externalEmail={externalEmail}
        setExternalEmail={setExternalEmail}
        userMobno={userMobno}
        setUserMobno={setUserMobno}
        requestSignUp={requestSignUp}
      />
    </>
  );
};

LoginContainer.defaultProps = {};

export default LoginContainer;
