import styled, { keyframes } from 'styled-components';

import theme from 'components/styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5rem 10rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
`;

export const ApprContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const MainTitle = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
  padding: 3rem 0 3rem 1rem;
  border-bottom: 2px solid #ccc;
`;

export const Wrapper = styled.div<{ width?: any }>`
  display: flex;
  width: ${(props) => props.width};
  justify-content: start;
`;

export const FlexRowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const FlexColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div<{
  color?: any;
  size?: any;
  fWeight?: any;
  width?: any;
}>`
  display: flex;
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.fWeight ? props.fWeight : 400)};
  color: ${(props) => (props.color ? props.color : null)};
  align-items: center;
`;

export const ApprLine = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  gap: 1rem;
`;

export const UserInfo = styled.div<{ color?: any }>`
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
  gap: 1rem;
  border: 1px solid
    ${(props) => {
      const colorMap: { [key: string]: string } = {
        승인: 'blue',
        반려: 'red',
        default: '#aaa',
      };
      return colorMap[props.color] || colorMap['default'];
    }};
  border-radius: 5px;
  justify-content: center;
  position: relative;
  align-items: center;
`;

export const UserApprSt = styled.div<{ color?: any }>`
  color: ${(props) => {
    const colorMap: { [key: string]: string } = {
      승인: 'blue',
      반려: 'red',
      default: '#aaa',
    };
    return colorMap[props.color] || colorMap['default'];
  }};
  font-weight: 700;
  font-size: 14px;
`;

export const CcUserInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.5rem;
  gap: 1rem;
  border-radius: 5px;
  justify-content: center;
  position: relative;
  align-items: center;
  background-color: #f2f6ff;
  color: #717aff;
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  gap: 1rem;
  border-radius: 5px;
  justify-content: center;
  position: relative;
  align-items: center;
  background-color: #ccc;
  color: #333;
  cursor: pointer;
`;

export const DocWrapper = styled.div`
  display: flex;
  font-size: 12px;
  padding: 1rem 1rem;
  background-color: #e2dffa;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
`;

export const Contents = styled.div`
  width: 900px;
  height: auto;
  border: 1px solid #aaa;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  font-size: 14px;
`;

//결재 의견

export const Header = styled.div<{ width?: any; border?: any }>`
  display: flex;
  width: ${(props) => props.width};
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  font-size: 14px;
  padding: 1rem;
  border-left: 0;
  border-right: ${(props) => props.border};
  background-color: #eee;
`;

export const content = styled.div<{ width?: any; border?: any; fColor?: any }>`
  display: flex;
  width: ${(props) => props.width};
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  font-size: 13px;
  padding: 1.5rem;
  border-top: 0;
  border-left: 0;
  border-right: ${(props) => props.border};
  color: ${(props) => {
    switch (props.fColor) {
      case '승인':
        return 'blue';
      case '반려':
        return `${theme.color.red}`;
      default:
        return '#333';
    }
  }};
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ApprBtn = styled.div`
  width: fit-content;
  padding: 1rem 2rem;
  border: 1px solid #bbb;
  background-color: #4c4c4c;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 2rem;
  cursor: pointer;
  color: #fff;
  font-weight: 700;
`;

export const BackBtn = styled.div`
  width: fit-content;
  padding: 1rem 2rem;
  border: 1px solid #a1a1a1;
  background-color: #fff;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 2rem;
  cursor: pointer;
  color: #20180f;
  font-weight: 600;
`;

//popup
export const PopupBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 20;
  left: 0;
  top: 0;
`;

export const PopupModal = styled.div`
  width: 60rem;
  padding: 4rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.veryDarkGrey};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const ModalCloseButton = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const ModalTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.white};
  margin-bottom: 3rem;
  font-weight: bold;
`;

export const ModalInputRow = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  font-size: 1.4rem;
  color: ${theme.color.white};
  justify-content: center;
  align-items: center;
`;

export const ModalInputLabel = styled.div`
  font-size: 1.6rem;
  color: ${theme.color.white};
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ModalInput = styled.input`
  width: 19.5rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.black};
  color: ${theme.color.white};
  font-size: 1.6rem;
  border: 0;
  padding: 1rem;
`;

export const ModalText = styled.textarea`
  width: 80%;
  height: 150px;
  border-radius: 0.5rem;
  background-color: ${theme.color.black};
  color: ${theme.color.white};
  font-size: 1.6rem;
  border: 0;
  padding: 1rem;
  resize: none;
`;

export const ModalButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalButton = styled.div<{ primary?: boolean }>`
  width: 15rem;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: ${(props) =>
    props.primary ? theme.color.secondary : theme.color.white};
  color: ${(props) => (props.primary ? theme.color.white : theme.color.black)};
  padding: 1rem;
`;

//popup end
