import { token } from 'util/recoilitem';
import apiService from './api-base';

const PACKAGE = 'vacation/mgnt';
const apiVacation = {
  getList: (dto: { startDt: any; endDt: any; adminYn: any }) =>
    apiService.post(PACKAGE + '/getList', dto),

  getUseHistList: (dto: { startDt: any; endDt: any; adminYn: any }) =>
    apiService.post(PACKAGE + '/getUseHistList', dto),

  getApcList: (adminYn: any) =>
    apiService.post(PACKAGE + '/getApcList', adminYn),

  approval: (dto: {
    apcId: any;
    apprStCd: any;
    description: any;
    cancelYn: any;
  }) => apiService.post(PACKAGE + '/approval', dto),

  request: (dto: {
    vacationId: any;
    useStrDt: any;
    useEndDt: any;
    useDvCd: any; // 전일, 오전, 오후 구분
    vacationTypeCd: any;
    apcRsn: any;
    apprTypeId: any;
  }) => apiService.post(PACKAGE + '/request', dto),

  insert: (dto: {
    startDt: any;
    endDt: any;
    vacationTypeCd: any;
    vacationTypeNm: any;
    timeAmount: any;
    userId: any;
  }) => apiService.post(PACKAGE + '/insert', dto),

  update: (dto: any) => apiService.post(PACKAGE + '/update', dto),

  todayMyAmLog: () => apiService.post(PACKAGE + '/todayMyAmLog', {}),

  getMyMonthlyWorkData: () =>
    apiService.post(PACKAGE + '/getMyMonthlyWorkData', {}),
};

export default apiVacation;
