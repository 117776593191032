import styled from 'styled-components';

import theme from 'components/styles/theme';
import { FaSearch } from 'react-icons/fa';

export const Container = styled.div`
  width: 100%;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

// table start

export const TableTitle = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
  margin-top: 3rem;
`;

export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 0.2rem solid ${theme.color.primary};
  border-bottom: 0.2rem solid ${theme.color.primary};
  width: 100%;
  margin: 2rem auto 5rem;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr``;

export const Th = styled.th`
  padding: 1rem 1.5rem;
  border: 2px solid ${theme.color.primary};
  font-size: 1.4rem;
  background-color: ${theme.color.primary};
  color: ${theme.color.white};
`;

export const Td = styled.td`
  padding: 1rem 1.5rem;
  border-bottom: 2px solid ${theme.color.black};
  font-size: 1.4rem;
  position: relative;
  & > :last-child {
    border-bottom: 0;
  }
`;

// table end

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem 1.5rem;
  background-color: ${theme.color.veryLightGrey};
  gap: 2rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: start;
  font-size: 14px;
  padding: 0.1rem 0;
`;

export const Text = styled.div`
  display: flex;
  justify-content: end;
  font-size: 14px;
  width: 15rem;
  padding: 0.1rem 0;
`;

export const InputData = styled.input`
  font-size: 14px;
  padding: 0.1rem 0.5rem;
  margin-left: 2rem;
`;

export const SaveBtn = styled.div`
  font-size: 14px;
  padding: 1rem 1.5rem;
  background-color: ${theme.color.primary};
  color: ${theme.color.white};
  border-radius: 0.5rem;
  width: fit-content;
  margin-top: 1rem;
  display: flex;
  margin-left: auto;
  cursor: pointer;
`;
