import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';

import WorkTimeLogPresenter from './WorkTimeLogPresenter';
import apiAmTime from 'api/api-amtime';
import { makeMsg } from 'util/util';

const WorkTimeLogContainer = () => {
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [wtHistObject, setWtHistObject] = useState<any>();

  const WorkTimeTableTitle = [
    '시작일시',
    '종료일시',
    '사용자',
    '부서',
    '직책',
    '직급',
    '현재결재자',
    '결재상태',
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await apiAmTime.getApcList({ adminYn: 'Y' });
      if (res.rsltCd === '00') {
        setWtHistObject(res?.data?.apcList);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <>
      <WorkTimeLogPresenter
        WorkTimeTableTitle={WorkTimeTableTitle}
        wtHistObject={wtHistObject}
      />
    </>
  );
};

WorkTimeLogContainer.defaultProps = {};

export default WorkTimeLogContainer;
