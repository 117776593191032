import React, { Dispatch, SetStateAction } from 'react';
import * as s from './AmStyled';
import { Link } from 'react-router-dom';

import Dashboard from './dashboard';
import Vacation from './vacation';
import WorkTime from './workTime';
import Approval from './approval';

import NotReady from '../notReady';

interface AmProps {
  onFocused: () => void;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabList: string[];
}

const AmPresenter = (props: AmProps) => {
  return (
    <s.Container onClick={() => props.onFocused()}>
      <s.PageTitle>근태관리</s.PageTitle>
      <s.TabArea>
        <s.TabItemWrapper>
          {props.tabList.map((tabItem: string, index: number) => (
            <s.TabItem
              active={props.activeTab === index + 1}
              onClick={() => props.setActiveTab(index + 1)}
            >
              {tabItem}
            </s.TabItem>
          ))}
        </s.TabItemWrapper>
      </s.TabArea>

      <s.ContentArea>
        {props.activeTab === 1 ? (
          <Dashboard />
        ) : props.activeTab === 2 ? (
          <Vacation />
        ) : props.activeTab === 3 ? (
          <WorkTime />
        ) : props.activeTab === 4 ? (
          <Approval />
        ) : (
          <NotReady />
        )}
      </s.ContentArea>
    </s.Container>
  );
};

export default AmPresenter;
