import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { format, addMonths, subMonths } from 'date-fns';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek } from 'date-fns';
import { isSameMonth, isSameDay, addDays, parse } from 'date-fns';
import "./_style.scss"
import moment from 'moment';

const RenderHeader = ({ currentMonth, prevMonth, nextMonth }) => {
    return (
        <div className="header row">
            <div className="col col-start">
                <span className="text" style={{fontSize:'2.5rem'}}>
                    {format(currentMonth, 'yyyy')}년
                    <span className="text month">
                        {' ' +format(currentMonth, 'M')}월
                    </span>
                </span>
            </div>
            <div className="col col-end">
                <Icon icon="bi:arrow-left-circle-fill" onClick={prevMonth} />
                <Icon icon="bi:arrow-right-circle-fill" onClick={nextMonth} />
            </div>
        </div>
    );
};

const RenderDays = () => {
    const days = [];
    const date = ['일', '월', '화', '수', '목', '금', '토'];

    for (let i = 0; i < 7; i++) {
        days.push(
            <div className="col" key={i}>
                {date[i]}
            </div>,
        );
    }

    return <div className="days row">{days}</div>;
};

const RenderCells = ({ currentMonth, selectedDate, onDateClick, datas }) => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const {baseTime, changeTimeList, monthlyLogList, vacationList, hdayList} = datas.monthlyWorkData;
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';

    // While 도는동안 다음으로 체크
    // (1) vacationList에 해당 날짜 있나?
    //  - 있다면 전일인가? : 전일이면 그냥 휴가표기
    //  - 없다면 오전휴가? 오후휴가? 확인
    // (2) changeTimeList에 해당날짜 있나?
    //  - 있다면 출퇴근을 해당리스트에서 받아오기
    //  - 아니라면 baseTime에서 받아오기
    // (3) monthlyLogList 있나?
    // console.log(vacationList)
    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            formattedDate = format(day, 'd');

            
            const cloneDay = day;
            const matchingTime = changeTimeList?.find((time) => time.date === format(day, 'yyyyMMdd'));
            const matchingHday = hdayList?.find((hday) => hday.hdayDt === format(day, 'yyyyMMdd'));
            const isWeekend = day?.getDay() === 0 || day?.getDay() === 6;
            const matchingVcDate = vacationList?.find((vcDate) => day >= moment(vcDate.useStrDt) && day <= moment(vcDate.useEndDt));
            days.push(
            <div
                className={`col cell ${!isSameMonth(day, monthStart) ? 'disabled' : isSameDay(day, selectedDate) ? 'selected' : 'valid'
                }`}
                key={day}
                onClick={() => onDateClick(cloneDay)}
            >
                <span className={isSameMonth(day, monthStart) ? '' : 'text not-valid'}>
                {formattedDate}
                </span>
                {matchingHday &&
                    (
                    <div style={{ color: 'red', fontSize: '1.3rem' }}
                        className={isSameMonth(day, monthStart) ? 'time-display' : 'not-valid-time-display'}>
                        {matchingHday?.hdayNm}
                    </div>
                    )
                }
                {matchingVcDate &&
                    (
                    <div style={{ color: 'red', fontSize: '1.3rem' }}
                        className={isSameMonth(day, monthStart) ? 'time-display' : 'not-valid-time-display'}>
                        휴가
                    </div>
                    )
                }
                {matchingTime && (
                    <div className={isSameMonth(day, monthStart) ? 'time-display' : 'not-valid-time-display'}>
                        유연근무시간 : 
                        {matchingTime.startTm === '0000' ? <>DayOff</> : <>{matchingTime.startTm} - {matchingTime.endTm}</>}
                    </div>
                )}
                {!matchingHday && !matchingVcDate && !matchingTime && !isWeekend && baseTime && (
                <div className={isSameMonth(day, monthStart) ? 'time-display' : 'not-valid-time-display'}>
                    기본 시간 : {baseTime[0]?.startTm} - {baseTime[0]?.endTm}
                </div>
                )}
            </div>
            );
            day = addDays(day, 1);
        }
        rows.push(
            <div className="row" key={day}>
            {days}
            </div>
        );
        days = [];
    }
    return <div className="body">{rows}</div>;
};

const Calendar = (datas) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };
    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };
    const onDateClick = (day) => {
        setSelectedDate(day);
    };
    return (
        <div className="calendar">
            <RenderHeader
                currentMonth={currentMonth}
                prevMonth={prevMonth}
                nextMonth={nextMonth}
            />
            <RenderDays />
            <RenderCells
                currentMonth={currentMonth}
                selectedDate={selectedDate}
                onDateClick={onDateClick}
                datas={datas}
            />
        </div>
    );
};

export default Calendar;