import React, { Dispatch, SetStateAction } from 'react';
import * as s from './AmLogStyled';
import { Link } from 'react-router-dom';

// import VcSetting from './vcSetting';
// import WorkTime from './workTime';
// import Approval from './approval';

import NotReady from '../../../notReady';
import moment from 'moment';

interface AmLogProps {
  setSelectedYearMonth: Dispatch<SetStateAction<any>>;
  yearMonthList: any;
  searchData: any;
  setSearchData: Dispatch<SetStateAction<any>>;
  getMonthlyList: () => void;
  adminAmExcelHeader: any;
  adminAmExcelData: any;
  fileName: any;
  amTableTitle: any[];
  amHistObject: any;
  changeStartDt: any;
  setChangeStartDt: Dispatch<SetStateAction<any>>;
  changeEndDt: any;
  setChangeEndDt: Dispatch<SetStateAction<any>>;
}

const AmLogPresenter = (props: AmLogProps) => {
  return (
    <s.Container>
      <s.AdminContentArea>
        <s.SearchArea>
          <s.ModalInput //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
            type="date"
            max={'9999-12-31'}
            onChange={(e) => {
              props.setChangeStartDt(e.target.value);
            }}
          />
          ~
          <s.ModalInput
            type="date"
            max={'9999-12-31'}
            onChange={(e) => {
              props.setChangeEndDt(e.target.value);
            }}
          />
          <s.InputLabel>
            <s.Input
              type="text"
              value={props.searchData}
              onChange={(e) => props.setSearchData(e.target.value)}
              placeholder="부서명, 사용자명, 직책, 출퇴근현황 中 택1 입력하세요."
            />
          </s.InputLabel>
          <s.SearchBtn onClick={() => props.getMonthlyList()}>검색</s.SearchBtn>
        </s.SearchArea>
        <s.ExcelBtn
          headers={props.adminAmExcelHeader}
          data={props.adminAmExcelData}
          filename={props.fileName}
        >
          엑셀 다운로드
        </s.ExcelBtn>
        <s.AmTable>
          <s.AmTbody>
            <s.AmTr>
              {props.amTableTitle?.map((item: any) => (
                <s.AmTh>{item}</s.AmTh>
              ))}
            </s.AmTr>
            {props.amHistObject?.length > 0 ? (
              props.amHistObject?.map((row: any) => (
                <s.AmTr>
                  <s.AmTd>{row?.userEmpno}</s.AmTd>
                  <s.AmTd>{row?.userNm}</s.AmTd>
                  <s.AmTd>{row?.deptNm}</s.AmTd>
                  <s.AmTd>{row?.posnNm}</s.AmTd>
                  <s.AmTd>{moment(row?.logDt).format('YYYYMMDD')}</s.AmTd>
                  <s.AmTd>{row?.startTm}</s.AmTd>
                  <s.AmTd>{row?.endTm}</s.AmTd>
                  <s.AmTd>
                    {row?.case
                      ? row?.amDvNm + '(' + row?.case + ')'
                      : row?.amDvNm}
                  </s.AmTd>
                </s.AmTr>
              ))
            ) : (
              <s.AmTr>
                <s.AmTd colSpan={8}>{'데이터가 없습니다.'}</s.AmTd>
              </s.AmTr>
            )}
          </s.AmTbody>
        </s.AmTable>
      </s.AdminContentArea>
    </s.Container>
  );
};

export default AmLogPresenter;
