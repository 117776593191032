import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';

import AmLogPresenter from './AmLogPresenter';
import moment from 'moment';
import apiAmlog from 'api/api-amlog';
import { makeMsg } from 'util/util';

const AmLogContainer = () => {
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [selectedYearMonth, setSelectedYearMonth] = useState(
    moment().startOf('month'),
  );
  const [yearMonthList, setYearMonthList] = useState<string[]>([]);
  const [searchData, setSearchData] = useState('');
  const [fileName, setFileName] = useState<any | undefined>();
  const [amHistObject, setAmHistObject] = useState<any>();
  const [adminAmExcelData, setAdminAmExcelData] = useState([]);
  const [changeStartDt, setChangeStartDt] = useState();
  const [changeEndDt, setChangeEndDt] = useState();

  const amTableTitle = [
    '사번',
    '이름',
    '부서',
    '직책',
    '일자',
    '출근시간',
    '퇴근시간',
    '출/퇴근 누락현황',
  ];
  const adminAmExcelHeader = [
    { label: '사번', key: 'userEmpno' },
    { label: '부서', key: 'deptNm' },
    { label: '이름', key: 'userNm' },
    { label: '직책', key: 'posnNm' },
    { label: '일자', key: 'logDt' },
    { label: '출근시간', key: 'startTm' },
    { label: '퇴근시간', key: 'endTm' },
    { label: '출/퇴근 현황', key: 'amDvCd' },
  ];
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    fetchData();
    getMonthlyList();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      getYearMonthList();
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const getYearMonthList = () => {
    const currentYear: number = moment().year();

    for (let month = 1; month <= 12; month++) {
      const monthString: string = moment(month, 'M').format('MM');
      const yearMonth = `${currentYear}-${monthString}`;
      yearMonthList.push(yearMonth);
    }
    setYearMonthList(yearMonthList);
  };

  const getMonthlyList = async () => {
    try {
      setIsLoading(true);
      const yearMonth = moment().format('YYYYMMDD');
      setFileName(yearMonth + ' 출퇴근 기록 로그');
      const dto: any = {
        amDvCd: '',
        startDt: moment(changeStartDt).format('YYYYMMDD'),
        endDt: moment(changeEndDt).format('YYYYMMDD'),
        keyword: searchData,
      };
      const monthlyRes = await apiAmlog.getList(dto);
      if (monthlyRes.rsltCd === '00') {
        setAmHistObject(monthlyRes.data.amLogList);
        setAdminAmExcelData(
          monthlyRes.data.amLogList.map((item) => ({
            userEmpno: item.userEmpno,
            userNm: item.userNm,
            deptNm: item.deptNm,
            posnNm: item.posnNm,
            logDt: moment(item.logDt).format('YYYY-MM-DD'),
            startTm: item.startTm
              ? moment(item.startTm, 'HHmm').format('HH:mm')
              : '',
            endTm: item.endTm ? moment(item.endTm, 'HHmm').format('HH:mm') : '',
            amDvCd: item.case
              ? item.amDvNm + '(' + item.case + ')'
              : item.amDvNm,
          })),
        );
      } else {
        makeMsg('데이터가 잘못되었습니다..', 'warning');
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const AmLogOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <>
      <AmLogPresenter
        setSelectedYearMonth={setSelectedYearMonth}
        yearMonthList={yearMonthList}
        searchData={searchData}
        setSearchData={setSearchData}
        getMonthlyList={getMonthlyList}
        adminAmExcelHeader={adminAmExcelHeader}
        adminAmExcelData={adminAmExcelData}
        fileName={fileName}
        amTableTitle={amTableTitle}
        amHistObject={amHistObject}
        changeStartDt={changeStartDt}
        setChangeStartDt={setChangeStartDt}
        changeEndDt={changeEndDt}
        setChangeEndDt={setChangeEndDt}
      />
    </>
  );
};

AmLogContainer.defaultProps = {};

export default AmLogContainer;
