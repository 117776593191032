import React, { useState, useEffect, useCallback } from 'react';

import RegistPresenter from './RegistPresenter';
import moment from 'moment';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import apiAppr from 'api/api-appr';
import swal from '@sweetalert/with-react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { tr } from 'date-fns/locale';

const RegistContainer = () => {
  const location = useLocation();
  const apprTypeId = new URLSearchParams(location.search).get('apprTypeId');
  const navigate = useNavigate();
  const userInfo = useRecoilValue(recoilitem.userInfo);

  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [isItemModal, setIsItemModal] = useState(false);
  const [itemTitle, setItemTitle] = useState<string>('');
  const [itemInputData, setItemInputData] = useState<string>('');
  const itemList = ['TEXT', '일자', '금액', 'File'];
  const [price, setPrice] = useState<any | undefined>();
  const [itemSt, setItemSt] = useState('');
  const [openTypeSelect, setOpenTypeSelect] = useState();
  const openDvCdType = [
    { value: 'APPRRG01', label: '전체공개' },
    { value: 'APPRRG02', label: '비공개' },
    { value: 'APPRRG03', label: '부서공개' },
  ];
  interface Item {
    apprDetailQuestionId: any;
    apprQuestionId: any;
    questionTitle: string;
    questionTypeCd: string;
  }
  const [item, setItem] = useState<Item[]>([]);
  const [answer, setAnswer] = useState<any[]>([]);
  const [answerDetail, setAnswerDetail] = useState<any[]>([]);
  const [lineOrder, setLineOrder] = useState<any[]>([]);
  const [contents, setContents] = useState();
  const [detailItemCnt, setDetailItemCnt] = useState(0);
  const [detailItem, setDetailItem] = useState<Item[]>([]);
  const [title, setTitle] = useState();
  const [typeTitle, setTypeTitle] = useState();
  const [user, setUser] = useState<any>([]);
  const [ccUser, setCcUser] = useState<any>([]);
  const [isModal, setIsModal] = useState(false);
  const [startDt, setStartDt] = useState(false);
  const [endDt, setEndDt] = useState(false);
  const [search, setSearch] = useState();
  const [apprObject, setApprObject] = useState();
  const [keyword, setKeyword] = useState('');
  const [imgSend, setImgSend] = useState<string[]>([]);
  const [imgUrlSend, setImgUrlSend] = useState<string[]>([]);

  const ApprTableTitle = [
    '',
    '문서번호',
    '기안양식',
    '기안제목',
    '상신일시',
    '결재자',
    '결재상태',
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const dto = {
        apprTypeId: apprTypeId,
      };
      const res = await apiAppr.detail(dto);
      if (res.rsltCd === '00') {
        setContents(res.data.apprTypeDetail?.content);
        setTypeTitle(res.data.apprTypeDetail?.title);
        setUser(
          res.data?.apprTypeDetail?.lineList?.filter(
            (type) => type.approverTypeCd === 'APPRTYPE01',
          ),
        );
        setCcUser(
          res.data?.apprTypeDetail?.lineList?.filter(
            (type) => type.approverTypeCd === 'APPRTYPE04',
          ),
        );
        setItem(res.data.apprTypeDetail?.questionList);
        if (res.data.apprTypeDetail?.detailQuestionList?.length > 0) {
          setDetailItem(res.data.apprTypeDetail?.detailQuestionList);
        }
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const priceChange = (e) => {
    const { value } = e.target;
    const formattedValue = value
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    setPrice(formattedValue);
  };

  const updateAnswer = (index, value) => {
    // answer 배열의 복사본을 만듭니다.
    const updatedAnswer = [...answer];
    // 인덱스(index)에 해당하는 위치에 값을 저장합니다.
    updatedAnswer[index] = value;
    // answer 상태를 업데이트합니다.
    setAnswer(updatedAnswer);
  };

  const updateAnswerDetail = (index, value, lineIndex) => {
    // answer 배열의 복사본을 만듭니다.
    const updatedAnswer = [...answerDetail];
    // 인덱스(index)에 해당하는 위치에 값을 저장합니다.
    updatedAnswer[index] = value;
    // answer 상태를 업데이트합니다.
    setAnswerDetail(updatedAnswer);
  };

  const detailItemAdd = () => {
    if (answerDetail.length !== detailItem.length) {
      makeMsg('모든 항목을 입력 하고 추가하세요.', 'warning');
      return;
    }
    if (answerDetail.filter((item) => item === '').length > 0) {
      makeMsg('내용을 입력해주세요.', 'warning');
      return;
    } else {
      setDetailItemCnt(detailItemCnt + 1);
      const updatedAnswers = [...lineOrder];
      updatedAnswers[detailItemCnt] = answerDetail;
      setLineOrder(updatedAnswers);
    }
  };

  const detailDelete = (index: number) => {
    setDetailItemCnt(detailItemCnt - 1);
  };

  const documentPopup = async () => {
    try {
      setIsModal(true);
      const dto = {
        apprStCd: 'all',
        userId: userInfo.userId,
        startDt: startDt,
        endDt: endDt,
        keyword: keyword,
      };
      const res = await apiAppr.all(dto);
      if (res.rsltCd === '00') {
        //상신함
        setApprObject(res.data.apcList);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const save = async (content, user, ccUser, refDoc, apprStCd) => {
    try {
      const lineList = [
        ...user.map((user, index) => ({
          userId: user.userId,
          approverTypeCd: 'APPRTYPE01',
          apprOrder: index,
        })),
        ...ccUser.map((user, index) => ({
          userId: user.userId,
          approverTypeCd: 'APPRTYPE04',
          apprOrder: null,
        })),
      ];
      const answerList = item
        .filter(
          (item) =>
            item.questionTypeCd !== '참조문서' &&
            item.questionTypeCd !== 'File',
        )
        .map((item, index) => ({
          apprQuestionId: item?.apprQuestionId,
          answer: answer[index],
        }));
      const detailAnswerList = detailItem.map((item, index) => ({
        apprDetailQuestionId: item?.apprDetailQuestionId,
        answer: answerDetail[index],
      }));

      const lineOrderList = lineOrder.flatMap((lineItem, index) =>
        lineOrder[index].map((item, detailIndex) => ({
          lineOrder: index,
          apprDetailQuestionId: detailItem[detailIndex].apprDetailQuestionId,
          answer: item,
        })),
      );

      const fileList = imgUrlSend.map((item, index) => ({
        fileUrl: item,
        fileNm: imgSend[index],
      }));

      const refDocList = refDoc.map((item, index) => ({
        apcId: item,
      }));

      if (!title) {
        makeMsg('기안 제목을 입력하세요.', 'warning');
        return;
      }

      if (!openTypeSelect) {
        makeMsg('부서 공개여부를 선택하세요.', 'warning');
        return;
      }

      if (lineList.length === 0) {
        makeMsg('결재선은 한명이상 선택되어야 합니다.', 'warning');
        return;
      }
      console.log(detailItem);
      if (detailItem.length > 0) {
        if (lineOrder.length === 0) {
          makeMsg('상세내용을 입력해주세요.', 'warning');
          return;
        }
      } else if (
        answerDetail.filter((item) => !item || item === '').length > 0
      ) {
        makeMsg('상세내용을 다 입력해주세요.', 'warning');
        return;
      }

      setIsLoading(true);
      const dto = {
        apprTypeId: apprTypeId,
        openDvCd: openTypeSelect,
        apprStCd: apprStCd,
        refDocList: refDocList,
        fileList: fileList,
        title: title,
        content: content,
        answerList: answerList,
        detailAnswerList: lineOrderList,
        lineList: lineList,
      };
      const res = await apiAppr.userInsert(dto);
      if (res.rsltCd === '00') {
        makeMsg('등록되었습니다.', 'success');
        movePage('/approval');
      } else {
        makeMsg('데이터가 잘못 되었습니다.', 'warning');
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  const fileUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true; // 다중 파일 선택을 활성화
    input.onchange = async (changeEvent) => {
      setIsLoading(true);
      const fileInput = changeEvent.target as HTMLInputElement;
      if (fileInput.files && fileInput.files.length > 0) {
        const files = Array.from(fileInput.files); // 선택된 모든 파일을 배열로 변환

        // 파일 처리를 위한 Promise 배열 생성
        const filePromises = files.map((file) => {
          return new Promise<{ name: string; data: string }>((resolve) => {
            const reader = new FileReader();
            reader.onload = () => {
              const result = reader.result as ArrayBuffer | null;
              if (result instanceof ArrayBuffer) {
                const arrayBuffer = result;
                const base64String = arrayBufferToBase64(arrayBuffer);
                resolve({ name: file.name, data: base64String });
              }
            };
            reader.readAsArrayBuffer(file);
          });
        });

        // 모든 Promise를 병렬로 처리하고 결과를 업데이트
        await Promise.all(filePromises).then((fileDataArray) => {
          const fileNames = fileDataArray.map((fileData) => fileData.name);
          const fileUrls = fileDataArray.map((fileData) => fileData.data);
          setImgSend(fileNames);
          setImgUrlSend(fileUrls);
        });
      }
      setIsLoading(false);
    };

    input.click();
  };

  const movePage = (page) => {
    navigate(page);
  };

  const openNewPage = (url) => {
    window.open(url, '_blank');
  };

  const deleteRow = (indexToRemove) => {
    const updatedLineOrder = lineOrder.filter(
      (_, index) => index !== indexToRemove,
    );
    setLineOrder(updatedLineOrder);
    setDetailItemCnt(detailItemCnt - 1);
  };

  return (
    <>
      <RegistPresenter
        openNewPage={openNewPage}
        priceChange={priceChange}
        isItemModal={isItemModal}
        setIsItemModal={setIsItemModal}
        itemList={itemList}
        item={item}
        setItemInputData={setItemInputData}
        itemTitle={itemTitle}
        setItemTitle={setItemTitle}
        setPrice={setPrice}
        price={price}
        detailItem={detailItem}
        setDetailItem={setDetailItem}
        detailItemCnt={detailItemCnt}
        setDetailItemCnt={setDetailItemCnt}
        itemSt={itemSt}
        setItemSt={setItemSt}
        setItem={setItem}
        save={save}
        setTitle={setTitle}
        movePage={movePage}
        title={title}
        setUser={setUser}
        user={user}
        setCcUser={setCcUser}
        ccUser={ccUser}
        contents={contents}
        typeTitle={typeTitle}
        setAnswer={setAnswer}
        updateAnswer={updateAnswer}
        updateAnswerDetail={updateAnswerDetail}
        openTypeSelect={openTypeSelect}
        setOpenTypeSelect={setOpenTypeSelect}
        openDvCdType={openDvCdType}
        isModal={isModal}
        setIsModal={setIsModal}
        setStartDt={setStartDt}
        setEndDt={setEndDt}
        search={search}
        setSearch={setSearch}
        documentPopup={documentPopup}
        setKeyword={setKeyword}
        apprObject={apprObject}
        ApprTableTitle={ApprTableTitle}
        fileUpload={fileUpload}
        imgSend={imgSend}
        setImgSend={setImgSend}
        imgUrlSend={imgUrlSend}
        setImgUrlSend={setImgUrlSend}
        detailItemAdd={detailItemAdd}
        detailDelete={detailDelete}
        lineOrder={lineOrder}
        deleteRow={deleteRow}
      />
    </>
  );
};

RegistContainer.defaultProps = {};

export default RegistContainer;
