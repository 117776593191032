import theme from 'components/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

export const PageTitle = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${theme.color.black};
  text-align: left;
  margin-bottom: 2rem;
`;

export const TabArea = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const TabItemWrapper = styled.div`
  display: flex;
  gap: 3rem;
`;

export const TabItem = styled.div<{ active?: boolean }>`
  min-width: 8rem;
  padding: 1rem 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  justify-content: center;
  color: ${(props) =>
    props.active ? theme.color.primary : theme.color.lightGrey};
  border-bottom: 3px solid
    ${(props) => (props.active ? theme.color.primary : 'theme.color.lightGrey')};
  cursor: pointer;
  position: relative;
`;

export const ContentArea = styled.div`
  width: 100%;
  min-height: 40rem;
  padding: 3rem 0;
`;

export const NewCnt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: 1rem;
  background-color: ${theme.color.secondary};
  border-radius: 50%;
  color: #fff;
  position: absolute;
  right: -0.3rem;
`;
