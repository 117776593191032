import theme from 'components/styles/theme';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';

export const Container = styled.div`
  width: 100%;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

export const AdminBtnArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 2rem;
`;

export const AdminBtn = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  color: ${theme.color.white};
  background-color: ${theme.color.primary};
  font-size: 1.2rem;
`;

export const DashboardRow = styled.div`
  width: 100%;
  display: flex;
  gap: 3rem;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const TabItemWrapper = styled.div`
  display: flex;
  gap: 3rem;
  margin-top: 3rem;
`;

export const TabItem = styled.div<{ active?: boolean }>`
  min-width: 8rem;
  padding: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  justify-content: center;
  color: ${(props) =>
    props.active ? theme.color.secondary : theme.color.lightGrey};
  border-bottom: 3px solid
    ${(props) => (props.active ? theme.color.secondary : '#fff')};
  cursor: pointer;
`;

export const BaseTimeArea = styled.div`
  display: flex;
  width: 71rem;
  padding: 2rem;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const ChangeTime = styled.div`
  margin-left: 1rem;
  width: 20rem;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.primary};
  color: ${theme.color.white};
  font-size: 1.2rem;
  cursor: pointer;
`;

export const DashboardCard = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 50%;
  border-radius: 0.5rem;
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.lightGrey};
  padding: 3rem;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const DashboardCardTitle = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
`;

export const DashboardCardRow = styled.div`
  width: 100%;
  font-size: 1.6rem;
  color: ${theme.color.black};
  position: relative;
  text-align: left;
  font-weight: bold;
`;

export const DashboardCardRowSpan = styled.span`
  position: absolute;
  right: 0;
  top: calc(50% - 1.3rem);
  font-size: 2rem;
  color: ${theme.color.secondary};
  font-weight: bold;
`;

//관리자 전환 화면

export const AdminContentArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SearchArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 0.3rem;
`;

export const InputLabel = styled.div`
  font-size: 1.6rem;
  color: ${theme.color.black};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Input = styled.input`
  width: 30rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.color.darkGrey};
  color: ${theme.color.black};
  font-size: 1.2rem;
  padding: 1rem;
  margin: 1rem;
`;

export const SearchBtn = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 1.2rem 1.7rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.primary};
  color: ${theme.color.white};
`;

export const ExcelBtn = styled(CSVLink).attrs((props) => ({
  headers: props?.headers,
  data: props?.data,
}))`
  width: fit-content;
  height: fit-content;
  padding: 1.2rem 1.7rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.secondary};
  color: ${theme.color.white};
  margin-left: auto;
  text-decoration: none;
`;

// table start
export const AmTableTitle = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
  margin-top: 3rem;
`;

export const AmTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 0.2rem solid ${theme.color.primary};
  border-bottom: 0.2rem solid ${theme.color.primary};
  width: 100%;
  margin: 1rem auto 5rem;
`;

export const AmTbody = styled.tbody``;

export const AmTr = styled.tr``;

export const AmTh = styled.th`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
`;

export const AmTd = styled.td`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
  & > :last-child {
    border-bottom: 0;
  }
`;
// table end

// popup start

export const PopupBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
`;

export const PopupModal = styled.div`
  width: 60rem;
  padding: 4rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.veryDarkGrey};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  top: 10rem;
`;

export const ModalCloseButton = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const ModalTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.white};
  margin-bottom: 3rem;
  font-weight: bold;
`;

export const ModalInputRow = styled.div`
  width: 40%;
  display: flex;
  gap: 1rem;
  font-size: 1.4rem;
  color: ${theme.color.white};
`;

export const ModalInputLabel = styled.div`
  font-size: 1.6rem;
  color: ${theme.color.white};
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ModalInput = styled.input`
  width: 19.5rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.white};
  color: ${theme.color.darkGrey};
  font-size: 1.2rem;
  border: 1px solid ${theme.color.grey};
  padding: 1rem;
`;

export const ModalInputCheck = styled.input`
  border-radius: 0.5rem;
  background-color: ${theme.color.white};
  color: ${theme.color.darkGrey};
  font-size: 1.2rem;
  border: 1px solid ${theme.color.grey};
  padding: 1rem;
`;

export const ModalText = styled.textarea`
  width: 40%;
  border-radius: 0.5rem;
  background-color: ${theme.color.black};
  color: ${theme.color.white};
  font-size: 1.4rem;
  border: 0;
  padding: 1rem;
  resize: none;
`;

export const ModalNotifyDiv = styled.div`
  font-size: 0.8rem;
  width: 100%;
  text-align: left;
  color: ${theme.color.red};
  padding-left: 25%;
`;

export const ModalButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export const ModalButton = styled.div<{ primary?: boolean }>`
  width: 15rem;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: ${(props) =>
    props.primary ? theme.color.secondary : theme.color.white};
  color: ${(props) => (props.primary ? theme.color.white : theme.color.black)};
  padding: 1rem;
`;

export const ModalRowButton = styled.div`
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  text-align: center;
  background-color: ${theme.color.secondary};
  border-radius: 0.5rem;
  cursor: pointer;
  color: ${theme.color.white};
  font-size: 1.4rem;
  font-weight: bold;
`;

export const ModalSelect = styled.select<{ short?: boolean }>`
  width: 40%;
  height: auto;
  padding: 0.7rem 1rem;
  font-size: 1.4rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  color: ${theme.color.black};

  &:disabled {
    background-color: ${theme.color.grey};
  }
`;

export const SelectOption = styled.option`
  font-size: 1.4rem;
`;

//popup end

export const BtnArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
`;

export const ApprBtn = styled.div<{ primary?: boolean }>`
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid
    ${(props) => (props.primary ? theme.color.primary : theme.color.black)};
  background-color: ${(props) =>
    props.primary ? theme.color.primary : theme.color.white};
  color: ${(props) => (props.primary ? theme.color.white : theme.color.black)};
  cursor: pointer;
`;
