import { useState, useCallback } from 'react';

declare global {
  interface Window {
    NDEFReader: any;
  }
}

export const UseNfc = () => {
  const [nfcData, setNfcData] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    if ('NDEFReader' in window) {
      const reader = new window.NDEFReader();
      alert('된다');
      setNfcData(reader);
      try {
        await reader.scan();

        reader.onreading = (event: any) => {
          const message = event.message;
          let data = '';

          for (const record of message.records) {
            console.log(`Record type: ${record.recordType}`);
            console.log(`MIME type: ${record.mediaType}`);
            data += new TextDecoder().decode(record.data);
          }

          setNfcData(data);
        };

        console.log('NFC reader initialized and started.');
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    } else {
      alert('asdlkfj');
      console.log('Web NFC is not available on this device.');
    }
  }, []);

  return (
    <div>
      <button onClick={fetchData}>Scan NFC</button>
      <div>NFC Data: {nfcData ?? 'No data'}</div>
    </div>
  );
};

export default UseNfc;
