import { DefaultTheme } from 'styled-components';

const theme = {
  basicWidth: '320px',

  color: {
    primary: '#1A2D56',
    secondary: '#38955f',
    third: '#46b775',
    fourth: '#195A45',
    black: '#000000',
    darkGrey: '#676767',
    veryDarkGrey: '#222222',
    grey: '#afafaf',
    lightGrey: '#cecece',
    veryLightGrey: '#efefef',
    orange: '#EF9A45',
    green: '#46b775',
    white: '#ffffff',
    red: '#fe4545',
  },
};

export default theme;
