import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Main from './main';
import UserAm from './am';

//Setting
import Manager from './manager';
import ApprRegist from './manager/apprmgnt/regist';
import ApprUpdate from './manager/apprmgnt/detail';

//appr
import Appr from './appr';
import ApprReg from './appr/current/regist';
import ApprDetail from './appr/detail';
import ApprTemp from './appr/tempstorage';

import Login from './login';
import NotFound from 'components/404';
import NotReady from './notReady';
import Nfc from './nfc';

import LeftNavi from 'components/leftNavi';
import styled from 'styled-components';

import { useRecoilState } from 'recoil';
import recoilItem from 'util/recoilitem';
import { useEffect, useState } from 'react';
import ChannelService from 'util/ChannelService';
import { Audio } from 'react-loader-spinner';
import theme from 'components/styles/theme';
import { FaCaretSquareUp } from 'react-icons/fa';
import moment from 'moment';

const Router = () => {
  const loadingTextArr = [
    '도움될만한걸 찾는 중...',
    '잠시 기지개 켜는 중...',
    '커피한모금 하는 중...',
    '최선을 다하는 중...',
    '베타오픈 티 안내려고 하는 중...',
  ];
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);
  const [scroll, setScroll] = useState(0);
  const [idx, setIdx] = useState<number>(0);
  const [curText, setCurText] = useState<string>(
    loadingTextArr[moment().unix() % 5],
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      addTime();
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  const addTime = () => {
    setIdx((prevTimeout) => prevTimeout + 1); // Use the functional update form of setLoginTimeout
  };

  useEffect(() => {
    setCurText(loadingTextArr[idx % loadingTextArr.length]);
  }, [idx]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScroll(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  // useEffect(() => {
  //   ChannelService.shutdown();
  //   const memberId = ChannelService.getMemberIdEncrypted(userInfo.userId);
  //   const memberHash = ChannelService.getMemberIdEncrypted(memberId);
  //   ChannelService.boot({
  //     pluginKey: 'af617359-17d9-4d84-9eca-fa92f7b88a5a', //please fill with your plugin key
  //     memberId: memberId,
  //     memberHash: memberHash,
  //     profile: {
  //       name: userInfo.userNm,
  //       email: userInfo.userEmail,
  //     },
  //   });
  // }, []);

  useEffect(() => {
    console.log(userInfo);
  }, [userInfo]);

  return (
    <>
      {isLoading ? (
        <Loading>
          <Audio
            height="150"
            width="150"
            color={theme.color.secondary}
            ariaLabel="three-dots-loading"
          />
          <LoadingText>{curText}</LoadingText>
        </Loading>
      ) : null}
      <BrowserRouter>
        {userInfo?.userId ? (
          <>
            <LeftNavi />
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/mail" element={<NotReady />} />
              <Route path="/approval" element={<Appr />} />
              <Route path="/approval/current/regist" element={<ApprReg />} />
              <Route path="/approval/detail" element={<ApprDetail />} />
              <Route path="/am" element={<UserAm />} />
              <Route path="/board" element={<NotReady />} />
              <Route path="/manager" element={<Manager />} />
              <Route path="/manager/regist" element={<ApprRegist />} />
              <Route path="/manager/detail" element={<ApprUpdate />} />
              <Route path="/approval/temp" element={<ApprTemp />} />
              <Route path="/notfound" element={<NotFound />} />
              <Route path="*" element={<Navigate to="/notfound" />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/nfc" element={<Nfc />} />
            <Route path="/login" element={<Login />} />

            <Route path="/notfound" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}

        <ScrollTop opacity={scroll > 100} onClick={() => scrollToTop()}>
          <FaCaretSquareUp size={'100%'} color={theme.color.secondary} />
        </ScrollTop>
      </BrowserRouter>
    </>
  );
};

export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30rem 0;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999999999999;
`;

export const LoadingText = styled.div`
  margin-top: 2rem;
  font-size: 1.4rem;
  color: ${theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScrollTop = styled.div<{ opacity: boolean }>`
  width: 3rem;
  height: 3rem;
  position: fixed;
  bottom: 4.4rem;
  right: 10rem;
  cursor: pointer;
  z-index: 99999999;
  opacity: ${(props) => (props.opacity ? '1' : '0')};
  transition: 0.35s ease-out;

  @media screen and (max-width: 1200px) {
    bottom: 3rem;
    right: 6rem;
  }
`;

export default Router;
