import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const token = atom({
  key: 'token',
  default: {
    accessToken: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export const isLoading = atom<boolean>({
  key: 'isLoading',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const userInfo = atom({
  key: 'userInfo',
  default: {
    userId: '',
    userNm: '',
    userEmail: '',
    userTypeCd: '',
    profileImage: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export const openLeftNavi = atom({
  key: 'openNavi',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const myAmLog = atom({
  key: 'myAmLog',
  default: {
    enter: '',
    leave: '',
    inVacation: false,
    startTm: '',
    endTm: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export default {
  token,
  isLoading,
  userInfo,
  openLeftNavi,
  myAmLog,
};
