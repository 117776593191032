import apiService from './api-base';

const PACKAGE = 'am/time/extra/mgnt';

const apiAmTime = {
  getApcList: (dto: { adminYn: any }) =>
    apiService.post(PACKAGE + '/getApcList', dto),

  request: (dto: { userId: any; startDtm: any; endDtm: any }) =>
    apiService.post(PACKAGE + '/request', dto),

  approval: (dto: { apcId: any; apprStCd: any }) =>
    apiService.post(PACKAGE + '/approval', dto),

  cancel: (dto: { apcId: any }) => apiService.post(PACKAGE + '/cancel', dto),
};

export default apiAmTime;
