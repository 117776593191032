import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';

import Router from './views/Router';
import { ApiServiceBridge } from 'api/api-base';

declare const window: Window &
  typeof globalThis & {
    IMP: any;
  };

function App() {
  function setScreenSize() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  useEffect(() => {
    setScreenSize();
  });
  const IMP = window.IMP; // 생략 가능
  IMP.init('imp55050205'); // 예: imp00000000a
  return (
    <RecoilRoot>
      <Router />
      <ApiServiceBridge />
    </RecoilRoot>
  );
}

export default App;
