import React, { Dispatch, SetStateAction } from 'react';
import * as s from './ApprStyled';
import { Link } from 'react-router-dom';

// import VcSetting from './vcSetting';
// import WorkTime from './workTime';
import Current from './current';
import OutBox from './outbox';
import ApprBox from './apprbox';
import DocBox from './documentbox';

import NotReady from '../notReady';

interface ApprProps {
  onFocused: () => void;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabList: string[];
}

const ApprPresenter = (props: ApprProps) => {
  return (
    <s.Container onClick={() => props.onFocused()}>
      <s.PageTitle>결재</s.PageTitle>
      <s.TabArea>
        <s.TabItemWrapper>
          {props.tabList.map((tabItem: string, index: number) => (
            <s.TabItem
              active={props.activeTab === index + 1}
              onClick={() => props.setActiveTab(index + 1)}
            >
              {tabItem}
            </s.TabItem>
          ))}
        </s.TabItemWrapper>
      </s.TabArea>
      <s.ContentArea>
        {props.activeTab === 1 ? (
          <Current setActiveTab={props.setActiveTab} />
        ) : props.activeTab === 2 ? (
          <OutBox />
        ) : props.activeTab === 3 ? (
          <ApprBox />
        ) : props.activeTab === 4 ? (
          <DocBox />
        ) : props.activeTab === 5 ? (
          <NotReady />
        ) : (
          <NotReady />
        )}
      </s.ContentArea>
    </s.Container>
  );
};

export default ApprPresenter;
