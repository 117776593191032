import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import apiAmlog from 'api/api-amlog';
import { makeMsg } from 'util/util';
import apiVclog from 'api/api-vclog';
import apiAmTime from 'api/api-amtime';

import RequestPresenter from './RequestPresenter';
import moment from 'moment';

const RequestContainer = () => {
  const setOpenLeftNavi = useSetRecoilState(recoilitem.openLeftNavi);
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [progressCounts, setProgressCounts] = useState<any[]>([0, 0, 0]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const resAm = await apiAmlog.getApcList();
      const resChangeAm = await apiAmlog.getChangeApcList();
      const resWorkTime = await apiAmTime.getApcList({ adminYn: 'Y' });
      const resVclog = await apiVclog.getApcList({ adminYn: 'Y' });

      if (resAm.rsltCd === '00') {
        setProgressCounts([
          resAm.data?.apcList.filter((item) => !item.state).length,
          resChangeAm.data?.apcList.filter((item) => !item.state).length,
          resWorkTime.data?.apcList.filter((item) => !item.state).length,
          resVclog.data?.apcList.filter((item) => !item.state).length,
        ]);
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const onFocused = () => {
    setOpenLeftNavi(false);
  };

  const [activeTab, setActiveTab] = useState<number>(1);
  const tabList = ['기본시간', '유연근무제', '시간 외 근무', '휴가'];

  return (
    <>
      <RequestPresenter
        onFocused={onFocused}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabList={tabList}
        progressCounts={progressCounts}
      />
    </>
  );
};

RequestContainer.defaultProps = {};

export default RequestContainer;
