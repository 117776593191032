import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';

import AmPresenter from './AmPresenter';

const AmContainer = () => {
  const setOpenLeftNavi = useSetRecoilState(recoilitem.openLeftNavi);

  const onFocused = () => {
    setOpenLeftNavi(false);
  };

  const [activeTab, setActiveTab] = useState<number>(1);
  const tabList = ['근태현황', '휴가', '시간외근무', '결재'];

  return (
    <>
      <AmPresenter
        onFocused={onFocused}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabList={tabList}
      />
    </>
  );
};

AmContainer.defaultProps = {};

export default AmContainer;
