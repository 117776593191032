import React, { Dispatch, SetStateAction } from 'react';
import * as s from './MainStyled';
import { Link } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import theme from 'components/styles/theme';
import { isMobile } from 'react-device-detect';
import { SetterOrUpdater } from 'recoil';

interface MainProps {
  onFocused: () => void;
}

const MainPresenter = (props: MainProps) => {
  return (
    <>
      <s.Container onClick={() => props.onFocused()}></s.Container>
    </>
  );
};

export default MainPresenter;
