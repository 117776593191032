import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import LeftNaviPresenter from './LeftNaviPresenter';

import recoilItem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import moment from 'moment';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import ChannelService from 'util/ChannelService';
import apiAmlog from 'api/api-amlog';

const LeftNaviContainer = () => {
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);
  const [myAmLog, setMyAmLog] = useRecoilState(recoilItem.myAmLog);
  const [token, setToken] = useRecoilState(recoilItem.token);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [opened, setOpened] = useState<boolean>(false);
  const [openedDelay, setOpenedDelay] = useState<boolean>(false);
  const [openLeftNavi, setOpenLeftNavi] = useRecoilState(
    recoilItem.openLeftNavi,
  );
  const [loginTimeout, setLoginTimeout] = useState<number>(60 * 60 * 24);
  const navigate = useNavigate();

  const logout = (timeout?: boolean) => {
    setIsLoading(true);
    setUserInfo({
      userId: '',
      userNm: '',
      userEmail: '',
      userTypeCd: '',
      profileImage: '',
    });
    setToken(null);
    setIsLoading(false);
    if (timeout) {
      makeMsg('세션이 만료되었습니다.\n다시 로그인해주세요.', 'warning');
    } else {
      makeMsg('로그아웃 되었습니다', 'success');
    }
    navigate('/');
  };

  useEffect(() => {
    setTimeout(() => {
      apiAmlog.todayMyAmLog().then((res) => {
        setMyAmLog({
          enter: res?.data?.enter,
          leave: res?.data?.leave,
          inVacation: res?.data?.inVacation,
          startTm: res?.data?.startTm,
          endTm: res?.data?.endTm,
        });
      });
    }, 150);
  }, []);

  useEffect(() => {
    if (!openLeftNavi) {
      setOpenedDelay(openLeftNavi);
    } else {
      setTimeout(() => {
        setOpenedDelay(openLeftNavi);
      }, 350);
    }
  }, [openLeftNavi]);

  useEffect(() => {}, [myAmLog]);

  const movePage = (page) => {
    navigate(page);
  };

  const registerEnter = async () => {
    try {
      const res = await apiAmlog.enter();
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const registerLeave = async () => {
    try {
      const res = await apiAmlog.leave();
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const onClickAmButton = async () => {
    try {
      if (myAmLog.inVacation) {
        makeMsg('휴가중이십니다.\n즐겁게 쉬고 재충전하세요!', 'success');
        return;
      } else {
        if (moment().day() === 0 || moment().day() === 6) {
          makeMsg('휴일입니다.', 'success');
          return;
        } else if (myAmLog.enter) {
          if (myAmLog.leave) {
            makeMsg('이미 퇴근하셨습니다.', 'success');
            return;
          } else {
            const res = await apiAmlog.leave();
            if (res.rsltCd === '00') {
              makeMsg('오늘하루도 고생하셨습니다.', 'success');
              const todayAmLogRes = await apiAmlog.todayMyAmLog();
              if (todayAmLogRes.rsltCd === '00') {
                setMyAmLog({
                  enter: todayAmLogRes.data.enter,
                  leave: todayAmLogRes.data.leave,
                  inVacation: todayAmLogRes.data.inVacation,
                  startTm: todayAmLogRes.data.startTm,
                  endTm: todayAmLogRes.data.endTm,
                });
              }
            } else {
              makeMsg('네트워크 오류가 발생했습니다.', 'error');
            }
            return;
          }
        } else {
          if (moment().add(-10, 'minutes').format('HHmm') > myAmLog) {
            const res = await apiAmlog.enter();
            if (res.rsltCd === '00') {
              makeMsg(
                '지각하셨습니다. 근태변경요청을 진행해주세요.',
                'warning',
              );
              const todayAmLogRes = await apiAmlog.todayMyAmLog();
              if (todayAmLogRes.rsltCd === '00') {
                apiAmlog.todayMyAmLogSendMail();
                setMyAmLog({
                  enter: todayAmLogRes.data.enter,
                  leave: todayAmLogRes.data.leave,
                  inVacation: todayAmLogRes.data.inVacation,
                  startTm: todayAmLogRes.data.startTm,
                  endTm: todayAmLogRes.data.endTm,
                });
              }
            } else {
              makeMsg('네트워크 오류가 발생했습니다.', 'error');
            }
            return;
          } else {
            const res = await apiAmlog.enter();
            if (res.rsltCd === '00') {
              makeMsg('오늘하루도 화이팅하세요!', 'success');
              const todayAmLogRes = await apiAmlog.todayMyAmLog();
              if (todayAmLogRes.rsltCd === '00') {
                apiAmlog.todayMyAmLogSendMail();
                setMyAmLog({
                  enter: todayAmLogRes.data.enter,
                  leave: todayAmLogRes.data.leave,
                  inVacation: todayAmLogRes.data.inVacation,
                  startTm: todayAmLogRes.data.startTm,
                  endTm: todayAmLogRes.data.endTm,
                });
              }
            } else {
              makeMsg('네트워크 오류가 발생했습니다.', 'error');
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <>
      <LeftNaviPresenter
        userInfo={userInfo}
        logout={logout}
        opened={opened}
        setOpened={setOpened}
        openedDelay={openedDelay}
        movePage={movePage}
        openLeftNavi={openLeftNavi}
        setOpenLeftNavi={setOpenLeftNavi}
        registerEnter={registerEnter}
        registerLeave={registerLeave}
        onClickAmButton={onClickAmButton}
        myAmLog={myAmLog}
      />
    </>
  );
};

LeftNaviContainer.defaultProps = {};

export default LeftNaviContainer;
