import React, { Dispatch, SetStateAction } from 'react';
import * as s from './AmSettingStyled';
import { Link } from 'react-router-dom';
import NotReady from '../../../notReady';
import moment from 'moment';
import Calendar from 'components/calendar';
import { MdClose } from 'react-icons/md';
import theme from 'components/styles/theme';
import { FaSearch } from 'react-icons/fa';
interface AmSettingProps {
  amSetTableTitle: any[];
  timeFormatChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  timeValue: any;
  amObject: any;
  workSiteList: any;
  setLateTime: Dispatch<SetStateAction<any>>;
  setWeeklyWorkHours: Dispatch<SetStateAction<any>>;
  saveSetting: () => void;
}

const AmSettingPresenter = (props: AmSettingProps) => {
  return (
    <s.Container>
      <s.TableTitle>현재 설정값</s.TableTitle>
      <s.Table>
        <s.Tbody>
          <s.Tr>
            {props.amSetTableTitle?.map((item: any) => (
              <s.Th>{item}</s.Th>
            ))}
          </s.Tr>
          <s.Tr>
            <s.Td>
              {moment(props.amObject?.startTm, 'HHmm').format('HH:mm')}
            </s.Td>
            <s.Td>{props.amObject?.lateTolerance}분</s.Td>
            {/* <s.Td>
              {props.workSiteList
                ?.map((item: any) => item.worksiteNm)
                .join(',')}
            </s.Td>
            <s.Td>50M</s.Td> */}
            <s.Td>40시간</s.Td>
          </s.Tr>
        </s.Tbody>
      </s.Table>
      <s.TableTitle>설정</s.TableTitle>
      <s.Wrapper>
        <s.TextWrapper>
          {props.workSiteList?.map((item: any) => {
            item?.worksiteNm;
          })}
          <s.Text>출근시간(초기값)설정</s.Text>
          <s.InputData
            type="text"
            value={props.timeValue}
            onChange={props.timeFormatChange}
            placeholder="HH:mm"
            maxLength={5}
          ></s.InputData>
        </s.TextWrapper>
        <s.TextWrapper>
          <s.Text>지각 기준설정</s.Text>
          <s.InputData
            type="number"
            pattern="[0-9]*"
            placeholder="숫자만 입력하세요"
            onChange={(e) => props.setLateTime(e.target.value)}
          />
          <span style={{ padding: '0.1rem 0' }}>분</span>
        </s.TextWrapper>
        {/* <s.TextWrapper>
          <s.Text>근무지 설정</s.Text>
          <s.InputData />
        </s.TextWrapper>
        <s.TextWrapper>
          <s.Text>근무지 반경 설정</s.Text>
          <s.InputData
            type="number"
            pattern="[0-9]*"
            placeholder="숫자만 입력하세요"
          />
          <span style={{ padding: '0.1rem 0' }}>M</span>
        </s.TextWrapper> */}
        <s.TextWrapper>
          <s.Text>필수 근무시간(주)</s.Text>
          <s.InputData
            type="number"
            pattern="[0-9]*"
            placeholder="숫자만 입력하세요"
            onChange={(e) => props.setWeeklyWorkHours(e.target.value)}
          />
          <span style={{ padding: '0.1rem 0' }}>시간</span>
        </s.TextWrapper>
      </s.Wrapper>
      <s.SaveBtn onClick={props.saveSetting}>저장</s.SaveBtn>
    </s.Container>
  );
};

export default AmSettingPresenter;
