import styled, { keyframes } from 'styled-components';

import theme from 'components/styles/theme';
import { IoMdArrowDropup } from 'react-icons/io';

export const Container = styled.div`
  width: 100%;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

export const Title = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
  margin-top: 3rem;
`;

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 3rem 1.5rem;
  gap: 10rem;
`;

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10rem;
`;

export const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 20rem;
  color: ${theme.color.primary};
  font-size: 16px;
  gap: 1rem;
  font-weight: 600;
`;

export const Contents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  /* background-color: ${theme.color.veryLightGrey}; */
  border: 2px solid ${theme.color.primary};
  color: ${theme.color.primary};
  border-radius: 100%;
`;

// table start

export const TabItemWrapper = styled.div`
  display: flex;
  gap: 3rem;
  margin-top: 5rem;
`;

export const TabItem = styled.div<{ active?: boolean }>`
  min-width: 8rem;
  padding: 1rem 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  justify-content: center;
  color: ${(props) =>
    props.active ? theme.color.primary : theme.color.lightGrey};
  border-bottom: 3px solid
    ${(props) => (props.active ? theme.color.primary : 'theme.color.lightGrey')};
  cursor: pointer;
  position: relative;
`;

export const ContentArea = styled.div`
  width: 100%;
  min-height: 40rem;
  padding: 3rem 0;
`;

export const NewCnt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  font-size: 1rem;
  background-color: ${theme.color.secondary};
  border-radius: 50%;
  color: #fff;
  position: absolute;
  right: -0.3rem;
`;

export const Reason = styled.div`
  width: 80rem;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #1a2d56;
  padding: 1rem 1.5rem;
  color: #fff;
`;

export const TableTitle = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
  margin-top: 3rem;
`;

export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 0.2rem solid ${theme.color.primary};
  border-bottom: 0.2rem solid ${theme.color.primary};
  width: 100%;
  margin: 2rem auto 5rem;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr``;

export const Th = styled.th`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
  color: #fff;
`;

export const Td = styled.td`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
  position: relative;
  & > :last-child {
    border-bottom: 0;
  }

  &.expanded {
    ${Reason} {
      animation: scale(1) 0.5s ease;
    }
  }
`;

export const BtnArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
`;

// table end

export const ApprRegBtn = styled.div<{ primary?: boolean }>`
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid
    ${(props) => (props.primary ? theme.color.primary : theme.color.black)};
  background-color: ${(props) =>
    props.primary ? theme.color.primary : theme.color.white};
  color: ${(props) => (props.primary ? theme.color.white : theme.color.black)};
  cursor: pointer;
  font-size: 14px;
  width: fit-content;
  position: absolute;
  right: 0;
  top: 0;
`;

//popupStart

export const ApprTypeContainer = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid ${theme.color.veryLightGrey};
  padding: 0;
  transition: max-height 0.3s;
  animation: expand 0.3s forwards;
  border-radius: 5px;
  ${(props) =>
    props.isOpen &&
    `
    max-height: 100%;
    padding: 1rem 2rem;
  `}

  @keyframes expand {
    from {
      max-height: 0;
      padding: 0;
    }
    to {
      max-height: 100%;
      padding: 1rem 2rem;
    }
  }
`;

export const ArrowDropup = styled(IoMdArrowDropup)`
  position: absolute;
  top: 0rem;
  right: 2rem;
  font-size: 3rem;
  cursor: pointer;
  z-index: 2;
`;

export const ApprTypeTitle = styled.div`
  width: 100%;
  font-size: 1.5rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* 내용이 넘칠 때 줄 바꿈을 허용 */
`;

export const ApprTypeWrapper = styled.div`
  border: 1px solid #e5eeff;
  margin: 2rem 1rem;
  display: flex;
  flex-direction: column;
  width: 13.5rem;
  border-radius: 5px;
`;

export const ApprType = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  background-color: #fafbff;
  color: #333;
  font-size: 12px;
`;
export const ApprTypeExplain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 8rem;
  color: #999;
`;
