import React, { useState, useEffect } from 'react';

import ApprovalPresenter from './approvalPresenter';
import apiVclog from 'api/api-vclog';
import apiAmlog from 'api/api-amlog';
import moment from 'moment';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import swal from '@sweetalert/with-react';
import apiAmTime from 'api/api-amtime';

const ApprovalContainer = () => {
  const [vcApcObject, setVcApcObject] = useState<any>();
  const [amApcObject, setAmApcObject] = useState<any>();
  const [changeAmApcObject, setChangeAmApcObject] = useState<any>();
  const [reqAmApcObject, setReqAmApcObject] = useState<any>();
  const [vcReason, setVcReason] = useState<any>({});
  const [wtHistObject, setWtHistObject] = useState<any>();
  const token = useRecoilValue(recoilitem.token);
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const vacationTableTitle = [
    '신청일자',
    '사용자',
    '사용구분',
    '종류',
    '휴가일자',
    '휴가시간',
    '현재결재자',
    '사유',
    '결재',
  ];
  const amTableTitle = [
    '등록일시',
    '부서',
    '직급',
    '이름',
    '현 출/퇴근시간',
    '변경 출/퇴근시간',
    '현재결재자',
    '결재',
    '결재상태',
  ];

  const changeTableTitle = [
    '요청자',
    '부서',
    '변경기간',
    '시작시간',
    '종료시간',
    '현재결재자',
    '결재',
    '결재상태',
  ];

  const reqTableTitle = [
    '신청일자',
    '신청자',
    '일자',
    '시작시간',
    '종료시간',
    '누락현황',
    '사유',
    '현재결재자',
    '결재',
  ];

  const tabList = [
    '기본시간변경',
    '유연근무신청',
    '휴가신청',
    '출퇴근현황 요청',
    '시간외 근무 요청',
  ];
  const WorkTimeTableTitle = [
    '시작일시',
    '종료일시',
    '사용자',
    '부서',
    '직책',
    '직급',
    '현재결재자',
    '결재',
  ];
  const [activeTab, setActiveTab] = useState<number>(1);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const resVc = await apiVclog.getApcList({ adminYn: 'N' });
      const resAm = await apiAmlog.getApcList();
      const resChangeAm = await apiAmlog.getChangeApcList();
      const resReqAm = await apiAmlog.getReqApcList();
      const resAmTime = await apiAmTime.getApcList({ adminYn: 'Y' });

      if (
        resVc.rsltCd === '00' &&
        resAm.rsltCd === '00' &&
        resChangeAm.rsltCd === '00' &&
        resReqAm.rsltCd === '00' &&
        resAmTime.rsltCd === '00'
      ) {
        setVcApcObject(
          resVc.data?.apcList.filter((item) => item?.state !== '승인'),
        );
        setAmApcObject(
          resAm.data?.apcList.filter((item) => item?.state !== '승인'),
        );
        setChangeAmApcObject(
          resChangeAm.data?.apcList.filter((item) => item?.state !== '승인'),
        );
        setReqAmApcObject(
          resReqAm.data?.apcList.filter((item) => item?.state !== '승인'),
        );
        setWtHistObject(
          resAmTime?.data?.apcList.filter((item) => item?.state !== '승인'),
        );
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const approval = async (apprData: any, apprStCd: string, apprNm: string) => {
    try {
      let value;
      if (apprStCd === 'APPRST02') {
        value = await swal({
          text: '승인 진행 하시겠습니까?',
          buttons: {
            confirm: {
              text: '확인',
              closeModal: true,
            },
            cancel: '취소',
          },
        });
      } else {
        value = await swal({
          text: '반려 진행 하시겠습니까?',
          content: {
            element: 'input',
            attributes: {
              placeholder: '반려사유를 입력하세요',
              type: 'text',
            },
          },
          buttons: {
            confirm: {
              text: '확인',
              closeModal: true,
            },
            cancel: '취소',
          },
        });
      }
      if (value) {
        setIsLoading(true);
        let description;
        if (value === true) {
          description = '승인되었습니다.';
        } else {
          description = value;
        }
        const dto: any = {
          apcId: apprData?.apcId,
          apprStCd: apprStCd,
          ...(apprNm === '휴가' && { cancelYn: apprData?.cancelYn }),
          ...(apprNm === '유연근로'
            ? { reason: description }
            : { description: description }),
        };
        let res;
        if (apprNm === '기본시간') {
          res = await apiAmlog.approval(dto);
        } else if (apprNm === '휴가') {
          res = await apiVclog.approval(dto);
        } else if (apprNm === '유연근로') {
          res = await apiAmlog.changeTimeApproval(dto);
        }
        if (res.rsltCd === '00') {
          makeMsg('결재되었습니다.', 'success');
          fetchData();
        } else {
          makeMsg('신청에 실패했습니다(개발자에게 문의하세요).', 'warning');
        }
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const approvalReq = async (apprData: any, apprStCd: string) => {
    try {
      let value;
      if (apprStCd === 'APPRST02') {
        value = await swal({
          text: '승인 진행 하시겠습니까?',
          buttons: {
            confirm: {
              text: '확인',
              closeModal: true,
            },
            cancel: '취소',
          },
        });
      } else {
        value = await swal({
          text: '반려 진행 하시겠습니까?',
          content: {
            element: 'input',
            attributes: {
              placeholder: '반려사유를 입력하세요',
              type: 'text',
            },
          },
          buttons: {
            confirm: {
              text: '확인',
              closeModal: true,
            },
            cancel: '취소',
          },
        });
      }
      if (value) {
        setIsLoading(true);
        let description;
        if (value === true) {
          description = '승인되었습니다.';
        } else {
          description = value;
        }
        const dto: any = {
          chgLogId: apprData.changeLogId,
          apprStCd: apprStCd,
        };
        const res = await apiAmlog.approvalReq(dto);

        if (res.rsltCd === '00') {
          makeMsg('결재되었습니다.', 'success');
          fetchData();
        } else {
          makeMsg('신청에 실패했습니다(개발자에게 문의하세요).', 'warning');
        }
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const workTimeApproval = async (apprData: any, apprStCd: string) => {
    try {
      let value;
      if (apprStCd === 'APPRST02') {
        value = await swal({
          text: '승인 진행 하시겠습니까?',
          buttons: {
            confirm: {
              text: '확인',
              closeModal: true,
            },
            cancel: '취소',
          },
        });
      } else {
        value = await swal({
          text: '반려 진행 하시겠습니까?',
          content: {
            element: 'input',
            attributes: {
              placeholder: '반려사유를 입력하세요',
              type: 'text',
            },
          },
          buttons: {
            confirm: {
              text: '확인',
              closeModal: true,
            },
            cancel: '취소',
          },
        });
      }
      if (value) {
        setIsLoading(true);
        let description;
        if (value === true) {
          description = '승인되었습니다.';
        } else {
          description = value;
        }
        const dto: any = {
          apcId: apprData?.apcId,
          apprStCd: apprStCd,
          description: description,
        };
        const res = await apiAmTime.approval(dto);

        if (res.rsltCd === '00') {
          makeMsg('결재되었습니다.', 'success');
          fetchData();
        } else {
          makeMsg('신청에 실패했습니다(개발자에게 문의하세요).', 'warning');
        }
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const reasonClick = (row) => {
    setVcReason({
      ...vcReason,
      [row.apcId]: !vcReason[row.apcId], // 해당 행의 id를 사용하여 vcReason 값을 토글
    });
  };

  return (
    <>
      <ApprovalPresenter
        vcApcObject={vcApcObject}
        amApcObject={amApcObject}
        changeAmApcObject={changeAmApcObject}
        vacationTableTitle={vacationTableTitle}
        amTableTitle={amTableTitle}
        changeTableTitle={changeTableTitle}
        approval={approval}
        tabList={tabList}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setVcReason={setVcReason}
        vcReason={vcReason}
        reasonClick={reasonClick}
        reqTableTitle={reqTableTitle}
        reqAmApcObject={reqAmApcObject}
        approvalReq={approvalReq}
        workTimeApproval={workTimeApproval}
        wtHistObject={wtHistObject}
        WorkTimeTableTitle={WorkTimeTableTitle}
      />
    </>
  );
};

ApprovalContainer.defaultProps = {};

export default ApprovalContainer;
