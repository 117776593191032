import React, { Dispatch, SetStateAction } from 'react';
import * as s from './VcLogStyled';
import { Link } from 'react-router-dom';

import NotReady from '../../../notReady';
import moment from 'moment';

interface VcLogProps {
  VcLogTableTitle: any;
  wtHistObject: any;
}

const VcLogPresenter = (props: VcLogProps) => {
  return (
    <s.Container>
      <s.TableTitle>휴가 신청현황</s.TableTitle>
      <s.Table>
        <s.Tbody>
          <s.Tr>
            {props.VcLogTableTitle?.map((item: any) => (
              <s.Th>{item}</s.Th>
            ))}
          </s.Tr>
          {props.wtHistObject?.length > 0 ? (
            props.wtHistObject?.map((row: any) => (
              <s.Tr>
                <s.Td>{moment(row?.regDtm).format('YYYY-MM-DD HH:mm')}</s.Td>
                <s.Td>{row?.userNm}</s.Td>
                <s.Td>{row?.vacationTypeNm}</s.Td>
                <s.Td>{row?.useDvNm}</s.Td>
                <s.Td>
                  {row?.useStrDt}~{row?.useEndDt}
                </s.Td>
                <s.Td>{row?.useAmount}시간</s.Td>
                <s.Td>{row?.nowturn}</s.Td>
                <s.Td>{row?.apcRsn}</s.Td>
                <s.Td>{row?.apprStCdNm}</s.Td>
              </s.Tr>
            ))
          ) : (
            <s.Tr>
              <s.Td colSpan={7}>{'데이터가 없습니다.'}</s.Td>
            </s.Tr>
          )}
        </s.Tbody>
      </s.Table>
    </s.Container>
  );
};

export default VcLogPresenter;
