import styled, { keyframes } from 'styled-components';

import theme from 'components/styles/theme';

export const Container = styled.div`
  width: 100%;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

export const Title = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
  margin: 3rem 0;
`;

export const SearchWrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 1rem;
`;

export const SearchTitle = styled.div`
  width: 100px;
  font-size: 1.4rem;
  text-align: left;
`;

export const ModalInput = styled.input`
  width: 10rem;
  padding: 0.7rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.white};
  color: ${theme.color.darkGrey};
  font-size: 1.2rem;
  border: 1px solid ${theme.color.grey};
`;

export const ModalSelect = styled.select<{ short?: boolean }>`
  width: 150px;
  height: auto;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  color: ${theme.color.black};
  padding: 0.5rem;

  &:disabled {
    background-color: ${theme.color.grey};
  }
`;

export const SelectOption = styled.option`
  font-size: 1.2rem;
  padding: 0.5rem;
`;

export const SearchBtn = styled.div`
  margin-left: 1rem;
  width: 80px;
  font-size: 1.2rem;
  padding: 0.8rem;
  background-color: ${theme.color.primary};
  color: ${theme.color.white};
  border-radius: 5px;
  cursor: pointer;
`;

// table start

export const TableTitle = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
  margin-top: 3rem;
`;

export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 0.2rem solid ${theme.color.primary};
  border-bottom: 0.2rem solid ${theme.color.primary};
  width: 100%;
  margin: 2rem auto 5rem;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr``;

export const Th = styled.th`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
  color: #fff;
`;

export const Td = styled.td`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
  position: relative;
  & > :last-child {
    border-bottom: 0;
  }
`;

export const BtnArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
`;

// table end
