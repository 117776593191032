import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';

import ManagerPresenter from './ManagerPresenter';

const ManagerContainer = () => {
  const setOpenLeftNavi = useSetRecoilState(recoilitem.openLeftNavi);

  const onFocused = () => {
    setOpenLeftNavi(false);
  };

  const [activeTab, setActiveTab] = useState<number>(1);
  const tabList = ['현황관리', '요청관리', '결재관리', '설정'];

  return (
    <>
      <ManagerPresenter
        onFocused={onFocused}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabList={tabList}
      />
    </>
  );
};

ManagerContainer.defaultProps = {};

export default ManagerContainer;
