import React, { Dispatch, SetStateAction } from 'react';
import * as s from './VacationStyled';
import { Link } from 'react-router-dom';
import NotReady from '../../notReady';
import moment from 'moment';
import Calendar from 'components/calendar';
import { MdClose } from 'react-icons/md';
import theme from 'components/styles/theme';
interface VacationProps {
  vcObject: any;
  vcHistObject: any;
  isModal: boolean | any;
  useDvCd: any;
  useStrDt: any;
  useEndDt: any;
  apcRsn: any;
  setIsModal: Dispatch<SetStateAction<any>>;
  selectedValue: any;
  setSelectedValue: Dispatch<SetStateAction<any>>;
  selectedLabel: any;
  setSelectedLabel: Dispatch<SetStateAction<any>>;
  reqVacation: () => void;
  setVacationId: Dispatch<SetStateAction<any>>;
  setUseStrDt: Dispatch<SetStateAction<any>>;
  setUseEndDt: Dispatch<SetStateAction<any>>;
  setUseDvCd: Dispatch<SetStateAction<any>>;
  setApcRsn: Dispatch<SetStateAction<any>>;
  vacationDvCdList: any[];
  vacationTableTitle: any[];
  userInfo: any;
  setIsAdmin: Dispatch<SetStateAction<any>>;
  isAdmin: any;
  searchData: any;
  setSearchData: Dispatch<SetStateAction<any>>;
  //getMonthlyList: () => void;
  amHistObject: any;
  fetchAdminData: any;
  adminVacationTableTitle: any[];
  adminVcObject: any;
  adminVcHistObject: any;
  adminVacationExcelHeader: any;
  adminVacationExcelData: any;
  fileName: any;
}

const VacationPresenter = (props: VacationProps) => {
  return (
    <s.Container>
      {props.userInfo?.userTypeCd === 'USRUTADM' && (
        <s.AdminBtnArea>
          <s.AdminBtn
            onClick={() => {
              props.setIsAdmin(!props.isAdmin);
              !props.isAdmin && props.fetchAdminData();
            }}
          >
            {props.isAdmin ? '휴가관리' : '직원 휴가관리'}
          </s.AdminBtn>
        </s.AdminBtnArea>
      )}
      {!props.isAdmin ? (
        <>
          <s.VacationRow>
            <s.VacationCard>
              <s.VacationCardTitle>발생 휴가시간</s.VacationCardTitle>
              {props.vcObject?.map((item: any, index: number) => (
                <s.VacationCardRow>
                  {item.vacationTypeNm}
                  <s.VacationCardRowSpan>
                    {item.timeAmount} 시간
                  </s.VacationCardRowSpan>
                </s.VacationCardRow>
              ))}
            </s.VacationCard>
            <s.VacationCard>
              <s.VacationCardTitle>잔여 휴가시간</s.VacationCardTitle>
              {props.vcObject?.map((item: any, index: number) => (
                <s.VacationCardRow>
                  {item.vacationTypeNm}
                  <s.VacationCardRowSpan>
                    {item.remainTimeAmount} 시간
                  </s.VacationCardRowSpan>
                </s.VacationCardRow>
              ))}
            </s.VacationCard>
          </s.VacationRow>

          <s.VacationTableTitle>
            휴가 현황
            <s.VacationReqBtn onClick={() => props.setIsModal(!props.isModal)}>
              휴가 신청
            </s.VacationReqBtn>
          </s.VacationTableTitle>

          <s.VacationTable>
            <s.VacationTbody>
              <s.VacationTr>
                {props.vacationTableTitle?.map((item: any) => (
                  <s.VacationTh>{item}</s.VacationTh>
                ))}
              </s.VacationTr>
              {props.vcHistObject?.length > 0 ? (
                props.vcHistObject?.map((row: any) => (
                  <s.VacationTr>
                    <s.VacationTd>
                      {moment(row?.regDtm).format('YYYY-MM-DD')}
                    </s.VacationTd>
                    <s.VacationTd>{row?.vacationTypeNm}</s.VacationTd>
                    <s.VacationTd>{row?.useDvNm}</s.VacationTd>
                    <s.VacationTd>
                      {row?.useStrDt === row?.useEndDt ? (
                        <>{moment(row?.useStrDt).format('YYYY-MM-DD')}</>
                      ) : (
                        <>
                          {moment(row?.useStrDt).format('YYYY-MM-DD')}~
                          {moment(row?.useEndDt).format('YYYY-MM-DD')}
                        </>
                      )}
                    </s.VacationTd>
                    <s.VacationTd>{row?.useAmount}시간</s.VacationTd>
                    <s.VacationTd>{row?.apprStNm}</s.VacationTd>
                  </s.VacationTr>
                ))
              ) : (
                <s.VacationTr>
                  <s.VacationTd colSpan={6}>
                    {'데이터가 없습니다.'}
                  </s.VacationTd>
                </s.VacationTr>
              )}
            </s.VacationTbody>
          </s.VacationTable>
        </>
      ) : (
        <>
          <s.AdminContentArea>
            <s.SearchArea>
              <s.InputLabel>
                <s.Input
                  type="text"
                  value={props.searchData}
                  onChange={(e) => props.setSearchData(e.target.value)}
                  placeholder="부서명, 사용자명, 직책 中 택1 입력하세요."
                />
              </s.InputLabel>
              <s.SearchBtn onClick={() => props.fetchAdminData()}>
                검색
              </s.SearchBtn>
            </s.SearchArea>
            <s.ExcelBtn
              headers={props.adminVacationExcelHeader}
              data={props.adminVacationExcelData}
              filename={props.fileName}
            >
              엑셀 다운로드
            </s.ExcelBtn>
            <s.VcTable>
              <s.VcTbody>
                <s.VcTr>
                  {props.adminVacationTableTitle?.map((item: any) => (
                    <s.VcTh>{item}</s.VcTh>
                  ))}
                </s.VcTr>
                {props.adminVcHistObject?.length > 0 ? (
                  props.adminVcHistObject?.map((row: any) => (
                    <s.VacationTr>
                      <s.VacationTd>{row.userNm}</s.VacationTd>
                      <s.VacationTd>
                        {moment(row.regDtm).format('YYYY-MM-DD')}
                      </s.VacationTd>
                      <s.VacationTd>{row.vacationTypeNm}</s.VacationTd>
                      <s.VacationTd>{row.useDvNm}</s.VacationTd>
                      <s.VacationTd>
                        {row?.useStrDt === row?.useEndDt ? (
                          <>{moment(row?.useStrDt).format('YYYY-MM-DD')}</>
                        ) : (
                          <>
                            {moment(row?.useStrDt).format('YYYY-MM-DD')}~
                            {moment(row?.useEndDt).format('YYYY-MM-DD')}
                          </>
                        )}
                      </s.VacationTd>
                      <s.VacationTd>{row.useAmount}시간</s.VacationTd>
                      <s.VacationTd>{row.apprStNm}</s.VacationTd>
                    </s.VacationTr>
                  ))
                ) : (
                  <s.VcTr>
                    <s.VcTd colSpan={6}>{'데이터가 없습니다.'}</s.VcTd>
                  </s.VcTr>
                )}
              </s.VcTbody>
            </s.VcTable>
          </s.AdminContentArea>
        </>
      )}

      {props.isModal ? ( // 휴가신청 팝업
        <s.PopupBackground onClick={() => props.setIsModal(false)}>
          <s.PopupModal onClick={(e) => e.stopPropagation()}>
            <s.ModalCloseButton onClick={() => props.setIsModal(false)}>
              <MdClose size={'100%'} color={theme.color.white} />
            </s.ModalCloseButton>
            <s.ModalTitle>휴가신청</s.ModalTitle>
            <s.ModalInputRow>
              <s.ModalInputLabel>사용구분</s.ModalInputLabel>
              <s.ModalSelect
                value={props.selectedLabel}
                onChange={(e) => {
                  props.setSelectedValue(e.target.value); //TypeCd를 set해준다
                  props.setVacationId(
                    // vacationId를 set해준다
                    e.target.options[e.target.selectedIndex].getAttribute(
                      'data-id',
                    ),
                  );
                  props.setSelectedLabel(
                    // vacationId를 set해준다
                    e.target.options[e.target.selectedIndex].getAttribute(
                      'data-vacation-nm',
                    ),
                  );
                }}
              >
                <s.SelectOption value="">선택</s.SelectOption>
                {props.vcObject.map((vacation: any) => (
                  <s.SelectOption
                    key={vacation.vacationId}
                    data-id={vacation.vacationId} // 수정된 부분: data-id 속성 추가
                    data-vacation-nm={vacation.vacationNm} // 수정된 부분: data-id 속성 추가
                    value={vacation.vacationTypeCd}
                    disabled={
                      vacation.remainTimeAmount < 1 ||
                      moment(vacation.endDt) < moment()
                    }
                  >
                    {vacation.vacationTypeNm}
                    {vacation.remainTimeAmount < 1 && '(불가)'}
                    {moment(vacation.endDt) < moment() && '(불가)'}
                  </s.SelectOption>
                ))}
              </s.ModalSelect>
            </s.ModalInputRow>
            {props.selectedValue && ( // 사용구분이 선택 되면 표출
              <s.ModalInputRow>
                <s.ModalInputLabel>연차/반차</s.ModalInputLabel>
                <s.ModalSelect
                  value={props.useDvCd}
                  onChange={(e) => {
                    props.setUseDvCd(e.target.value); //연차/반차를 set해준다
                  }}
                >
                  <s.SelectOption value="">선택</s.SelectOption>
                  {props.vacationDvCdList.map((vacation: any) => (
                    <s.SelectOption value={vacation.value}>
                      {vacation.label}
                    </s.SelectOption>
                  ))}
                </s.ModalSelect>
              </s.ModalInputRow>
            )}
            <s.ModalInputRow>
              <s.ModalInputLabel>사용일자</s.ModalInputLabel>
              <s.ModalInput //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
                type="date"
                style={{
                  colorScheme: 'dark',
                }}
                max={'9999-12-31'}
                value={props.useStrDt}
                onChange={(e) => props.setUseStrDt(e.target.value)}
              />
              {props.useDvCd === 'AMVACUSE01' && (
                <>
                  ~
                  <s.ModalInput
                    type="date"
                    style={{
                      colorScheme: 'dark',
                    }}
                    max={'9999-12-31'}
                    value={props.useEndDt}
                    onChange={(e) => props.setUseEndDt(e.target.value)}
                  />
                </>
              )}
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>휴가사유</s.ModalInputLabel>
              <s.ModalText
                value={props.apcRsn}
                onChange={(e) => props.setApcRsn(e.target.value)}
                placeholder="휴가 사유를 작성해 주세요."
              />
            </s.ModalInputRow>
            <s.ModalButtonWrapper>
              <s.ModalButton onClick={() => props.setIsModal(false)}>
                취소
              </s.ModalButton>
              <s.ModalButton primary onClick={() => props.reqVacation()}>
                신청
              </s.ModalButton>
            </s.ModalButtonWrapper>
          </s.PopupModal>
        </s.PopupBackground>
      ) : null}
    </s.Container>
  );
};

export default VacationPresenter;
