import React, { Dispatch, SetStateAction } from 'react';
import * as s from './LoginStyled';
import { Link } from 'react-router-dom';
import theme from 'components/styles/theme';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { makeMsg } from 'util/util';
import { FaPassport, FaUserCircle } from 'react-icons/fa';
import { MdAccountCircle, MdClose, MdPassword } from 'react-icons/md';

interface LoginProps {
  userEmpno: string;
  setUserEmpno: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  login: () => void;
  checkEnter: (e) => void;

  isOpenIdPwPopup: boolean;
  setIsOpenIdPwPopup: Dispatch<SetStateAction<boolean>>;
  isOpenSignUpPopup: boolean;
  setIsOpenSignUpPopup: Dispatch<SetStateAction<boolean>>;
  findPasswordEmpno: string;
  setFindPasswordEmpno: Dispatch<SetStateAction<string>>;
  findPasswordEmail: string;
  findPasswordRegno: string;
  setFindPasswordRegno: Dispatch<SetStateAction<string>>;
  newPassword: string;
  setNewPassword: Dispatch<SetStateAction<string>>;
  newPasswordConf: string;
  setNewPasswordConf: Dispatch<SetStateAction<string>>;
  isShowRegno: boolean;
  isRegnoValid: boolean;
  findUserInfo: () => void;
  sendRegno: () => void;
  checkReg: () => void;
  updatePassword: () => void;

  id: string;
  setId: Dispatch<SetStateAction<string>>;
  signUpPassword: string;
  setSignUpPassword: Dispatch<SetStateAction<string>>;
  signUpPasswordConf: string;
  setSignUpPasswordConf: Dispatch<SetStateAction<string>>;
  userNm: string;
  setUserNm: Dispatch<SetStateAction<string>>;
  birthDate: string;
  setBirthDate: Dispatch<SetStateAction<string>>;
  gender: string;
  setGender: Dispatch<SetStateAction<string>>;
  externalEmail: string;
  setExternalEmail: Dispatch<SetStateAction<string>>;
  userMobno: string;
  setUserMobno: Dispatch<SetStateAction<string>>;

  requestSignUp: () => void;
}

const LoginPresenter = (props: LoginProps) => {
  return (
    <>
      <s.Container>
        <s.ImageArea>
          <s.MainImage src="/img/login.jpg" />
          <s.MainImageCover></s.MainImageCover>
        </s.ImageArea>
        <s.LoginArea>
          <s.Logo src="/logo/doublt_white.png" />
          <s.LoginForm>
            <s.LoginRow>
              <s.LoginLabel>
                <MdAccountCircle size={'100%'} color={theme.color.grey} />
              </s.LoginLabel>
              <s.LoginInput
                type="text"
                pattern="[0-9]*"
                value={props.userEmpno}
                onChange={(e) =>
                  e.target.validity.valid
                    ? props.setUserEmpno(e.target.value)
                    : makeMsg('숫자만 입력하세요', 'warning')
                }
                maxLength={7}
                placeholder={'사번을 입력하세요.'}
                onKeyDown={(e) => props.checkEnter(e)}
              />
            </s.LoginRow>
            <s.LoginRow>
              <s.LoginLabel>
                <MdPassword size={'100%'} color={theme.color.grey} />
              </s.LoginLabel>
              <s.LoginInput
                type="password"
                value={props.password}
                onChange={(e) => props.setPassword(e.target.value)}
                placeholder={'비밀번호를 입력하세요.'}
                onKeyDown={(e) => props.checkEnter(e)}
              />
            </s.LoginRow>
            <s.LoginRow>
              <s.LoginButton onClick={() => props.login()}>
                로그인
              </s.LoginButton>
            </s.LoginRow>
            <s.LoginRow>
              <s.AdditionalSpan onClick={() => props.setIsOpenIdPwPopup(true)}>
                ID/PW찾기
              </s.AdditionalSpan>
              <s.AdditionalSpan
                onClick={() => props.setIsOpenSignUpPopup(true)}
              >
                회원가입
              </s.AdditionalSpan>
            </s.LoginRow>
          </s.LoginForm>
        </s.LoginArea>
      </s.Container>
      {props.isOpenIdPwPopup ? (
        <s.PopupBackground onClick={() => props.setIsOpenIdPwPopup(false)}>
          <s.PopupModal onClick={(e) => e.stopPropagation()}>
            <s.ModalCloseButton onClick={() => props.setIsOpenIdPwPopup(false)}>
              <MdClose size={'100%'} color={theme.color.white} />
            </s.ModalCloseButton>
            <s.ModalTitle>ID/PW찾기</s.ModalTitle>
            <s.ModalInputRow>
              <s.ModalInputLabel>사번</s.ModalInputLabel>
              <s.ModalInput
                type="text"
                value={props.findPasswordEmpno}
                onChange={(e) => props.setFindPasswordEmpno(e.target.value)}
                placeholder="사번을 입력하세요."
              />
              <s.ModalRowButton onClick={() => props.findUserInfo()}>
                조회
              </s.ModalRowButton>
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>이메일</s.ModalInputLabel>
              <s.ModalInput
                type="text"
                disabled
                value={props.findPasswordEmail}
              />
              {props.findPasswordEmail ? (
                <s.ModalRowButton onClick={() => props.sendRegno()}>
                  전송
                </s.ModalRowButton>
              ) : null}
            </s.ModalInputRow>
            {props.isShowRegno ? (
              <s.ModalInputRow>
                <s.ModalInputLabel>인증번호</s.ModalInputLabel>
                <s.ModalInput
                  type="text"
                  value={props.findPasswordRegno}
                  onChange={(e) => props.setFindPasswordRegno(e.target.value)}
                  placeholder="인증번호를 입력하세요."
                />
                <s.ModalRowButton onClick={() => props.checkReg()}>
                  인증
                </s.ModalRowButton>
              </s.ModalInputRow>
            ) : null}
            {props.isRegnoValid ? (
              <>
                <s.ModalInputRow>
                  <s.ModalInputLabel>신규비밀번호</s.ModalInputLabel>
                  <s.ModalInput
                    type="password"
                    value={props.newPassword}
                    onChange={(e) => props.setNewPassword(e.target.value)}
                    placeholder="비밀번호를 입력하세요."
                  />
                </s.ModalInputRow>
                {props.newPassword &&
                !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
                  props.newPassword,
                ) ? (
                  <s.ModalNotifyDiv>
                    비밀번호는 영문 대소문자, 숫자, 특수문자를 포함한 8자
                    이상으로 등록하세요.
                  </s.ModalNotifyDiv>
                ) : null}
                <s.ModalInputRow>
                  <s.ModalInputLabel>비밀번호확인</s.ModalInputLabel>
                  <s.ModalInput
                    type="password"
                    value={props.newPasswordConf}
                    onChange={(e) => props.setNewPasswordConf(e.target.value)}
                    placeholder="비밀번호를 다시 입력하세요."
                  />
                </s.ModalInputRow>
                {props.newPasswordConf &&
                props.newPassword !== props.newPasswordConf ? (
                  <s.ModalNotifyDiv>
                    비밀번호를 다시 확인해주세요.
                  </s.ModalNotifyDiv>
                ) : null}
              </>
            ) : null}
            <s.ModalButtonWrapper>
              <s.ModalButton onClick={() => props.setIsOpenIdPwPopup(false)}>
                취소
              </s.ModalButton>
              {props.isRegnoValid ? (
                <s.ModalButton primary onClick={() => props.updatePassword()}>
                  비밀번호변경
                </s.ModalButton>
              ) : null}
            </s.ModalButtonWrapper>
          </s.PopupModal>
        </s.PopupBackground>
      ) : null}
      {props.isOpenSignUpPopup ? (
        <s.PopupBackground onClick={() => props.setIsOpenSignUpPopup(false)}>
          <s.PopupModal onClick={(e) => e.stopPropagation()}>
            <s.ModalCloseButton
              onClick={() => props.setIsOpenSignUpPopup(false)}
            >
              <MdClose size={'100%'} color={theme.color.white} />
            </s.ModalCloseButton>
            <s.ModalTitle>회원가입</s.ModalTitle>
            <s.ModalInputRow>
              <s.ModalInputLabel>이메일계정</s.ModalInputLabel>
              <s.ModalInput
                type="text"
                value={props.id}
                onChange={(e) => props.setId(e.target.value)}
                placeholder="이메일계정에 사용할 아이디를 입력하세요."
              />
              &nbsp;
              <span style={{ color: '#fff' }}>@doublt.com</span>
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>비밀번호</s.ModalInputLabel>
              <s.ModalInput
                type="password"
                value={props.signUpPassword}
                onChange={(e) => props.setSignUpPassword(e.target.value)}
                placeholder="비밀번호를 입력하세요."
              />
            </s.ModalInputRow>
            {props.signUpPassword &&
            !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
              props.signUpPassword,
            ) ? (
              <s.ModalNotifyDiv>
                비밀번호는 영문 대소문자, 숫자, 특수문자를 포함한 8자 이상으로
                등록하세요.
              </s.ModalNotifyDiv>
            ) : null}
            <s.ModalInputRow>
              <s.ModalInputLabel>비밀번호확인</s.ModalInputLabel>
              <s.ModalInput
                type="password"
                value={props.signUpPasswordConf}
                onChange={(e) => props.setSignUpPasswordConf(e.target.value)}
                placeholder="비밀번호를 다시 입력하세요."
              />
            </s.ModalInputRow>
            {props.signUpPasswordConf &&
            props.signUpPassword !== props.signUpPasswordConf ? (
              <s.ModalNotifyDiv>비밀번호를 다시 확인해주세요.</s.ModalNotifyDiv>
            ) : null}
            <s.ModalInputRow>
              <s.ModalInputLabel>이름</s.ModalInputLabel>
              <s.ModalInput
                type="text"
                value={props.userNm}
                onChange={(e) => props.setUserNm(e.target.value)}
                placeholder="이름을 입력하세요."
              />
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>생년월일</s.ModalInputLabel>
              <s.ModalInput
                type="date"
                value={props.birthDate}
                onChange={(e) => props.setBirthDate(e.target.value)}
                style={{
                  colorScheme: 'dark',
                }}
                max={'9999-12-31'}
              />
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>성별</s.ModalInputLabel>
              <s.ModalSelect
                short
                value={props.gender}
                onChange={(e) => props.setGender(e.target.value)}
              >
                <option value="M">남</option>
                <option value="F">여</option>
              </s.ModalSelect>
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>본인확인이메일</s.ModalInputLabel>
              <s.ModalInput
                type="text"
                value={props.externalEmail}
                onChange={(e) => props.setExternalEmail(e.target.value)}
                placeholder="본인 확인용 이메일을 입력하세요."
              />
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>휴대폰번호</s.ModalInputLabel>
              <s.ModalInput
                type="text"
                pattern="[0-9\-]*"
                value={props.userMobno}
                onChange={(e) =>
                  e.target.validity.valid
                    ? props.setUserMobno(e.target.value)
                    : makeMsg('숫자 또는 - 만 입력하세요.', 'warning')
                }
                placeholder="전화번호를 입력하세요."
              />
            </s.ModalInputRow>
            <s.ModalButtonWrapper>
              <s.ModalButton onClick={() => props.setIsOpenSignUpPopup(false)}>
                취소
              </s.ModalButton>
              <s.ModalButton primary onClick={() => props.requestSignUp()}>
                가입 요청
              </s.ModalButton>
            </s.ModalButtonWrapper>
          </s.PopupModal>
        </s.PopupBackground>
      ) : null}
    </>
  );
};

export default LoginPresenter;
