import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';
import recoilItem from 'util/recoilitem';

import TreePresenter from './TreePresenter';
import apiUser from 'api/api-user';
import { makeMsg } from 'util/util';

interface TreeProps {
  setTree: Dispatch<SetStateAction<any>>;
  user: any[];
  ccUser: any[];
  setUserLine: Dispatch<SetStateAction<any>>;
  setCcUserLine: Dispatch<SetStateAction<any>>;
}

const TreeContainer = (props: TreeProps) => {
  const [deptList, setDeptList] = useState();
  const [userList, setUserList] = useState<any | undefined>();
  const [userFilter, setUserFilter] = useState();
  const [userIndex, setUserIndex] = useState();
  const [selectedUser, setSelectedUser] = useState<any | undefined>(null);
  const [user, setUser] = useState(props.user);
  const [ccUser, setCcUser] = useState(props.ccUser);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const resDept = await apiUser.getDeptList();
      const resUser = await apiUser.getList({});
      if (resDept.rsltCd == '00' && resUser.rsltCd == '00') {
        setDeptList(resDept.data.deptList);
        setUserList(resUser.data.userList);
      } else {
        makeMsg('데이터를 불러오지 못했습니다.', 'warning');
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 에러', 'error');
    }
  };

  const buttonClick = (deptId: any, index: any) => {
    const filteredUsers = userList
      .filter((user) => user.deptId === deptId)
      .sort((a, b) => a.userEmpno - b.userEmpno);
    setUserIndex(index);
    setUserFilter(filteredUsers);
  };

  const userSet = (apprTypeCd: any) => {
    if (apprTypeCd === 'APPRTYPE01') {
      // selectedUser가 user 배열에 있는지 확인
      if (!user.some((u) => u?.userId === selectedUser?.userId)) {
        // selectedUser가 user 배열에 없을 때만 추가
        setUser([...user, selectedUser]);
      } else {
        // 이미 있는 경우 알림
        makeMsg('이미 선택된 사용자입니다.', 'warning');
      }
    }

    if (apprTypeCd === 'APPRTYPE04') {
      // selectedUser가 user 배열에 있는지 확인
      if (!ccUser?.some((u) => u?.userId === selectedUser?.userId)) {
        // selectedUser가 user 배열에 없을 때만 추가
        setCcUser([...ccUser, selectedUser]);
      } else {
        // 이미 있는 경우 알림
        makeMsg('이미 선택된 사용자입니다.', 'warning');
      }
    }
  };

  return (
    <>
      <TreePresenter
        deptList={deptList}
        userList={userList}
        setTree={props.setTree}
        buttonClick={buttonClick}
        userFilter={userFilter}
        userIndex={userIndex}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        setUser={setUser}
        user={user}
        setUserLine={props.setUserLine}
        setCcUserLine={props.setCcUserLine}
        userSet={userSet}
        ccUser={ccUser}
        setCcUser={setCcUser}
      />
    </>
  );
};

TreeContainer.defaultProps = {};

export default TreeContainer;
