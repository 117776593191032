import React, { useState, useEffect } from 'react';

import RegistPresenter from './RegistPresenter';
import moment from 'moment';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import apiAppr from 'api/api-appr';

const RegistContainer = () => {
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const userInfo = useRecoilValue(recoilitem.userInfo);
  const [isItemModal, setIsItemModal] = useState(false);
  const [itemTitle, setItemTitle] = useState<string>('');
  const [itemInputData, setItemInputData] = useState<string>('');
  const itemList = ['TEXT', '일자', '금액', 'File', '참조문서'];
  const [price, setPrice] = useState<any | undefined>();
  const [itemSt, setItemSt] = useState('');

  interface Item {
    title: string;
    inputData: string;
  }
  const [item, setItem] = useState<Item[]>([]);
  const [detailItemCnt, setDetailItemCnt] = useState(0);
  const [detailItem, setDetailItem] = useState<Item[]>([]);

  const [title, setTitle] = useState();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      console.log('RegistFetchData');
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const priceChange = (e) => {
    const { value } = e.target;
    const formattedValue = value
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    setPrice(formattedValue);
  };

  const itemReg = (itemSt: any) => {
    if (itemTitle === '') {
      makeMsg('Title을 입력하세요.', 'warning');
      return;
    } else if (itemInputData === '') {
      makeMsg('InputData를 입력하세요.', 'warning');
      return;
    }

    if (itemSt === 'item') {
      setItem([...item, { title: itemTitle, inputData: itemInputData }]);
    } else if (itemSt === 'detailItem') {
      setDetailItem([
        ...detailItem,
        { title: itemTitle, inputData: itemInputData },
      ]);
    }
    setIsItemModal(false);
    setItemTitle('');
    setItemInputData('');
  };

  const save = async (content, user, ccUser) => {
    try {
      if (!title) {
        makeMsg('기안 제목을 입력하세요.', 'warning');
        return;
      }
      setIsLoading(true);

      const lineList = [
        ...user.map((user, index) => ({
          userId: user.userId,
          approverTypeCd: 'APPRTYPE01',
          apprOrder: index,
        })),
        ...ccUser.map((user, index) => ({
          userId: user.userId,
          approverTypeCd: 'APPRTYPE04',
          apprOrder: null,
        })),
      ];

      const questionList = item.map((item, index) => ({
        questionTitle: item.title,
        questionPlaceholder: `${item.title}을 입력하세요.`,
        questionNum: index,
        questionTypeCd: item.inputData,
      }));

      const detailQuestionList = detailItem.map((item, index) => ({
        questionTitle: item.title,
        questionPlaceholder: `${item.title}을 입력하세요.`,
        questionNum: index,
        questionTypeCd: item.inputData,
      }));

      const dto = {
        title: title,
        description: `${title}입니다`,
        content: content,
        ...(questionList.length > 0 && { questionList: questionList }),
        ...(detailQuestionList.length > 0 && {
          detailQuestionList: detailQuestionList,
        }),
        ...(lineList.length > 0 && { lineList: lineList }),
      };
      const res = await apiAppr.insert(dto);
      if (res.rsltCd === '00') {
        makeMsg('등록되었습니다.', 'success');
        window.location.href = '/manager';
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <>
      <RegistPresenter
        priceChange={priceChange}
        isItemModal={isItemModal}
        setIsItemModal={setIsItemModal}
        itemList={itemList}
        item={item}
        setItemInputData={setItemInputData}
        itemTitle={itemTitle}
        setItemTitle={setItemTitle}
        itemReg={itemReg}
        setPrice={setPrice}
        price={price}
        detailItem={detailItem}
        setDetailItem={setDetailItem}
        detailItemCnt={detailItemCnt}
        setDetailItemCnt={setDetailItemCnt}
        itemSt={itemSt}
        setItemSt={setItemSt}
        setItem={setItem}
        save={save}
        setTitle={setTitle}
      />
    </>
  );
};

RegistContainer.defaultProps = {};

export default RegistContainer;
