import styled from 'styled-components';
import theme from 'components/styles/theme';
import { MdCancel } from 'react-icons/md';

export const Container = styled.div`
  /* 여기에 트리 컨테이너의 스타일을 추가하세요 */
  z-index: 2;
  width: 800px;
  height: 620px;
  border: 1px solid #000;
  background-color: #fff;
  align-self: center;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  position: absolute;
  justify-content: center;
  align-items: center;
`;

export const CancleBtn = styled.div`
  width: 20px;
  height: 50px;
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  font-size: 2rem;
  cursor: pointer;
  pointer-events: all;
`;

export const Title = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

export const LeftUserWrapper = styled.div`
  width: 43%;
  height: 50rem;
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  margin-top: 2rem;
  border-radius: 5px;
`;

export const PlusBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border: 1px solid ${theme.color.secondary};
  color: ${theme.color.white};
  background-color: ${theme.color.secondary};
  cursor: pointer;
`;

export const DeptInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const UserWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-left: 2rem;
  cursor: pointer;
`;

export const AddApprLine = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.2rem;
  font-size: 12px;
  background-color: ${theme.color.primary};
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
`;

export const RightUserWrapper = styled.div`
  width: 43%;
  height: 50rem;
  background-color: ${theme.color.veryLightGrey};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.5rem;
  margin-top: 2rem;
  border-radius: 5px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 70%;
  gap: 0.3rem;
`;

export const RightTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 3rem;
`;

export const CCTitle = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  margin: 1rem 0;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
`;

export const ApprLineOrderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ApprLineOrder = styled.div`
  color: #676767;
  font-size: 12px;
  background-color: #cecece;
  padding: 0.2rem 1rem;
`;

export const ApprLineOrderCencel = styled.div`
  color: #676767;
  font-size: 10px;
  padding: 0.2rem 1rem;
  cursor: pointer;
`;

export const RUserWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 0.5rem 1rem;
`;

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  margin-top: 2rem;
  padding-right: 1rem;
  gap: 0.5rem;
`;

export const RegBtn = styled.div`
  background-color: ${theme.color.primary};
  padding: 1rem 1.5rem;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
`;

export const CancelBtn = styled.div`
  padding: 1rem 1.5rem;
  color: #000;
  border-radius: 5px;
  border: 1px solid ${theme.color.primary};
  cursor: pointer;
`;
