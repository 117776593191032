import React, { useState, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';

import VcLogPresenter from './VcLogPresenter';
import apiAmTime from 'api/api-amtime';
import { makeMsg } from 'util/util';
import apiVacation from 'api/api-vclog';

const VcLogContainer = () => {
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [vcHistObject, setVcHistObject] = useState<any>();

  const VcLogTableTitle = [
    '신청일자',
    '사용자',
    '사용구분',
    '종료',
    '휴가일자',
    '휴가시간',
    '결재자',
    '사유',
    '결재상태',
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const res = await apiVacation.getApcList({ adminYn: 'Y' });
      if (res.rsltCd === '00') {
        setVcHistObject(res?.data?.apcList);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <>
      <VcLogPresenter
        VcLogTableTitle={VcLogTableTitle}
        wtHistObject={vcHistObject}
      />
    </>
  );
};

VcLogContainer.defaultProps = {};

export default VcLogContainer;
