import React from 'react';
import styled from 'styled-components';

const NotFoundContainer = () => (
  <>
    <Container>
      <Img src={'/static/img/404page.png'} />
    </Container>
  </>
);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: 20rem 0;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  max-width: 120rem;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export default NotFoundContainer;
