import React, { Dispatch, SetStateAction } from 'react';
import * as s from './RequestStyled';
import { Link } from 'react-router-dom';

import NotReady from '../../notReady';
import AmLog from '../request/amLog';
import VcLog from '../request/vcLog';
import AmChangeLog from '../request/amChangeLog';
import WorkTimeLog from '../request/workTimeLog';

interface RequestProps {
  onFocused: () => void;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  tabList: string[];
  progressCounts: any[];
}

const RequestPresenter = (props: RequestProps) => {
  return (
    <s.Container onClick={() => props.onFocused()}>
      <s.TabItemWrapper>
        {props.tabList.map((tabItem: string, index: number) => (
          <s.TabItem
            active={props.activeTab === index + 1}
            onClick={() => props.setActiveTab(index + 1)}
          >
            {tabItem}
            <s.NewCnt>{props?.progressCounts[index]}</s.NewCnt>
          </s.TabItem>
        ))}
      </s.TabItemWrapper>

      <s.ContentArea>
        {props.activeTab === 1 ? (
          <AmLog />
        ) : props.activeTab === 2 ? (
          <AmChangeLog />
        ) : props.activeTab === 3 ? (
          <WorkTimeLog />
        ) : props.activeTab === 4 ? (
          <VcLog />
        ) : props.activeTab === 5 ? (
          <NotReady />
        ) : (
          <NotReady />
        )}
      </s.ContentArea>
    </s.Container>
  );
};

export default RequestPresenter;
