import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

import DetailPresenter from './DetailPresenter';
import apiAppr from 'api/api-appr';
import moment from 'moment';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import swal from '@sweetalert/with-react';
import { useNavigate } from 'react-router-dom';

const DetailContainer = () => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(recoilitem.userInfo);
  const apprTypeId = new URLSearchParams(location.search).get('apcId');
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [apprDetail, setApprDetail] = useState<any>();
  const [apprLineList, setApprLineList] = useState();
  const [isApprModal, setIsApprModal] = useState(false);
  const apprHeader = ['결재구분', '결재자', '결재 상태', '일시', '결재의견'];
  const [description, setDescription] = useState();
  const [questionTitle, setQuestionTitle] = useState<any[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const dto = {
        apcId: apprTypeId,
      };
      const res = await apiAppr.userDetail(dto);
      if (res.rsltCd === '00') {
        if (res.data.apcDetail.apprStCdNm === '임시대기') {
          movePage(
            `/approval/temp?apcId=${apprTypeId}&apprTypeId=${res.data.apcDetail.apprTypeId}`,
          );
        }
        const detailAnswerList = res.data.apcDetail.detailAnswerList; // API 응답에서 detailAnswerList를 가져옴
        // Set을 사용하여 중복을 제거하고 배열로 다시 변환
        const uniqueQuestionTitles = [
          ...new Set(detailAnswerList.map((item) => item.questionTitle)),
        ];

        // 중복이 제거된 questionTitles를 setQuestionTitle로 설정
        setQuestionTitle(uniqueQuestionTitles);
        setApprDetail(res.data?.apcDetail);
        setApprLineList(res.data?.apcHistList);
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const movePage = (page) => {
    navigate(page);
  };

  const openNewPage = (url) => {
    window.open(url, '_blank');
  };

  const approval = async (apprStCd: string) => {
    try {
      setIsLoading(true);
      const dto = {
        userId: userInfo.userId,
        apcId: apprDetail.apcId,
        apprStCd: apprStCd,
        description: description,
      };
      const res = await apiAppr.decision(dto);
      if (res.rsltCd === '00') {
        makeMsg('결재 하였습니다.', 'success');
        setIsApprModal(false);
        fetchData();
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const recall = async () => {
    try {
      setIsLoading(true);
      const dto = {
        userId: userInfo.userId,
        apcId: apprDetail.apcId,
      };
      const res = await apiAppr.recall(dto);
      if (res.rsltCd === '00') {
        makeMsg('결재 하였습니다.', 'success');
        setIsApprModal(false);
        fetchData();
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <>
      <DetailPresenter
        openNewPage={openNewPage}
        apprDetail={apprDetail}
        apprHeader={apprHeader}
        apprLineList={apprLineList}
        movePage={movePage}
        userInfo={userInfo}
        setIsApprModal={setIsApprModal}
        isApprModal={isApprModal}
        approval={approval}
        description={description}
        setDescription={setDescription}
        questionTitle={questionTitle}
        recall={recall}
      />
    </>
  );
};

DetailContainer.defaultProps = {};

export default DetailContainer;
