import apiAmlog from 'api/api-amlog';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import swal from '@sweetalert/with-react';

import DashboardPresenter from './DashboardPresenter';

const DashboardContainer = () => {
  const [myAmLog, setMyAmLog] = useRecoilState(recoilitem.myAmLog);
  const [amObject, setAmObject] = useState<any>({});
  const [monthlyWorkData, setMonthlyWorkData] = useState<any>({});
  const [weeklyWorkData, setWeeklyWorkData] = useState<any>({});
  const token = useRecoilValue(recoilitem.token);
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [monthlyData, setMonthlyData] = useState<any>({});
  const userInfo = useRecoilValue(recoilitem.userInfo);
  const [isAdmin, setIsAdmin] = useState<any | undefined>(false);
  const [isBaseTimeModal, setIsBaseTimeModal] = useState<any | undefined>(
    false,
  );
  const [isRequestModal, setIsRequestModal] = useState<any | undefined>(false);
  const [isChangeTimeModal, setIsChangeTimeModal] = useState<any | undefined>(
    false,
  );
  const [searchData, setSearchData] = useState('');
  const [amHistObject, setAmHistObject] = useState<any>();
  const amTableTitle = [
    '사번',
    '이름',
    '부서',
    '직책',
    '일자',
    '출근시간',
    '퇴근시간',
    '출/퇴근 누락현황',
    '결재현황',
    '결재',
  ];
  const adminAmExcelHeader = [
    { label: '사번', key: 'userEmpno' },
    { label: '부서', key: 'deptNm' },
    { label: '이름', key: 'userNm' },
    { label: '직책', key: 'posnNm' },
    { label: '일자', key: 'logDt' },
    { label: '출근시간', key: 'startTm' },
    { label: '퇴근시간', key: 'endTm' },
    { label: '출/퇴근 현황', key: 'amDvCd' },
  ];
  const [adminAmExcelData, setAdminAmExcelData] = useState([]);
  const [selectedYearMonth, setSelectedYearMonth] = useState(
    moment().startOf('month'),
  );
  const [yearMonthList, setYearMonthList] = useState<string[]>([]);
  const [fileName, setFileName] = useState<any | undefined>();

  const startTimeList = [
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
  ];

  const endTimeList = [
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
  ];
  const [selectStartTime, setSelectStartTime] = useState<any | undefined>();
  const [selectEndTime, setSelectEndTime] = useState<any | undefined>();
  const [reqStartTime, setReqStartTime] = useState<any | undefined>();
  const [reqEndTime, setReqEndTime] = useState<any | undefined>();
  const [reqLogId, setReqLogId] = useState<any | undefined>();
  const [changeStartDt, setChangeStartDt] = useState<any | undefined>(
    moment().startOf('month').format('YYYYMMDD'),
  );
  const [changeEndDt, setChangeEndDt] = useState<any | undefined>(
    moment().endOf('month').format('YYYYMMDD'),
  );
  const [changeStartTime, setChangeStartTime] = useState<any | undefined>();
  const [changeEndTime, setChangeEndTime] = useState<any | undefined>();
  const tabList = ['달력', '출퇴근현황'];
  const [activeTab, setActiveTab] = useState<number>(1);
  const [reqRsn, setReqRsn] = useState<any | undefined>();
  const [dayOff, setDayOff] = useState(false);

  useEffect(() => {
    fetchData();
    getMonthlyList();
  }, [myAmLog]);

  const getMonthlyList = async () => {
    try {
      setIsLoading(true);
      const yearMonth = moment().format('YYYYMMDD');
      setFileName(yearMonth + ' 출퇴근 기록 로그');
      const dto: any = {
        amDvCd: '',
        startDt: moment(changeStartDt).format('YYYYMMDD'),
        endDt: moment(changeEndDt).format('YYYYMMDD'),
        keyword: userInfo.userNm,
      };
      const monthlyRes = await apiAmlog.getList(dto);
      if (monthlyRes.rsltCd === '00') {
        setAmHistObject(monthlyRes.data.amLogList);
        setAdminAmExcelData(
          monthlyRes.data.amLogList.map((item) => ({
            userEmpno: item.userEmpno,
            userNm: item.userNm,
            deptNm: item.deptNm,
            posnNm: item.posnNm,
            logDt: moment(item.logDt).format('YYYY-MM-DD'),
            startTm: item.startTm
              ? moment(item.startTm, 'HHmm').format('HH:mm')
              : '',
            endTm: item.endTm ? moment(item.endTm, 'HHmm').format('HH:mm') : '',
            amDvCd: item.case
              ? item.amDvNm + '(' + item.case + ')'
              : item.amDvNm,
          })),
        );
      } else {
        makeMsg('데이터가 잘못되었습니다..', 'warning');
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  useEffect(() => {
    const endTime = moment(selectStartTime, 'HH:mm');
    setSelectEndTime(endTime.add(9, 'hour'));
  }, [selectStartTime]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const todayAmLogRes = await apiAmlog.todayMyAmLog();
      if (todayAmLogRes.rsltCd === '00') {
        const enterTmFormat = todayAmLogRes.data.enter
          ? moment(todayAmLogRes.data.enter, 'HHmm').format('HH:mm')
          : '출근전';
        const leaveTmFormat = todayAmLogRes.data.leave
          ? moment(todayAmLogRes.data.leave, 'HHmm').format('HH:mm')
          : '퇴근전';

        if (todayAmLogRes.data.enter && todayAmLogRes.data.leave) {
          let timeDiff = moment(todayAmLogRes.data.leave, 'HHmm').diff(
            moment(todayAmLogRes.data.enter, 'HHmm'),
            'minutes',
          );

          if (timeDiff > 480) {
            timeDiff = timeDiff - 60;
          } else if (timeDiff > 240) {
            timeDiff = timeDiff - 30;
          }

          const diffHours = Math.floor(timeDiff / 60);
          const diffMinutes = timeDiff % 60;

          setAmObject({
            enter: todayAmLogRes.data.enter,
            leave: todayAmLogRes.data.leave,
            inVacation: todayAmLogRes.data.inVacation,
            startTm: todayAmLogRes.data.startTm,
            endTm: todayAmLogRes.data.endTm,
            enterTmFormat: enterTmFormat,
            leaveTmFormat: leaveTmFormat,
            workHours: `${diffHours}H ${diffMinutes}m`,
          });
        } else {
          setAmObject({
            enter: todayAmLogRes.data.enter,
            leave: todayAmLogRes.data.leave,
            inVacation: todayAmLogRes.data.inVacation,
            startTm: todayAmLogRes.data.startTm,
            endTm: todayAmLogRes.data.endTm,
            enterTmFormat: enterTmFormat,
            leaveTmFormat: leaveTmFormat,
            workHours: '퇴근전',
          });
        }
      }

      const monthlyWorkDataRes = await apiAmlog.getMyMonthlyWorkData();
      if (monthlyWorkDataRes.rsltCd === '00') {
        setMonthlyWorkData(monthlyWorkDataRes.data);

        // Get today's date
        const today = new Date();

        // Get the start and end date of the current week (Sunday to Saturday)
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay()); // Set to the previous Sunday
        startOfWeek.setHours(0, 0, 0, 0); // Set time to midnight

        const endOfWeek = new Date(today);
        endOfWeek.setDate(today.getDate() + (6 - today.getDay())); // Set to the next Saturday
        endOfWeek.setHours(23, 59, 59, 999); // Set time to the end of the day

        // 이번주 업무로그
        // Filter entries for the same week as today
        const filteredLogs =
          await monthlyWorkDataRes.data.monthlyLogList.filter((log: any) => {
            // Convert logDt to a Date object
            const logDate = new Date(
              log.logDt.substr(0, 4), // Year
              log.logDt.substr(4, 2) - 1, // Month (0-based)
              log.logDt.substr(6, 2), // Day
            );

            // Check if logDate falls within the start and end of the current week
            return logDate >= startOfWeek && logDate <= endOfWeek;
          });

        // 이번주 휴가로그
        const filteredVacations = await Promise.all(
          monthlyWorkDataRes.data.vacationList.map(async (vacation: any) => {
            const days = await getDaysBetweenSameWeek(
              vacation.useStrDt,
              vacation.useEndDt,
            );
            const result = days.map((day: any) => ({
              ...day,
              useDvCd: vacation.useDvCd,
            }));
            return result;
          }),
        );
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const getDaysBetweenSameWeek = async (
    dateRangeStart: string,
    dateRangeEnd: string,
  ) => {
    const today = new Date();

    // Get the start and end date of the current week (Sunday to Saturday)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay()); // Set to the previous Sunday
    startOfWeek.setHours(0, 0, 0, 0); // Set time to midnight

    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (6 - today.getDay())); // Set to the next Saturday
    endOfWeek.setHours(23, 59, 59, 999); // Set time to the end of the day

    const daysBetweenSameWeek: any = [];
    const currentDate = new Date(dateRangeStart);

    while (currentDate <= new Date(dateRangeEnd)) {
      const currentWeekStart = new Date(currentDate);
      currentWeekStart.setDate(currentDate.getDate() - currentDate.getDay()); // Set to the previous Sunday

      const currentWeekEnd = new Date(currentDate);
      currentWeekEnd.setDate(
        currentDate.getDate() + (6 - currentDate.getDay()),
      ); // Set to the next Saturday

      if (currentWeekStart <= endOfWeek && currentWeekEnd >= startOfWeek) {
        daysBetweenSameWeek.push(currentDate);
      }

      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    return daysBetweenSameWeek;
  };

  const updateBaseTime = async () => {
    try {
      if (!selectStartTime || !selectEndTime) {
        makeMsg('시간을 선택해주세요.', 'warning');
        return;
      }

      const value = await swal({
        text: ' 기본시간 변경신청 하시겠습니까?',
        buttons: {
          confirm: {
            text: '확인',
            closeModal: true,
          },
          cancel: '취소',
        },
      });
      if (value) {
        const dto: any = {
          startTm: moment(selectStartTime, 'HH:mm').format('HHmm'), // 기본시간 시작
          endTm: moment(selectEndTime, 'HH:mm').format('HHmm'), // 기본시간 종료
        };
        const res = await apiAmlog.baseTimeUpdate(dto);
        if (res.rsltCd === '00') {
          makeMsg('신청되었습니다.', 'success');
          setIsBaseTimeModal(false);
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
          makeMsg('데이터가 잘못 되었습니다.', 'warning');
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const updateReqTime = async () => {
    try {
      if (!reqStartTime || !reqEndTime) {
        makeMsg('시간을 선택해주세요.', 'warning');
        return;
      }

      const value = await swal({
        text: ' 출퇴근현황 변경신청 하시겠습니까?',
        buttons: {
          confirm: {
            text: '확인',
            closeModal: true,
          },
          cancel: '취소',
        },
      });
      if (value) {
        const dto: any = {
          userId: userInfo?.userId,
          logId: reqLogId,
          chgStartTm: moment(reqStartTime, 'HH:mm').format('HHmm'), // 시작
          chgEndTm: moment(reqEndTime, 'HH:mm').format('HHmm'), // 종료
          reason: reqRsn,
          apprTypeId: 7,
        };
        const res = await apiAmlog.reqTimeUpdate(dto);
        if (res.rsltCd === '00') {
          makeMsg('신청되었습니다.', 'success');
          setIsRequestModal(false);
          fetchData();
          setReqLogId(null);
          setReqRsn(null);
        } else {
          makeMsg('데이터가 잘못 되었습니다.', 'warning');
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const updateChangeTime = async () => {
    const userId = userInfo?.userId;
    try {
      const value = await swal({
        text: ' 유연근무시간을 신청 하시겠습니까?',
        buttons: {
          confirm: {
            text: '확인',
            closeModal: true,
          },
          cancel: '취소',
        },
      });
      if (value) {
        let dto: any = {};
        if (dayOff === false) {
          if (!changeStartTime || !changeEndTime) {
            makeMsg('시간을 선택해주세요.', 'warning');
            return;
          }
        }

        if (dayOff === true) {
          dto = {
            userId: userId,
            startDt: moment(changeStartDt).format('YYYYMMDD'),
            endDt: moment(changeEndDt).format('YYYYMMDD'),
            startTm: moment('00:00', 'HH:mm').format('HHmm'),
            endTm: moment('00:00', 'HH:mm').format('HHmm'),
            dayOffYn: 'Y',
          };
        } else if (dayOff === false) {
          dto = {
            userId: userId,
            startDt: moment(changeStartDt).format('YYYYMMDD'),
            endDt: moment(changeEndDt).format('YYYYMMDD'),
            startTm: moment(changeStartTime, 'HH:mm').format('HHmm'), // 기본시간 시작
            endTm: moment(changeEndTime, 'HH:mm').format('HHmm'), // 기본시간 종료
            dayOffYn: 'N',
          };
        }

        const res = await apiAmlog.changeTimeUpdate(dto);
        if (res.rsltCd === '00') {
          makeMsg('신청되었습니다.', 'success');
          setIsBaseTimeModal(false);
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
          makeMsg('데이터가 잘못 되었습니다.', 'warning');
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <>
      <DashboardPresenter
        amObject={amObject}
        monthlyWorkData={monthlyWorkData}
        userInfo={userInfo}
        setIsAdmin={setIsAdmin}
        isAdmin={isAdmin}
        searchData={searchData}
        setSearchData={setSearchData}
        amTableTitle={amTableTitle}
        amHistObject={amHistObject}
        isBaseTimeModal={isBaseTimeModal}
        setIsBaseTimeModal={setIsBaseTimeModal}
        isChangeTimeModal={isChangeTimeModal}
        setIsChangeTimeModal={setIsChangeTimeModal}
        yearMonthList={yearMonthList}
        setSelectedYearMonth={setSelectedYearMonth}
        selectedYearMonth={selectedYearMonth}
        adminAmExcelHeader={adminAmExcelHeader}
        adminAmExcelData={adminAmExcelData}
        fileName={fileName}
        startTimeList={startTimeList}
        endTimeList={endTimeList}
        selectStartTime={selectStartTime}
        setSelectStartTime={setSelectStartTime}
        selectEndTime={selectEndTime}
        setSelectEndTime={setSelectEndTime}
        updateBaseTime={updateBaseTime}
        setChangeStartTime={setChangeStartTime}
        setChangeEndTime={setChangeEndTime}
        updateChangeTime={updateChangeTime}
        setChangeStartDt={setChangeStartDt}
        setChangeEndDt={setChangeEndDt}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabList={tabList}
        getMonthlyList={getMonthlyList}
        isRequestModal={isRequestModal}
        setIsRequestModal={setIsRequestModal}
        reqStartTime={reqStartTime}
        setReqStartTime={setReqStartTime}
        reqEndTime={reqEndTime}
        setReqEndTime={setReqEndTime}
        setReqLogId={setReqLogId}
        reqRsn={reqRsn}
        setReqRsn={setReqRsn}
        updateReqTime={updateReqTime}
        setDayOff={setDayOff}
        dayOff={dayOff}
      />
    </>
  );
};

DashboardContainer.defaultProps = {};

export default DashboardContainer;
