import styled from 'styled-components';

import theme from 'components/styles/theme';
import { CSVLink } from 'react-csv';

export const Container = styled.div`
  width: 100%;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

export const VacationReqBtn = styled.div`
  width: fit-content;
  padding: 0.5rem 1.5rem;
  position: absolute;
  top: 0rem;
  right: 0rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.primary};
  color: ${theme.color.white};
  font-size: 1.5rem;
  cursor: pointer;
`;

export const VacationRow = styled.div`
  width: 100%;
  display: flex;
  gap: 3rem;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

// table start
export const VacationTableTitle = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
  margin-top: 3rem;
`;

export const VacationTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 0.2rem solid ${theme.color.primary};
  border-bottom: 0.2rem solid ${theme.color.primary};
  width: 100%;
  margin: 2rem auto 5rem;
`;

export const VacationTbody = styled.tbody``;

export const VacationTr = styled.tr``;

export const VacationTh = styled.th`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
`;

export const VacationTd = styled.td`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
  & > :last-child {
    border-bottom: 0;
  }
`;
// table end

export const VacationCard = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 50%;
  border-radius: 0.5rem;
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.lightGrey};

  padding: 3rem;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const VacationCardTitle = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.primary};
  position: relative;
  text-align: left;
  font-weight: bold;
`;

export const VacationCardRow = styled.div`
  width: 100%;
  font-size: 1.6rem;
  color: ${theme.color.black};
  position: relative;
  text-align: left;
  font-weight: bold;
`;

export const VacationCardRowSpan = styled.span`
  position: absolute;
  right: 0;
  top: calc(50% - 1.5rem);
  font-size: 2rem;
  color: ${theme.color.secondary};
  font-weight: bold;
`;

export const ExcelBtn = styled(CSVLink).attrs((props) => ({
  headers: props?.headers,
  data: props?.data,
}))`
  width: fit-content;
  height: fit-content;
  padding: 1.2rem 1.7rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.secondary};
  color: ${theme.color.white};
  margin-left: auto;
  text-decoration: none;
`;
//팝업

export const PopupBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
`;

export const PopupModal = styled.div`
  width: 60rem;
  padding: 4rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.veryDarkGrey};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
`;

export const ModalCloseButton = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const ModalTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.white};
  margin-bottom: 3rem;
  font-weight: bold;
`;

export const ModalInputRow = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  font-size: 1.4rem;
  color: ${theme.color.white};
`;

export const ModalInputLabel = styled.div`
  font-size: 1.6rem;
  color: ${theme.color.white};
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ModalInput = styled.input`
  width: 19.5rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.black};
  color: ${theme.color.white};
  font-size: 1.6rem;
  border: 0;
  padding: 1rem;
`;

export const ModalText = styled.textarea`
  width: 80%;
  border-radius: 0.5rem;
  background-color: ${theme.color.black};
  color: ${theme.color.white};
  font-size: 1.6rem;
  border: 0;
  padding: 1rem;
  resize: none;
`;

export const ModalNotifyDiv = styled.div`
  font-size: 0.8rem;
  width: 100%;
  text-align: left;
  color: ${theme.color.red};
  padding-left: 25%;
`;

export const ModalButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalButton = styled.div<{ primary?: boolean }>`
  width: 15rem;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: ${(props) =>
    props.primary ? theme.color.secondary : theme.color.white};
  color: ${(props) => (props.primary ? theme.color.white : theme.color.black)};
  padding: 1rem;
`;

export const ModalRowButton = styled.div`
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  text-align: center;
  background-color: ${theme.color.secondary};
  border-radius: 0.5rem;
  cursor: pointer;
  color: ${theme.color.white};
  font-size: 1.4rem;
  font-weight: bold;
`;

export const ModalSelect = styled.select<{ short?: boolean }>`
  width: ${(props) => (props.short ? '20%' : '80%')};
  padding: 0.5rem 1rem;
  font-size: 1.6rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  color: ${theme.color.black};

  &:disabled {
    background-color: ${theme.color.grey};
  }
`;

export const SelectOption = styled.option`
  font-size: 1.6rem;
`;

export const AdminBtnArea = styled.div`
  position: absolute;
  right: 0;
  top: -5rem;
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 2rem;
`;

export const AdminBtn = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  color: ${theme.color.white};
  background-color: ${theme.color.primary};
  font-size: 1.2rem;
`;

export const AdminContentArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const SearchArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
`;
export const InputLabel = styled.div`
  font-size: 1.6rem;
  color: ${theme.color.black};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const Input = styled.input`
  width: 30rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.color.darkGrey};
  color: ${theme.color.black};
  font-size: 1.2rem;
  padding: 1rem;
  margin: 1rem;
`;
export const SearchBtn = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 1.2rem 1.7rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.veryDarkGrey};
  color: ${theme.color.white};
`;

export const VcTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 0.2rem solid ${theme.color.primary};
  border-bottom: 0.2rem solid ${theme.color.primary};
  width: 100%;
  margin: 1rem auto 5rem;
`;
export const VcTbody = styled.tbody``;

export const VcTr = styled.tr``;

export const VcTh = styled.th`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
`;

export const VcTd = styled.td`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${theme.color.black};
  font-size: 1.4rem;
  & > :last-child {
    border-bottom: 0;
  }
`;
