import React, { Dispatch, SetStateAction } from 'react';
import * as s from './VcSettingStyled';
import { Link } from 'react-router-dom';
import NotReady from '../../../notReady';
import moment from 'moment';
import Calendar from 'components/calendar';
import { MdClose } from 'react-icons/md';
import theme from 'components/styles/theme';
import { FaSearch } from 'react-icons/fa';
interface VcSettingProps {
  vacationTableTitle: any[];
  vcObject: any;
  searchData: any;
  setSearchData: Dispatch<SetStateAction<any>>;
  fetchAdminData: () => void;
  isModal: boolean | any;
  setIsModal: Dispatch<SetStateAction<any>>;
  userNm: any;
  userEmpno: any;
  setUserNm: Dispatch<SetStateAction<any>>;
  setOptionSelect: Dispatch<SetStateAction<any>>;
  searchUser: () => void;
  reqVacation: () => void;
  userValue: any;
  showDropdown: any;
  optionSelect: any;
  optionSelectUser: (user: any) => void;
  vacationType: any[];
  setVacationTypeSelect: Dispatch<SetStateAction<any>>;
  vacationTypeSelect: any;
  setVacationNm: Dispatch<SetStateAction<any>>;
  setVacationStartDtm: Dispatch<SetStateAction<any>>;
  setVacationEndDtm: Dispatch<SetStateAction<any>>;
  setVacationTime: Dispatch<SetStateAction<any>>;
  modalContentsReset: () => void;
  updateVcModal: (row: any) => void;
  isUpdateModal: any;
  setIsUpdateModal: Dispatch<SetStateAction<any>>;
  updateUserInfo: any;
  vacationStartDtm: any;
  vacationEndDtm: any;
  vacationTime: any;
  updVacation: () => void;
  startDt: any;
  endDt: any;
  setStartDt: Dispatch<SetStateAction<any>>;
  setEndDt: Dispatch<SetStateAction<any>>;
}

const VcSettingPresenter = (props: VcSettingProps) => {
  return (
    <s.Container>
      <s.SearchArea>
        <s.InputLabel>
          <s.Input
            type="date"
            value={props.startDt}
            style={{
              colorScheme: 'white',
              width: '18.5rem',
            }}
            max={'9999-12-31'}
            onChange={(e) => {
              props.setStartDt(e.target.value);
            }}
          />
          ~
          <s.Input
            type="date"
            value={props.endDt}
            style={{
              colorScheme: 'white',
              width: '18.5rem',
            }}
            max={'9999-12-31'}
            onChange={(e) => {
              props.setEndDt(e.target.value);
            }}
          />
          <s.Input
            type="text"
            value={props.searchData}
            onChange={(e) => props.setSearchData(e.target.value)}
            placeholder="부서명, 사용자명, 직책 中 택1 입력하세요."
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                props.fetchAdminData();
              }
            }}
          />
        </s.InputLabel>
        <s.SearchBtn onClick={() => props.fetchAdminData()}>검색</s.SearchBtn>
      </s.SearchArea>
      <s.VacationTableTitle>
        휴가 현황
        <s.VacationReqBtn onClick={() => props.setIsModal(!props.isModal)}>
          휴가등록
        </s.VacationReqBtn>
      </s.VacationTableTitle>

      <s.VacationTable>
        <s.VacationTbody>
          <s.VacationTr>
            {props.vacationTableTitle?.map((item: any) => (
              <s.VacationTh>{item}</s.VacationTh>
            ))}
          </s.VacationTr>
          {props.vcObject?.length > 0 ? (
            props.vcObject?.map((row: any) => (
              <s.VacationTr
                style={{ cursor: 'pointer' }}
                onClick={() => props.updateVcModal(row)}
              >
                <s.VacationTd>{row?.userNm} </s.VacationTd>
                <s.VacationTd>{row?.deptNm} </s.VacationTd>
                <s.VacationTd>{row?.rankNm} </s.VacationTd>
                <s.VacationTd>{row?.vacationTypeNm}</s.VacationTd>
                <s.VacationTd>
                  {row?.startDt}~{row?.endDt}
                </s.VacationTd>
                <s.VacationTd>{row?.timeAmount}시간</s.VacationTd>
                <s.VacationTd>{row?.remainTimeAmount}시간</s.VacationTd>
                <s.VacationTd>{row?.apprStNm}</s.VacationTd>
              </s.VacationTr>
            ))
          ) : (
            <s.VacationTr>
              <s.VacationTd colSpan={8}>{'데이터가 없습니다.'}</s.VacationTd>
            </s.VacationTr>
          )}
        </s.VacationTbody>
      </s.VacationTable>

      {props.isModal && ( // 휴가신청 팝업
        <s.PopupBackground
          onClick={() => {
            props.setIsModal(false);
            props.modalContentsReset();
          }}
        >
          <s.PopupModal onClick={(e) => e.stopPropagation()}>
            <s.ModalCloseButton
              onClick={() => {
                props.setIsModal(false);
                props.modalContentsReset();
              }}
            >
              <MdClose size={'100%'} color={theme.color.white} />
            </s.ModalCloseButton>
            <s.ModalTitle>휴가 등록</s.ModalTitle>
            <s.ModalInputRow>
              <s.ModalInputLabel>검 색</s.ModalInputLabel>
              <s.ModalInput
                placeholder="'직원의 (이름,사번,부서,직급)을 입력후 검색해 주세요.'"
                onChange={(e) => {
                  props.setUserNm(e.target.value);
                }}
              />
              {props.showDropdown && (
                <s.DropdownList>
                  {props.userValue?.map((user) => (
                    <s.OptionItem
                      key={user?.id}
                      onClick={() => props.optionSelectUser(user)}
                    >
                      {`${user?.userNm}/${user?.deptNm}/${user?.rankNm}/${user?.userEmpno}`}
                    </s.OptionItem>
                  ))}
                </s.DropdownList>
              )}
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>이 름</s.ModalInputLabel>
              <s.ModalInput
                disabled={true}
                placeholder="'검색하시면 자동으로 입력됩니다'"
                value={props.optionSelect?.userNm}
              ></s.ModalInput>
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>사 번</s.ModalInputLabel>
              <s.ModalInput
                disabled={true}
                placeholder="'검색하시면 자동으로 입력됩니다'"
                value={props.optionSelect?.userEmpno}
              ></s.ModalInput>
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>휴가 종류</s.ModalInputLabel>
              <s.ModalSelect
                onChange={(e) => {
                  props.setVacationTypeSelect(e.target.value);
                }}
              >
                <s.SelectOption value="">선택</s.SelectOption>
                {props.vacationType.map((vacation: any) => (
                  <s.SelectOption value={vacation.value}>
                    {vacation.label}
                  </s.SelectOption>
                ))}
              </s.ModalSelect>
            </s.ModalInputRow>
            {props?.vacationTypeSelect === 'AMVACTYPE02' && (
              <s.ModalInputRow>
                <s.ModalInputLabel>특별연차</s.ModalInputLabel>
                <s.ModalInput
                  style={{ width: '18.5rem' }}
                  placeholder="특별연차 종류를 입력"
                  onChange={(e) => props.setVacationNm(e.target.value)}
                />
              </s.ModalInputRow>
            )}
            <s.ModalInputRow>
              <s.ModalInputLabel>휴가 날짜</s.ModalInputLabel>
              <s.ModalInput
                type="date"
                style={{
                  colorScheme: 'dark',
                  width: '18.5rem',
                }}
                max={'9999-12-31'}
                onChange={(e) => {
                  props.setVacationStartDtm(e.target.value);
                }}
              />
              ~
              <s.ModalInput
                type="date"
                style={{
                  colorScheme: 'dark',
                  width: '18.5rem',
                }}
                max={'9999-12-31'}
                onChange={(e) => {
                  props.setVacationEndDtm(e.target.value);
                }}
              />
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>부여시간</s.ModalInputLabel>
              <s.ModalInput
                style={{ width: '18.5rem' }}
                onChange={(e) => props.setVacationTime(e.target.value)}
              ></s.ModalInput>
              시간
            </s.ModalInputRow>
            <s.ModalButtonWrapper>
              <s.ModalButton
                onClick={() => {
                  props.setIsModal(false);
                  props.modalContentsReset();
                }}
              >
                취소
              </s.ModalButton>
              <s.ModalButton
                primary
                onClick={() => {
                  props.reqVacation();
                  props.modalContentsReset();
                }}
              >
                등록
              </s.ModalButton>
            </s.ModalButtonWrapper>
          </s.PopupModal>
        </s.PopupBackground>
      )}

      {props.isUpdateModal && ( // 휴가신청 팝업
        <s.PopupBackground
          onClick={() => {
            props.setIsUpdateModal(false);
          }}
        >
          <s.PopupModal onClick={(e) => e.stopPropagation()}>
            <s.ModalCloseButton
              onClick={() => {
                props.setIsUpdateModal(false);
              }}
            >
              <MdClose size={'100%'} color={theme.color.white} />
            </s.ModalCloseButton>
            <s.ModalTitle>휴가 수정</s.ModalTitle>
            <s.ModalInputRow>
              <s.ModalInputLabel>이 름</s.ModalInputLabel>
              <s.ModalInput
                disabled={true}
                placeholder="'검색하시면 자동으로 입력됩니다'"
                value={props.updateUserInfo?.userNm}
              ></s.ModalInput>
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>부 서 </s.ModalInputLabel>
              <s.ModalInput
                disabled={true}
                value={props.updateUserInfo?.deptNm}
              ></s.ModalInput>
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>휴가 종류</s.ModalInputLabel>
              <s.ModalInput
                disabled={true}
                value={props.updateUserInfo?.vacationTypeNm}
              ></s.ModalInput>
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>휴가 날짜</s.ModalInputLabel>
              <s.ModalInput
                type="date"
                style={{
                  colorScheme: 'dark',
                  width: '18.5rem',
                }}
                max={'9999-12-31'}
                value={props.vacationStartDtm}
                onChange={(e) => {
                  props.setVacationStartDtm(e.target.value);
                }}
              />
              ~
              <s.ModalInput
                type="date"
                style={{
                  colorScheme: 'dark',
                  width: '18.5rem',
                }}
                max={'9999-12-31'}
                value={props.vacationEndDtm}
                onChange={(e) => {
                  props.setVacationEndDtm(e.target.value);
                }}
              />
            </s.ModalInputRow>
            <s.ModalInputRow>
              <s.ModalInputLabel>부여시간</s.ModalInputLabel>
              <s.ModalInput
                style={{ width: '18.5rem' }}
                value={props.vacationTime}
                onChange={(e) => props.setVacationTime(e.target.value)}
              ></s.ModalInput>
              시간
            </s.ModalInputRow>
            <s.ModalButtonWrapper>
              <s.ModalButton
                primary
                onClick={() => {
                  props.updVacation();
                }}
              >
                수정
              </s.ModalButton>
              <s.ModalButton
                onClick={() => {
                  props.setIsUpdateModal(false);
                }}
              >
                취소
              </s.ModalButton>
            </s.ModalButtonWrapper>
          </s.PopupModal>
        </s.PopupBackground>
      )}
    </s.Container>
  );
};

export default VcSettingPresenter;
