import theme from 'components/styles/theme';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  background-color: ${theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ImageArea = styled.div`
  width: calc(100% - 50rem);
  height: 100%;
  position: relative;
`;

export const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const MainImageCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const LoginArea = styled.div`
  width: 50rem;
  height: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${theme.color.primary};
`;

export const Logo = styled.img`
  width: 10rem;
  height: 10rem;
  object-fit: contain;
`;

export const LoginForm = styled.div`
  width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const LoginRow = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const LoginLabel = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  width: 2rem;
  height: 2rem;
`;

export const LoginInput = styled.input`
  width: 100%;
  padding: 1rem 1.5rem 1rem 4rem;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  border: 0;
  background-color: ${theme.color.veryDarkGrey};
  color: ${theme.color.white};
`;

export const LoginButton = styled.div`
  width: 100%;
  padding: 1rem 1.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: ${theme.color.secondary};
  color: white;
`;

export const AdditionalSpan = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: ${theme.color.white};
  cursor: pointer;

  &:hover {
    color: ${theme.color.third};
  }
`;

export const PopupBackground = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
`;

export const PopupModal = styled.div`
  width: 60rem;
  padding: 4rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.veryDarkGrey};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
`;

export const ModalCloseButton = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const ModalTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.white};
  margin-bottom: 3rem;
  font-weight: bold;
`;

export const ModalInputRow = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
`;

export const ModalInputLabel = styled.div`
  font-size: 1.6rem;
  color: ${theme.color.white};
  width: 13rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ModalInput = styled.input`
  width: 30rem;
  border-radius: 0.5rem;
  background-color: ${theme.color.black};
  color: ${theme.color.white};
  font-size: 1.6rem;
  border: 0;
  padding: 1rem;
`;

export const ModalNotifyDiv = styled.div`
  font-size: 0.8rem;
  width: 100%;
  text-align: left;
  color: ${theme.color.red};
  padding-left: 25%;
`;

export const ModalButtonWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalButton = styled.div<{ primary?: boolean }>`
  width: 15rem;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: ${(props) =>
    props.primary ? theme.color.secondary : theme.color.white};
  color: ${(props) => (props.primary ? theme.color.white : theme.color.black)};
  padding: 1rem;
`;

export const ModalRowButton = styled.div`
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  text-align: center;
  background-color: ${theme.color.secondary};
  border-radius: 0.5rem;
  cursor: pointer;
  color: ${theme.color.white};
  font-size: 1.4rem;
  font-weight: bold;
`;

export const ModalSelect = styled.select<{ short?: boolean }>`
  width: ${(props) => (props.short ? '20%' : '70%')};
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  color: ${theme.color.black};

  &:disabled {
    background-color: ${theme.color.grey};
  }
`;
