import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as s from './RegistStyled';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html'; // draft-js 컨텐츠를 HTML로 변환하는 라이브러리
import { MdClose } from 'react-icons/md';
import DoubltTree from '../../../../components/tree';
import moment from 'moment';

interface RegistProps {
  openNewPage: (url: string) => void;
  priceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isItemModal: any;
  setIsItemModal: Dispatch<SetStateAction<any>>;
  itemList: any[];
  item: any[];
  itemTitle: any;
  setItemTitle: Dispatch<SetStateAction<any>>;
  setItemInputData: Dispatch<SetStateAction<any>>;
  price: any;
  setPrice: Dispatch<SetStateAction<any>>;
  setDetailItem: Dispatch<SetStateAction<any>>;
  detailItem: any[];
  setDetailItemCnt: Dispatch<SetStateAction<any>>;
  detailItemCnt: any;
  itemSt: any;
  setItemSt: Dispatch<SetStateAction<any>>;
  setItem: Dispatch<SetStateAction<any>>;
  save: (
    content: any,
    user: any,
    ccUser: any,
    refDocList: any,
    apprStCd: string,
  ) => void;
  setTitle: Dispatch<SetStateAction<any>>;
  movePage: (page: any) => void;
  title: any;
  user: any;
  setUser: Dispatch<SetStateAction<any>>;
  ccUser: any;
  setCcUser: Dispatch<SetStateAction<any>>;
  contents: any;
  typeTitle: any;
  setAnswer: Dispatch<SetStateAction<any>>;
  updateAnswer: (index: any, value: any) => void;
  updateAnswerDetail: (index: any, value: any, lineIndex: any) => void;
  openTypeSelect: any;
  setOpenTypeSelect: Dispatch<SetStateAction<any>>;
  openDvCdType: any[];
  isModal: any;
  setIsModal: Dispatch<SetStateAction<any>>;
  setStartDt: Dispatch<SetStateAction<any>>;
  setEndDt: Dispatch<SetStateAction<any>>;
  setSearch: Dispatch<SetStateAction<any>>;
  search: any;
  documentPopup: () => void;
  setKeyword: Dispatch<SetStateAction<any>>;
  apprObject: any;
  ApprTableTitle: any;
  fileUpload: () => void;
  imgSend: any;
  setImgSend: Dispatch<SetStateAction<any>>;
  imgUrlSend: any;
  setImgUrlSend: Dispatch<SetStateAction<any>>;
  detailItemAdd: () => void;
  detailDelete: (index: number) => void;
  lineOrder: any;
  deleteRow: (indexToRemove: any) => void;
}

const RegistPresenter = (props: RegistProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [tree, setTree] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedItemNm, setSelectedItemNm] = useState<string[]>([]);

  useEffect(() => {
    const contentHtml = props.contents || '';
    // HTML 문자열을 ContentState로 변환
    const blocksFromHTML = convertFromHTML(contentHtml);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    setEditorState(EditorState.createWithContent(contentState));
  }, [props.contents]);

  const onEditorStateChange = (editorStated) => {
    // editorState에 값 설정
    setEditorState(editorStated);
  };

  const saveClick = (apprStCd: string) => {
    // editorState에서 현재 컨텐츠 추출
    const contentState = editorState.getCurrentContent();
    // 컨텐츠를 HTML로 변환
    const contentAsHtml = draftToHtml(convertToRaw(contentState));
    // contentAsHtml을 표시
    props.save(
      contentAsHtml,
      props.user,
      props.ccUser,
      selectedItems,
      apprStCd,
    );
  };

  // 행을 선택 및 해제하는 함수
  const toggleSelection = (apcId: string, apcTitle: string) => {
    if (selectedItems.includes(apcId)) {
      // 이미 선택된 항목인 경우 선택 해제
      setSelectedItems(selectedItems.filter((item) => item !== apcId));
      setSelectedItemNm(selectedItemNm.filter((item) => item !== apcTitle));
    } else {
      // 선택되지 않은 항목인 경우 선택
      setSelectedItems([...selectedItems, apcId]);
      setSelectedItemNm([...selectedItemNm, apcTitle]);
    }
  };
  return (
    <s.Container>
      {tree && (
        <DoubltTree
          setTree={setTree}
          user={props.user}
          ccUser={props.ccUser}
          setUserLine={props.setUser}
          setCcUserLine={props.setCcUser}
        />
      )}
      <s.Title>{props.typeTitle}</s.Title>
      <s.Wrapper>
        <s.ColumnWrapper>
          <s.RowWrapper>
            <s.ContentTitle>제목</s.ContentTitle>
            <s.InputData
              style={{ marginLeft: '2rem' }}
              placeholder="기안제목 입력"
              onChange={(e) => props.setTitle(e.target.value)}
              value={props.title}
            ></s.InputData>
          </s.RowWrapper>
          <s.RowWrapper>
            <s.ContentTitle>공개여부</s.ContentTitle>
            <s.Select
              onChange={(e) => {
                props.setOpenTypeSelect(e.target.value);
              }}
            >
              <s.SelectOption value="">선택</s.SelectOption>
              {props.openDvCdType.map((openDvCd: any) => (
                <s.SelectOption value={openDvCd.value}>
                  {openDvCd.label}
                </s.SelectOption>
              ))}
            </s.Select>
          </s.RowWrapper>
          <s.RowWrapper>
            <s.ContentTitle>결재선 설정</s.ContentTitle>
            <s.AddBtn onClick={() => setTree(true)}>+</s.AddBtn>
          </s.RowWrapper>
          {props.user.length > 0 && ( // 결재선 선택시 생선되는 Contents
            <s.ApprLine>
              {props.user?.map((item, index) => (
                <s.UserInfo>
                  <s.ApprLineCancel
                    onClick={() => {
                      const updatedItems = [...props.user];
                      updatedItems.splice(index, 1);
                      props.setUser(updatedItems);
                    }}
                  >
                    x
                  </s.ApprLineCancel>
                  <div>{item?.userNm}</div>
                  <div>({item?.userEmpno})</div>
                  <div style={{ color: '#ccc' }}>{item?.deptNm}</div>
                </s.UserInfo>
              ))}
            </s.ApprLine>
          )}
          <s.CcLine>{props.ccUser.length > 0 && '참조'}</s.CcLine>
          <div style={{ display: 'flex', gap: '0.3rem', flexWrap: 'wrap' }}>
            {props.ccUser.length > 0 && ( // 참조선 선택시 생선되는 Contents
              <s.ApprLine>
                {props.ccUser?.map((item, index) => (
                  <s.CcUserInfo>
                    <div>{item?.userNm}</div>
                    <div>({item?.userEmpno}) | </div>
                    <div>{item?.deptNm}</div>
                    <s.CcLineCancel
                      onClick={() => {
                        const updatedItems = [...props.ccUser];
                        updatedItems.splice(index, 1);
                        props.setCcUser(updatedItems);
                      }}
                    >
                      x
                    </s.CcLineCancel>
                  </s.CcUserInfo>
                ))}
              </s.ApprLine>
            )}
          </div>

          {props.item.length > 0 && ( // 항목 추가시 생성퇴는 Contents
            <>
              <s.NewItemWrapper>
                {props.item?.map((item, index) => (
                  <s.RowWrapper>
                    <s.ContentTitle style={{ color: 'black' }}>
                      {item.questionTitle}
                    </s.ContentTitle>
                    {item.questionTypeCd === 'TEXT' ? (
                      <>
                        <s.InputData
                          onChange={(e) =>
                            props.updateAnswer(index, e.target.value)
                          }
                          placeholder={`${item.questionTitle} 입력`}
                        />
                      </>
                    ) : item.questionTypeCd === '일자' ? (
                      <>
                        <s.InputData
                          type="date"
                          style={{
                            colorScheme: 'white',
                          }}
                          max={'9999-12-31'}
                          onChange={(e) =>
                            props.updateAnswer(index, e.target.value)
                          }
                        />
                      </>
                    ) : item.questionTypeCd === '금액' ? (
                      <>
                        <s.InputData
                          onChange={(e) => {
                            props.updateAnswer(index, e.target.value);
                          }}
                          placeholder="금액을 입력(숫자)"
                        />
                        원
                      </>
                    ) : item.questionTypeCd === 'File' ? (
                      <>
                        <s.FileUpload onClick={() => props.fileUpload()}>
                          파일찾기
                        </s.FileUpload>
                        <div
                          style={{
                            fontSize: '1.2rem',
                            marginLeft: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                          }}
                        >
                          {props.imgSend.map((item, index) => (
                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                              <s.FileName>{item}</s.FileName>
                              <s.FileCancel
                                onClick={() => {
                                  const updatedItems = [...props.imgSend];
                                  const updatedItemsUrl = [...props.imgUrlSend];
                                  updatedItems.splice(index, 1);
                                  updatedItemsUrl.splice(index, 1);
                                  props.setImgSend(updatedItems);
                                  props.setImgUrlSend(updatedItemsUrl);
                                }}
                              >
                                x
                              </s.FileCancel>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : item.questionTypeCd === '참조문서' ? (
                      <>
                        {
                          <div style={{ display: 'flex', gap: '1rem' }}>
                            <s.DocBtn onClick={() => props.documentPopup()}>
                              +
                            </s.DocBtn>
                            {selectedItemNm.map((item, index) => (
                              <s.DocWrapper
                                onClick={() =>
                                  props.openNewPage(
                                    `/approval/detail?apcId=${selectedItems[index]}`,
                                  )
                                }
                              >
                                {item}
                              </s.DocWrapper>
                            ))}
                          </div>
                        }
                      </>
                    ) : null}
                  </s.RowWrapper>
                ))}
              </s.NewItemWrapper>
            </>
          )}
          {props.detailItem?.length > 0 && (
            <div style={{ gap: 0, marginTop: '20px' }}>
              <s.RowWrapper>
                <s.ContentTitle>상세내용</s.ContentTitle>
              </s.RowWrapper>
              <>
                <s.NewDetileItemWrapper>
                  {props.detailItem?.map((item, index) => (
                    <s.ColumnDetailWrapper
                      cnt={props.detailItemCnt}
                      key={item.apprDetailQuestionId}
                    >
                      <s.ContentTitle style={{ textAlign: 'center' }}>
                        {item.questionTitle}
                      </s.ContentTitle>
                      <div
                        style={{
                          width: '100%',
                          height: '1px',
                          background: '#000',
                        }}
                      />
                      {item.questionTypeCd === 'TEXT' ? (
                        <>
                          <s.InputData
                            onChange={(e) =>
                              props.updateAnswerDetail(index, e.target.value, 0)
                            }
                            style={{ width: '100%' }}
                            placeholder={`${item.questionTitle} 입력`}
                          />
                        </>
                      ) : item.questionTypeCd === '일자' ? (
                        <>
                          <s.InputData
                            onChange={(e) =>
                              props.updateAnswerDetail(index, e.target.value, 0)
                            }
                            type="date"
                            style={{
                              width: '100%',
                              colorScheme: 'white',
                            }}
                            max={'9999-12-31'}
                          />
                        </>
                      ) : item.questionTypeCd === '금액' ? (
                        <>
                          <s.InputData
                            style={{ width: '100%' }}
                            onChange={(e) => {
                              props.updateAnswerDetail(
                                index,
                                e.target.value,
                                0,
                              );
                            }}
                            placeholder="금액을 입력(숫자)"
                          />
                        </>
                      ) : item.questionTypeCd === 'File' ? (
                        <>
                          <s.InputData type="file" />
                        </>
                      ) : null}
                    </s.ColumnDetailWrapper>
                  ))}
                </s.NewDetileItemWrapper>
                <div
                  style={{ width: '100%', borderTop: '1px solid #000' }}
                ></div>
              </>
              <s.addDetailBtn onClick={() => props.detailItemAdd()}>
                추가
              </s.addDetailBtn>
            </div>
          )}

          {props.detailItem?.length > 0 && (
            <div>
              <s.NewDetileItemWrapper>
                {props.detailItem?.map((item, index) => (
                  <s.DetailWrapper
                    cnt={props.detailItemCnt}
                    key={item.apprDetailQuestionId}
                  >
                    <s.DetailTitle style={{ textAlign: 'center' }}>
                      {item.questionTitle}
                    </s.DetailTitle>
                  </s.DetailWrapper>
                ))}
              </s.NewDetileItemWrapper>
              <s.NewDetileItemWrapper style={{ flexDirection: 'column' }}>
                {props.lineOrder?.map((line, i) => (
                  <s.NewDetileItemWrapper key={i}>
                    {line?.map((item, index) => (
                      <s.DetailWrapper key={`${i}-${index}`}>
                        <s.DetailTitle style={{ textAlign: 'center' }}>
                          {item}
                        </s.DetailTitle>
                      </s.DetailWrapper>
                    ))}
                    <s.Delete onClick={() => props.deleteRow(i)}>
                      Delete
                    </s.Delete>
                  </s.NewDetileItemWrapper>
                ))}
              </s.NewDetileItemWrapper>
            </div>
          )}
          <s.RowWrapper
            style={{ flexDirection: 'column', alignItems: 'start' }}
          >
            <s.ContentTitle>내용</s.ContentTitle>
            <s.MyBlock>
              <Editor
                // 에디터와 툴바 모두에 적용되는 클래스
                wrapperClassName="wrapper-class"
                // 에디터 주변에 적용된 클래스
                editorClassName="editor"
                // 툴바 주위에 적용된 클래스
                toolbarClassName="toolbar-class"
                // 툴바 설정
                toolbar={{
                  // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
                  list: { inDropdown: false },
                  textAlign: { inDropdown: false },
                  link: { inDropdown: false },
                  history: { inDropdown: false },
                }}
                placeholder="내용을 작성해주세요."
                // 한국어 설정
                localization={{
                  locale: 'ko',
                }}
                // 초기값 설정
                editorState={editorState}
                // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
                onEditorStateChange={onEditorStateChange}
              />
            </s.MyBlock>
          </s.RowWrapper>
          <s.BtnWrapper>
            <s.RecallBtn onClick={() => saveClick('APPRST05')}>
              임시저장
            </s.RecallBtn>
            <s.SaveBtn onClick={() => saveClick('APPRST01')}>결재</s.SaveBtn>
            <s.CancelBtn onClick={() => props.movePage('/approval')}>
              취소
            </s.CancelBtn>
          </s.BtnWrapper>
        </s.ColumnWrapper>
      </s.Wrapper>
      {props.isModal && (
        <s.PopupBackground onClick={() => props.setIsModal(false)}>
          <s.PopupModal onClick={(e) => e.stopPropagation()}>
            <s.ModalTitle>참조문서</s.ModalTitle>
            <s.SearchWrapper>
              <s.SearchTitle>상신일</s.SearchTitle>
              <s.ModalInput //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
                type="date"
                style={{
                  colorScheme: 'white',
                  marginRight: '1rem',
                }}
                max={'9999-12-31'}
                onChange={(e) => props.setStartDt(e.target.value)}
              />
              ~
              <s.ModalInput //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
                type="date"
                style={{
                  colorScheme: 'white',
                  marginLeft: '1rem',
                }}
                max={'9999-12-31'}
                onChange={(e) => props.setEndDt(e.target.value)}
              />
            </s.SearchWrapper>
            <s.SearchWrapper style={{ marginBottom: '5rem' }}>
              <s.SearchTitle>검색</s.SearchTitle>
              <s.ModalInput //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
                type="text"
                placeholder="기안 양식 또는 제목을 입력하세요."
                style={{ width: '500px' }}
                onChange={(e) => props.setKeyword(e.target.value)}
              />
              <s.SearchBtn onClick={() => props.setSearch(!props.search)}>
                검색
              </s.SearchBtn>
            </s.SearchWrapper>
            <s.Table>
              <s.Tbody>
                <s.Tr style={{ background: '#1A2D56' }}>
                  {props.ApprTableTitle?.map((item: any) => (
                    <s.Th>{item}</s.Th>
                  ))}
                </s.Tr>
                {props.apprObject?.filter(
                  (row: any) =>
                    row.apprStCdNm === '승인' || row.apprStCdNm === '반려',
                ).length > 0 ? (
                  props.apprObject
                    ?.filter(
                      (row: any) =>
                        row.apprStCdNm === '승인' || row.apprStCdNm === '반려',
                    )
                    .map((row: any) => (
                      <s.Tr>
                        <s.Td>
                          <s.ModalCheck //전일이 아닐경우 data Type 을 하나만 띄우도록 수정해야함 230713 JW
                            type="checkbox"
                            checked={selectedItems.includes(row.apcId)}
                            onChange={() =>
                              toggleSelection(row.apcId, row.apcTitle)
                            }
                          />
                        </s.Td>
                        <s.Td>{row.apcId}</s.Td>
                        <s.Td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            props.openNewPage(
                              `/approval/detail?apcId=${row.apcId}`,
                            )
                          }
                        >
                          {row.apprTypeTitle}
                        </s.Td>
                        <s.Td
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            props.openNewPage(
                              `/approval/detail?apcId=${row.apcId}`,
                            )
                          }
                        >
                          {row.apcTitle}
                        </s.Td>
                        <s.Td>
                          {moment(row.regDtm).format('YYYY-MM-DD HH:mm')}
                        </s.Td>
                        <s.Td>{row.apprUserNm}</s.Td>
                        <s.Td>{row.apprStCdNm}</s.Td>
                      </s.Tr>
                    ))
                ) : (
                  <s.Tr>
                    <s.Td colSpan={5}>{'데이터가 없습니다.'}</s.Td>
                  </s.Tr>
                )}
              </s.Tbody>
            </s.Table>
            <s.ModalButtonWrapper>
              <s.ModalButton
                primary
                onClick={() => {
                  props.setIsModal(false);
                }}
              >
                선택
              </s.ModalButton>
              <s.ModalButton
                primary
                onClick={() => {
                  props.setIsModal(false);
                  setSelectedItems([]);
                }}
              >
                전체 해제
              </s.ModalButton>
              <s.ModalButton
                onClick={() => {
                  props.setIsModal(false);
                }}
              >
                취소
              </s.ModalButton>
            </s.ModalButtonWrapper>
          </s.PopupModal>
        </s.PopupBackground>
      )}
    </s.Container>
  );
};

export default RegistPresenter;
