import React, { useState, useEffect } from 'react';

import VacationPresenter from './VacationPresenter';
import apiVclog from 'api/api-vclog';
import moment from 'moment';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import swal from '@sweetalert/with-react';

const VacationContainer = () => {
  const [vcObject, setVcObject] = useState<any>();
  const [vcHistObject, setVcHistObject] = useState<any>();
  const [adminVcObject, setAdminVcObject] = useState<any>();
  const [adminVcHistObject, setAdminVcHistObject] = useState<any>();
  const token = useRecoilValue(recoilitem.token);
  const userInfo = useRecoilValue(recoilitem.userInfo);
  const setIsLoading = useSetRecoilState(recoilitem.isLoading);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState();
  const [selectedLabel, setSelectedLabel] = useState();
  const [vacationId, setVacationId] = useState();
  const [useStrDt, setUseStrDt] = useState<any | undefined>();
  const [useEndDt, setUseEndDt] = useState<any | undefined>();
  const [useDvCd, setUseDvCd] = useState();
  const [vacationTypeCd, setVacationTypeCd] = useState();
  const [apcRsn, setApcRsn] = useState();
  const [fileName, setFileName] = useState('휴가 기록');
  const [vacationDvCdList, setVacationDvCdList] = useState<any[]>([
    { value: 'AMVACUSE01', label: '전일' },
    { value: 'AMVACUSE02', label: '오전' },
    { value: 'AMVACUSE03', label: '오후' },
  ]);
  const vacationTableTitle = [
    '신청일자',
    '사용구분',
    '종류',
    '휴가일자',
    '휴가시간',
    '승인여부',
  ];
  const adminVacationTableTitle = [
    '신청자',
    '신청일자',
    '사용구분',
    '종류',
    '휴가일자',
    '휴가시간',
    '승인여부',
  ];
  const adminVacationExcelHeader = [
    { label: '사번', key: 'userEmpno' },
    { label: '부서', key: 'deptNm' },
    { label: '이름', key: 'userNm' },
    { label: '직책', key: 'posnNm' },
    { label: '휴가이름', key: 'vacationTypeNm' },
    { label: '시작시간', key: 'useStrDt' },
    { label: '종료시간', key: 'useEndDt' },
    { label: '휴가종류', key: 'useDvCd' },
    { label: '휴가시간', key: 'useAmount' },
    { label: '승인여부', key: 'apprStNm' },
  ];
  const useDvCdMap = {
    AMVACUSE01: '전일',
    AMVACUSE02: '오전',
    AMVACUSE03: '오후',
  };
  const [adminVacationExcelData, setAdminVacationExcelData] = useState([]);
  const [isAdmin, setIsAdmin] = useState<any | undefined>(false);
  const [searchData, setSearchData] = useState('');
  const [amHistObject, setAmHistObject] = useState<any>();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const dto: any = {
        startDt: moment().format('YYYY0101'),
        endDt: moment().format('YYYY1231'),
        adminYn: 'N',
      };
      const res = await apiVclog.getList(dto);
      const resHist = await apiVclog.getUseHistList(dto);
      if (res.rsltCd === '00' && resHist.rsltCd == '00') {
        setVcObject(res.data?.vacationList);
        setVcHistObject(resHist.data?.useHistList);
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const reqVacation = async () => {
    try {
      const strDate = useStrDt?.replace(/-/g, '');
      let endDate = useEndDt?.replace(/-/g, '');

      if (useDvCd !== 'AMVACUSE01') {
        endDate = useStrDt?.replace(/-/g, '');
      }
      if (!selectedValue) {
        makeMsg('사용구분을 선택해주세요.', 'warning');
        return;
      } else if (!useDvCd) {
        makeMsg('연차/반차를 선택해주세요.', 'warning');
        return;
      } else if (!strDate || !endDate) {
        makeMsg('사용일자를 선택해주세요.', 'warning');
        return;
      } else if (!apcRsn) {
        makeMsg('휴가사유를 작성해주세요.', 'warning');
        return;
      }

      const value = await swal({
        text: ' 휴가신청 하시겠습니까?',
        buttons: {
          confirm: {
            text: '확인',
            closeModal: true,
          },
          cancel: '취소',
        },
      });
      if (value) {
        const dto: any = {
          vacationId: vacationId,
          useStrDt: strDate, // 휴가 시작일시
          useEndDt: endDate, // 휴가 종료일시
          useDvCd: useDvCd, // 전일, 오전, 오후 구분
          vacationTypeCd: selectedValue, // 휴가 종류
          apcRsn: apcRsn, // 휴가 사유
          apprTypeId: 1,
        };
        const res = await apiVclog.request(dto);
        if (res.rsltCd === '00') {
          makeMsg('신청되었습니다.', 'success');
          setIsModal(false);
          setTimeout(() => {
            fetchData();
          }, 1000);
        } else {
          makeMsg('데이터가 잘못 되었습니다.', 'warning');
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  useEffect(() => {
    if (
      moment(useStrDt, 'YYYY-MM-DD').isAfter(moment(useEndDt, 'YYYY-MM-DD'))
    ) {
      makeMsg('종료일은 시작일 이전일 수 없습니다.', 'warning');
      setUseEndDt(useStrDt);
    }
  }, [useStrDt, useEndDt]);

  const fetchAdminData = async () => {
    const dto: any = {
      startDt: moment().startOf('year').format('YYYYMMDD'),
      endDt: moment().endOf('year').format('YYYYMMDD'),
      adminYn: 'Y',
      keyword: searchData,
    };

    const adminRestHist = await apiVclog.getUseHistList(dto);
    if (adminRestHist.rsltCd == '00') {
      setAdminVcHistObject(adminRestHist.data?.useHistList);
      setAdminVacationExcelData(
        adminRestHist.data?.useHistList.map((item) => ({
          userEmpno: item.userEmpno,
          userNm: item.userNm,
          deptNm: item.deptNm,
          posnNm: item.posnNm,
          useStrDt: moment(item.useStrDt).format('YYYY-MM-DD'),
          useEndDt: moment(item.useEndDt).format('YYYY-MM-DD'),
          useDvCd: useDvCdMap[item.useDvCd] || item.useDvCd,
          vacationTypeNm: item.vacationTypeNm,
          useAmount: item.useAmount + '시간',
          apprStNm: item.apprStNm,
        })),
      );
    }
  };

  // useEffect(() => {
  //   if (isAdmin === 'Y') {
  //     setIsLoading(true);
  //     fetchAdminData();
  //     setIsLoading(false);
  //   }
  // }, [isAdmin]);

  return (
    <>
      <VacationPresenter
        vcObject={vcObject}
        vcHistObject={vcHistObject}
        isModal={isModal}
        setIsModal={setIsModal}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        reqVacation={reqVacation}
        setVacationId={setVacationId}
        setUseStrDt={setUseStrDt}
        setUseEndDt={setUseEndDt}
        setUseDvCd={setUseDvCd}
        setApcRsn={setApcRsn}
        vacationDvCdList={vacationDvCdList}
        useDvCd={useDvCd}
        useStrDt={useStrDt}
        useEndDt={useEndDt}
        apcRsn={apcRsn}
        vacationTableTitle={vacationTableTitle}
        setSelectedLabel={setSelectedLabel}
        selectedLabel={selectedLabel}
        userInfo={userInfo}
        setIsAdmin={setIsAdmin}
        isAdmin={isAdmin}
        searchData={searchData}
        setSearchData={setSearchData}
        //getMonthlyList={getMonthlyList}
        amHistObject={amHistObject}
        fetchAdminData={fetchAdminData}
        adminVacationTableTitle={adminVacationTableTitle}
        adminVcObject={adminVcObject}
        adminVcHistObject={adminVcHistObject}
        adminVacationExcelHeader={adminVacationExcelHeader}
        adminVacationExcelData={adminVacationExcelData}
        fileName={fileName}
      />
    </>
  );
};

VacationContainer.defaultProps = {};

export default VacationContainer;
