import React, { Dispatch, SetStateAction } from 'react';
import * as s from './AmChangeLogStyled';
import { Link } from 'react-router-dom';

import NotReady from '../../../notReady';
import moment from 'moment';

interface AmChangeLogProps {
  amChangeTableTitle: any;
  changeAmApcObject: any;
}

const AmChangeLogPresenter = (props: AmChangeLogProps) => {
  return (
    <s.Container>
      <s.TableTitle>유연근무제 요청 현황</s.TableTitle>
      <s.Table>
        <s.Tbody>
          <s.Tr>
            {props.amChangeTableTitle?.map((item: any) => (
              <s.Th>{item}</s.Th>
            ))}
          </s.Tr>
          {props.changeAmApcObject?.length > 0 ? (
            props.changeAmApcObject?.map((row: any) => (
              <s.Tr>
                <s.Td>{row.userNm}</s.Td>
                <s.Td>{row.deptNm}</s.Td>
                <s.Td>
                  {row.startDt === row.endDt ? (
                    <>{row.startDt}</>
                  ) : (
                    <>
                      {row.startDt}~{row.endDt}
                    </>
                  )}
                </s.Td>
                <s.Td>{moment(row.startTm, 'HHmm').format('HH:mm')}</s.Td>
                <s.Td>{moment(row.endTm, 'HHmm').format('HH:mm')}</s.Td>
                <s.Td>{row.nowturn}</s.Td>
                <s.Td>
                  {row.state
                    ? row.state === '반려'
                      ? `${row.state} (${row?.rejectionRsn})`
                      : row.state
                    : '결재중'}
                </s.Td>
              </s.Tr>
            ))
          ) : (
            <s.Tr>
              <s.Td colSpan={7}>{'데이터가 없습니다.'}</s.Td>
            </s.Tr>
          )}
        </s.Tbody>
      </s.Table>
    </s.Container>
  );
};

export default AmChangeLogPresenter;
